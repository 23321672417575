import React, { useEffect, useMemo, useState } from "react"

import { Trans, useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { analyticsEvent, SupportedEvents } from "../../../../analytics"
import { HOME_PATHS } from "../../../../constants"
import { useNavigation } from "../../../../hooks/useNavigation"
import { getLabel } from "../../../../utils"
import PricingCard from "./PricingCard"
import { useModals } from "@mattjennings/react-modal-stack"

import { CURRENCIES } from "../../../../redux/api/billing/constants"
import { selectCompanyId } from "../../../../redux/app/selectors"
import {
  fetchExperiments,
  postExperiments,
  updateExperiments,
} from "../../../../redux/experiments/experimentsSlice"
import {
  BusinessPlanSettingsData,
  BusinessPlanSettingsRequest,
  BusinessPlanValues,
  ExperimentSettings,
} from "../../../../redux/experiments/types"
import { RootState } from "../../../../redux/reducers"
import { useActions } from "../../../../redux/utils"

import { ConfirmationModal } from "../../../../components/advanced/ConfirmationModal"
import Skeleton from "../../../../components/advanced/Skeleton"
import MultiToggle from "../../../../components/basic/MultiToggle"

import "./PricingPlans.sass"

type PlanName = BusinessPlanValues["plan"]

type BillingPeriod = BusinessPlanValues["billingPeriod"]

type PlanDetails = {
  title: PlanName
  monthlyPrice: number
  yearlyPrice: number
  description: string
  userCount: number
  deviceCount: number
  actionText: string
}

/**
 * This is the pricing plans component for the new business model. (Old business model uses BillingSection component -- src/screens/Settings/Billing/BillingSection.tsx)
 * It is currently under feature flag since the new business model is an experiment at the moment.
 */

const PricingPlans = () => {
  const { t } = useTranslation()
  const { push } = useNavigation()
  const { openModal, closeModal } = useModals()
  const [isLoading, setIsLoading] = useState(true)

  // Centralized plan configuration object
  const PLANS_CONFIG = useMemo<Record<PlanName, PlanDetails>>(
    () => ({
      Starter: {
        title: "Starter",
        monthlyPrice: 59,
        yearlyPrice: 49,
        description: t(
          "desktop.settings.billing.plans.options.description.starter",
        ),
        userCount: 20,
        deviceCount: 2,
        actionText: t("desktop.settings.billing.plans.options.action.starter"),
      },
      Growth: {
        title: "Growth",
        monthlyPrice: 269,
        yearlyPrice: 219,
        description: t(
          "desktop.settings.billing.plans.options.description.growth",
        ),
        userCount: 50,
        deviceCount: 5,
        actionText: t("desktop.settings.billing.plans.options.action.growth"),
      },
      Scale: {
        title: "Scale",
        monthlyPrice: 599,
        yearlyPrice: 499,
        description: t(
          "desktop.settings.billing.plans.options.description.scale",
        ),
        userCount: 100,
        deviceCount: 10,
        actionText: t("desktop.settings.billing.plans.options.action.scale"),
      },
      Enterprise: {
        title: "Enterprise",
        monthlyPrice: 1249,
        yearlyPrice: 999,
        description: t(
          "desktop.settings.billing.plans.options.description.enterprise",
        ),
        userCount: 200,
        deviceCount: 20,
        actionText: t(
          "desktop.settings.billing.plans.options.action.enterprise",
        ),
      },
    }),
    [t],
  )

  const experiments = useSelector(
    (state: RootState) => state.experiments.entries,
  )

  const currentCompanyId = useSelector(selectCompanyId)

  // Get the saved plan and billing period from the experiments
  const { savedPlan, savedBillingPeriod, newBusinessModel } = useMemo(() => {
    // Filter experiments to only include those for the current company
    const newBusinessModel = experiments.find(
      (exp: ExperimentSettings) =>
        exp.data.type === "NEW_BUSINESS_PLAN_SELECTED" &&
        exp.company === currentCompanyId,
    )

    let storedPlan = null
    let storedBillingPeriod = null

    if (newBusinessModel) {
      const { values } = newBusinessModel.data as BusinessPlanSettingsData
      if (values) {
        if ("plan" in values) {
          storedPlan = values.plan
        }
        if ("billingPeriod" in values) {
          storedBillingPeriod = values.billingPeriod
        }
      }
    }

    return {
      savedPlan: storedPlan,
      savedBillingPeriod: storedBillingPeriod,
      newBusinessModel,
    }
  }, [experiments, currentCompanyId])

  const [selectedBillingPeriod, setSelectedBillingPeriod] =
    useState<BillingPeriod>("yearly")

  // Initialize isPlanDisabled based on whether there's a saved plan
  const [isPlanDisabled, setIsPlanDisabled] = useState(!!savedPlan)

  const actions = useActions({
    fetchExperiments: () => fetchExperiments(),
    postExperiments: (payload: BusinessPlanSettingsRequest) =>
      postExperiments(payload),
    updateExperiments: (id: string, payload: BusinessPlanSettingsRequest) =>
      updateExperiments({
        id,
        ...payload,
      }),
  })

  const getPrice = (planName: PlanName) => {
    const planConfig = PLANS_CONFIG[planName]
    return selectedBillingPeriod === "monthly"
      ? planConfig.monthlyPrice
      : planConfig.yearlyPrice
  }

  const getPeriod = () => {
    return selectedBillingPeriod === "monthly" ? "month" : "year"
  }

  const isPlanActive = (planName: PlanName) => {
    return savedPlan === planName
  }

  const handlePlanClick = (planName: PlanName) => {
    // If a plan is already saved, don't allow selecting another one
    if (savedPlan) {
      return
    }

    const title = t("desktop.settings.billing.plans.current_plan.modal.title", {
      selectedPlan: planName,
    })

    const hint = (
      <Trans
        i18nKey="desktop.settings.billing.plans.current_plan.modal.content"
        values={{ selectedPlan: planName }}
      />
    )

    openModal(ConfirmationModal, {
      title,
      hint,
      onConfirm: async () => {
        await handleConfirm(planName)
        closeModal()
      },
    })
  }

  const handleConfirm = async (planName: PlanName) => {
    // Get the selected plan price based on billing period
    const planConfig = PLANS_CONFIG[planName]
    const planPrice =
      selectedBillingPeriod === "monthly"
        ? planConfig.monthlyPrice
        : planConfig.yearlyPrice

    // Send analytics event
    analyticsEvent(SupportedEvents.NEW_BUSINESS_PLAN_SELECTED, {
      plan: planName,
      billingPeriod: selectedBillingPeriod,
      price: planPrice,
    })

    try {
      // Save to experiments endpoint
      const payload: BusinessPlanSettingsRequest = {
        type: "NEW_BUSINESS_PLAN_SELECTED",
        values: {
          plan: planName,
          billingPeriod: selectedBillingPeriod,
          price: planPrice,
        },
      }

      // Find existing experiment
      const existingExperiment = experiments.find(
        (exp: ExperimentSettings) =>
          exp.data.type === "NEW_BUSINESS_PLAN_SELECTED" &&
          exp.company === currentCompanyId,
      )

      // If the experiment already exists, don't update it - plans can't be changed once selected
      if (existingExperiment) {
        return
      } else {
        // If the experiment does not exist, create it
        await actions.postExperiments(payload)
      }

      // Disable the buttons after confirming
      setIsPlanDisabled(true)
    } catch (error) {
      console.error("Failed to save plan selection:", error)
    }
  }

  useEffect(() => {
    // Fetch experiments
    const loadExperiments = async () => {
      setIsLoading(true)
      await actions.fetchExperiments()
      setIsLoading(false)
    }

    loadExperiments()
  }, [actions])

  useEffect(() => {
    // Set billing period from saved experiments when they load
    if (savedBillingPeriod) {
      setSelectedBillingPeriod(savedBillingPeriod)
    }
  }, [savedBillingPeriod])

  useEffect(() => {
    // When savedPlan changes, set selectedPlan and isPlanDisabled to true
    if (savedPlan) {
      setIsPlanDisabled(true)
    }
  }, [savedPlan])

  if (isLoading) {
    return <Skeleton height={"32rem"} />
  }

  return (
    <div className="PricingPlans">
      <h2 className="PricingPlans__title">
        {t("desktop.settings.billing.plans.title")}
      </h2>

      <div className="PricingPlans__billing-toggle">
        <MultiToggle
          value={selectedBillingPeriod}
          onChange={(value) => setSelectedBillingPeriod(value as BillingPeriod)}
          options={[
            {
              label: t("desktop.settings.billing.plan_selection.billed", {
                period: t(
                  "desktop.settings.billing.subscription_quantity.monthly",
                ),
              }),
              value: "monthly",
            },
            {
              label: t("desktop.settings.billing.plan_selection.billed", {
                period: t(
                  "desktop.settings.billing.subscription_quantity.yearly",
                ),
              }),
              value: "yearly",
            },
          ]}
        />
      </div>

      <div className="PricingPlans__list">
        {Object.values(PLANS_CONFIG).map((plan) => (
          <PricingCard
            className="PricingPlans__card"
            key={plan.title}
            title={plan.title}
            price={getPrice(plan.title)}
            currency={CURRENCIES.EUR}
            period={getPeriod()}
            description={plan.description}
            forText={plan.description}
            userCount={plan.userCount}
            deviceCount={plan.deviceCount}
            actionText={plan.actionText}
            onActionClick={() => handlePlanClick(plan.title)}
            isActive={isPlanActive(plan.title)}
            isDisabled={isPlanDisabled && !isPlanActive(plan.title)}
          />
        ))}
      </div>

      {newBusinessModel && savedPlan && PLANS_CONFIG[savedPlan] && (
        <div className="PricingPlans__active-message">
          <div className="PricingPlans__active-message__title">
            {t("desktop.settings.billing.plans.current_plan.title")}
          </div>
          <div className="PricingPlans__active-message__content">
            <Trans
              i18nKey="desktop.settings.billing.plans.current_plan.content"
              components={{
                a: (
                  <a
                    href={`mailto:${getLabel("links.salesEmail")}`}
                    target="_blank"
                    rel="noreferrer"
                    className="PricingPlans__active-message__content--link"
                  >
                    sales email
                  </a>
                ),
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default PricingPlans
