import { useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { useAppSelector } from "../../redux/reducers"
import { fetchScreeningsConfig } from "../../redux/screenings/screeningsSlice"
import { selectScreenings } from "../../redux/screenings/selectors"
import { useActions } from "../../redux/utils"

import Skeleton from "../../components/advanced/Skeleton"
import Breadcrumbs from "../../components/Breadcrumbs"
import HealthSettingsForm from "../../components/Form/HealthSettingsForm"
import Intro from "../../components/Intro"
import Space from "../../components/Space"
import View from "../../components/View"

type ParamsType = {
  id: string
}

const HealthSettingsSkeleton = () => {
  const pattern = [
    {
      width: "30%",
    },
    {
      width: "100%",
      space: 1,
    },
    {
      width: "30%",
    },
    {
      width: "100%",
    },
    {
      width: "100%",
      space: 2,
    },
  ]

  const lines = new Array(5).fill(pattern).flat()

  return <Skeleton skeletonGroup={{ lines }} />
}

const HealthSettings = () => {
  const { t } = useTranslation()
  const { id } = useParams<ParamsType>()

  const actions = useActions({
    fetchConfig: (id: string) => fetchScreeningsConfig(id),
  })

  const { entry: healthSetting, isLoading } = useAppSelector(selectScreenings)

  useEffect(() => {
    id && actions.fetchConfig(id)
  }, [actions, id])

  return (
    <View className="HealthSettings">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.manage.visitors.health_screening.heading"),
          id
            ? t("desktop.manage.visitors.health_screening.edit_questionnaire")
            : t(
                "desktop.manage.visitors.health_screening.table.add_questionnaire",
              ),
        ]}
      />

      <Intro>{t("desktop.manage.visitors.health_screening.form.intro")}</Intro>

      <Space size={0.75} />

      {isLoading ? (
        <HealthSettingsSkeleton />
      ) : (
        <HealthSettingsForm healthSetting={id ? healthSetting : undefined} />
      )}
    </View>
  )
}

export default HealthSettings
