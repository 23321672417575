import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import {
  LabelPaths,
  LANGUAGE_OPTIONS,
  TIME_FORMAT_OPTIONS,
} from "../../../../constants"
import { useCheckForFeatureFlag } from "../../../../hooks/useCheckForFeatureFlag"
import { generateId } from "../../../../hooks/useId"
import { useNavigation } from "../../../../hooks/useNavigation"
import { useScrollToLocation } from "../../../../hooks/useScrollToLocation"
import { useStoredFilter } from "../../../../hooks/useStoredFilter"
import { useToast } from "../../../../hooks/useToast"
import { OptionType } from "../../../../types/sharedTypes"
import {
  generateString,
  getLabel,
  getOption,
  updateHistory,
} from "../../../../utils"
import {
  DEVICE_SPECIFIC_EXCLUSIONS,
  DEVICES_WITH_CUSTOM_BUTTON,
  DEVICES_WITH_CUSTOM_CONTENT,
  DEVICES_WITH_RESTRICTED_CANCEL_OPTIONS,
  ROOM_DEVICE_SETTINGS_FILTERS_STORE_NAME,
  ROOMS_DEVICE_SETTINGS_PATHNAME,
  SETTINGS_GROUPS_PROPS,
} from "../constants"
import { CUSTOM_CONTENT_STATUS } from "../CustomContent/constants"
import { getDeviceLabel } from "../Devices/utils"
import {
  AUTHENTICATION_TYPES,
  CUSTOM_BUTTON_STATES,
  DEVICE_SETTINGS_FEATURES,
  EFFECTIVE_SETTINGS_LOCATIONS,
  getCustomButtonActionTypeOptions,
  getDeviceCancelationEventOptions,
  getDeviceCheckinPeriodOptions,
  getDeviceMeetNowDurationOptions,
  getDeviceOnSpotDurationOptions,
  getReplaceableButtonOptions,
  getReplaceableDevice6ProButtonOptions,
  getRestrictedDeviceCancelationEventOptions,
  SETTINGS_GROUPS,
  SettingsGroups,
} from "./constants"
import DeviceSettingsFilters, { Filter } from "./Filters"
import CustomButtonGroup from "./SettingsGroup/CustomButtonGroup"
import CustomContentGroup from "./SettingsGroup/CustomContentGroup"
import CustomLogoGroup from "./SettingsGroup/CustomLogoGroup"
import GeneralGroup from "./SettingsGroup/GeneralGroup"
import LocalizationGroup from "./SettingsGroup/LocalizationGroup"
import MaintenanceGroup from "./SettingsGroup/MaintenanceGroup"
import OfficeHoursGroup from "./SettingsGroup/OfficeHoursGroup"
import { EffectiveSettingsLocation } from "./types"
import { skipToken } from "@reduxjs/toolkit/dist/query"

import {
  useFetchBuildingQuery,
  useUpdateBuildingMutation,
} from "../../../../redux/api/buildings"
import {
  useFetchCompanyQuery,
  useUpdateCompanyMutation,
} from "../../../../redux/api/company"
import {
  useFetchCustomContentListQuery,
  useUpdateCustomContentMutation,
} from "../../../../redux/api/customContent"
import { CustomContentResponse } from "../../../../redux/api/customContent/types"
import {
  useFetchRoomDeviceQuery,
  useFetchRoomsDevicesQuery,
  useUpdateRoomDeviceMutation,
} from "../../../../redux/api/devices"
import { RoomDeviceResponse } from "../../../../redux/api/devices/types"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"
import { uploadFileCompat } from "../../../../redux/files/filesSlice"
import { removeDotsAndSpaces } from "../../../../redux/files/utils"
import {
  DeviceLogo,
  OfficeHours,
  Settings,
} from "../../../../redux/settings/types"
import { useActions } from "../../../../redux/utils"

import Skeleton from "../../../../components/advanced/Skeleton"
import { FilterSpecialValues } from "../../../../components/Filter/types"
import { setErrors } from "../../../../components/Form/formUtils"
import PageForm from "../../../../components/Form/PageFormHook"

import "./DeviceSettingsForm.sass"

const isOptionType = (value: any): value is OptionType =>
  !!value && typeof value === "object" && value !== null && "value" in value

type PartialSettingsKeys = Array<keyof Partial<Settings>>

const FORM_MAPPING = {
  "settings.custom_button.action": "custom_button.action",
  "settings.custom_button.device": "custom_button.device",
  "settings.custom_button.friendly_key": "custom_button.friendly_key",
  "settings.custom_button.location": "custom_button.location",
  "settings.custom_button.replaces": "custom_button.replaces",
  "settings.custom_button.replaces_pro": "custom_button.replaces_pro",
  "settings.custom_button.state": "custom_button.state",
  "settings.custom_button.title": "custom_button.title",
  "settings.custom_button.payload.message": "custom_button.payload.message",
  "settings.custom_button.payload.url": "custom_button.payload.url",
  "settings.custom_button.payload.authentication.type":
    "custom_button.payload.authentication.type",
  "settings.custom_button.payload.authentication.basic.username":
    "custom_button.payload.authentication.basic.username",
  "settings.custom_button.payload.authentication.basic.password":
    "custom_button.payload.authentication.basic.password",
  "settings.device_cancel_meetings": "device_cancel_meetings",
  "settings.device_check_into_timeout": "device_check_into_timeout",
  "settings.device_cleaning_status": "device_cleaning_status",
  "settings.device_display_charging": "device_display_charging",
  "settings.device_meet_later": "device_meet_later",
  "settings.device_meet_now_duration": "device_meet_now_duration",
  "settings.device_on_spot_duration": "device_on_spot_duration",
  "settings.device_no_wifi": "device_no_wifi",
  "settings.device_show_meeting_organizer": "device_show_meeting_organizer",
  "settings.device_show_meeting_subject": "device_show_meeting_subject",
  "settings.device_show_meeting_description": "device_show_meeting_description",
  "settings.device_show_tentative_meetings": "device_show_tentative_meetings",
  "settings.device_sound": "device_sound",
  "settings.device_touchscreen": "device_touchscreen",
  "settings.maintenance_email": "maintenance_email",
  "settings.office_hours": [
    "office_hours.start_hour",
    "office_hours.stop_hour",
  ],
  "settings.timeformat": "timeformat",
  "settings.timezone": "timezone",
  "settings.language": "language",
} as const

type FormValues = {
  custom_button?: {
    action: OptionType<number>
    device: null | string
    friendly_key: string
    location: null | string
    payload: {
      authentication?: {
        basic?: {
          username?: string
          password?: string
        }
        type?: number
      }
      message: string
      url?: string
    }
    replaces: OptionType<number>
    replaces_pro: OptionType<number>
    state: number
    title: string
  } | null
  device_cancel_meetings?: OptionType<number> | null
  device_check_into_timeout?: OptionType<number> | null
  device_cleaning_status?: boolean | null
  device_display_charging?: boolean | null
  device_logo?: DeviceLogo | null
  device_meet_later?: boolean | null
  device_meet_now_duration?: OptionType<number> | null
  device_no_wifi?: boolean | null
  device_on_spot_duration?: OptionType<number> | null
  device_show_meeting_organizer?: boolean | null
  device_show_meeting_subject?: boolean | null
  device_show_meeting_description?: boolean | null
  device_show_tentative_meetings?: boolean | null
  device_sound?: boolean | null
  device_touchscreen?: boolean | null
  maintenance_email?: string | null
  office_hours?: OfficeHours<OptionType<null | string>>
  timeformat?: OptionType | null
  timezone?: OptionType | null
  language?: OptionType | null
}

const DeviceSettingsSkeleton = () => {
  const pattern = [
    {
      width: "30%",
      space: 0.3,
    },
    {
      width: "60%",
    },
    {
      width: "60%",
      space: 0.3,
    },
    {
      width: "20%",
    },
    {
      width: "20%",
    },
    {
      width: "20%",
      space: 2,
    },
  ]
  const lines = new Array(4).fill(pattern).flat()

  return <Skeleton skeletonGroup={{ lines }} />
}

/**
 * DeviceSettingsForm component
 */

const DeviceSettingsForm = () => {
  const {
    location: { pathname, hash },
  } = useNavigation()

  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()

  const actions = useActions({
    uploadFile: (logo: File) => uploadFileCompat(logo),
  })

  const {
    GLOBAL,
    PER_BUILDING,
    PER_DEVICE,
    SET_CUSTOM_LOGO,
    CUSTOM_CONTENT,
    CREATE_CUSTOM_BUTTONS,
    SET_LANGUAGE,
    OPERATING_HOURS,
    CHECK_INTO_MEETINGS,
  } = DEVICE_SETTINGS_FEATURES

  const isCompanySettingsEnabled = useCheckForFeatureFlag(GLOBAL)
  const isBuildingSettingsEnabled = useCheckForFeatureFlag(PER_BUILDING)
  const isDeviceSettingsEnabled = useCheckForFeatureFlag(PER_DEVICE)
  const isCustomLogoEnabled = useCheckForFeatureFlag(SET_CUSTOM_LOGO)
  const isCustomContentEnabled = useCheckForFeatureFlag(CUSTOM_CONTENT)
  const isCustomButtonEnabled = useCheckForFeatureFlag(CREATE_CUSTOM_BUTTONS)
  const isLanguageEnabled = useCheckForFeatureFlag(SET_LANGUAGE)
  const isOfficeHoursEnabled = useCheckForFeatureFlag(OPERATING_HOURS)

  const isCheckIntoMeetingsEnabled = useCheckForFeatureFlag(CHECK_INTO_MEETINGS)
  const defaultFilter: Filter = {
    building_id: FilterSpecialValues.ALL,
    device_id: FilterSpecialValues.ALL,
  }

  const [storedFilterValues, saveFilter, setDefaultFilters] = useStoredFilter({
    filterName: ROOM_DEVICE_SETTINGS_FILTERS_STORE_NAME,
    defaultFilterValues: defaultFilter,
    hasSearch: false,
  })

  const [filters, setFilters] = useState<Filter>(storedFilterValues)
  const [filtersKey, setFiltersKey] = useState<string>(generateId())

  const reqParams = useRef<Filter>(storedFilterValues)

  const handleFilterChange = useCallback(
    async (filter: Filter) => {
      reqParams.current = { ...filter }
      updateHistory(pathname, reqParams.current, hash)
      setFilters(filter)
    },
    [pathname, hash],
  )

  const [logo, setLogo] = useState<File | null>()

  const [selectedContent, setSelectedContent] = useState<
    CustomContentResponse[]
  >([])

  const { data: { count: deviceCount = 0 } = {} } = useFetchRoomsDevicesQuery({
    limit: 1,
    ...(filters.building_id !== FilterSpecialValues.ALL
      ? {
          building_id: filters.building_id,
        }
      : {}),
  })

  const { data: company } = useFetchCompanyQuery()
  const [updateCompany, { isLoading: isUpdatingCompany }] =
    useUpdateCompanyMutation()

  const { data: building, isLoading: isBuildingLoading } =
    useFetchBuildingQuery(
      { id: filters.building_id },
      { skip: filters.building_id === FilterSpecialValues.ALL },
    )
  const [updateBuilding, { isLoading: isUpdatingBuilding }] =
    useUpdateBuildingMutation()

  const { data: device, isLoading: isFetchingRoomDevice } =
    useFetchRoomDeviceQuery(
      filters.device_id !== FilterSpecialValues.ALL
        ? filters.device_id
        : skipToken,
    )

  const exclusions = device?.type ? DEVICE_SPECIFIC_EXCLUSIONS[device.type] : []

  const [updateRoomDevice, { isLoading: isUpdatingDevice }] =
    useUpdateRoomDeviceMutation()

  const isLoading =
    isUpdatingCompany ||
    isBuildingLoading ||
    isFetchingRoomDevice ||
    isUpdatingBuilding ||
    isUpdatingDevice

  const { data: { results: customContentEntries = [] } = {} } =
    useFetchCustomContentListQuery({
      status: String(CUSTOM_CONTENT_STATUS.enabled),
    })

  const currentDeviceContents = useMemo(() => {
    return customContentEntries.filter((content) =>
      content.devices.includes(filters.device_id),
    )
  }, [customContentEntries, filters.device_id])

  const [updateCustomContent] = useUpdateCustomContentMutation()

  useScrollToLocation([isFetchingRoomDevice, isBuildingLoading])

  const isBuildingSettingsMode = useMemo(
    () =>
      filters.building_id !== FilterSpecialValues.ALL &&
      filters.device_id === FilterSpecialValues.ALL,
    [filters.building_id, filters.device_id],
  )

  const isDeviceSettingsMode = useMemo(
    () => !!filters.device_id && filters.device_id !== FilterSpecialValues.ALL,
    [filters.device_id],
  )

  const isCompanySettingsMode = useMemo(
    () => !isBuildingSettingsMode && !isDeviceSettingsMode,
    [isBuildingSettingsMode, isDeviceSettingsMode],
  )

  const hasCustomContent = useMemo(
    () =>
      isCustomContentEnabled &&
      device?.type &&
      DEVICES_WITH_CUSTOM_CONTENT.includes(device?.type),
    [device?.type, isCustomContentEnabled],
  )

  const hasCustomButton = useMemo(
    () =>
      isCustomButtonEnabled &&
      device?.type &&
      DEVICES_WITH_CUSTOM_BUTTON.includes(device?.type),
    [device?.type, isCustomButtonEnabled],
  )

  const selectedSettingsTypeDescription = useMemo(() => {
    const settingsMode = isBuildingSettingsMode
      ? "building"
      : isDeviceSettingsMode
        ? "device"
        : "company"

    return (
      <Trans
        i18nKey={`desktop.settings.rooms.device_settings.form.header.selected_${settingsMode}_settings`}
        values={{
          deviceCount,
          buildingName: building?.name ?? "",
          deviceName: device ? getDeviceLabel(device) : "",
          deviceType: device?.type
            ? ` (${getLabel(`deviceTypes.${device.type.toLowerCase()}` as LabelPaths)})`
            : "",
        }}
      />
    )
  }, [
    isBuildingSettingsMode,
    isDeviceSettingsMode,
    deviceCount,
    building?.name,
    device,
  ])

  const settings = useMemo<Settings | undefined>(() => {
    if (isBuildingSettingsMode) {
      return building?.settings_effective
    } else if (isDeviceSettingsMode) {
      return device?.settings_effective
    } else {
      return company?.settings_effective
    }
  }, [
    isBuildingSettingsMode,
    isDeviceSettingsMode,
    building?.settings_effective,
    device?.settings_effective,
    company?.settings_effective,
  ])

  const [settingsGroupsVisibility, setSettingsGroupsVisibility] = useState({
    [SETTINGS_GROUPS.GENERAL_GROUP]: false,
    [SETTINGS_GROUPS.CUSTOM_LOGO_GROUP]: false,
    [SETTINGS_GROUPS.OFFICE_HOURS_GROUP]: false,
    [SETTINGS_GROUPS.CUSTOM_BUTTON_GROUP]: false,
    [SETTINGS_GROUPS.LOCALIZATION_GROUP]: false,
    [SETTINGS_GROUPS.CUSTOM_CONTENT_GROUP]: false,
    [SETTINGS_GROUPS.MAINTENANCE_GROUP]: false,
  })

  const {
    GENERAL_GROUP,
    CUSTOM_LOGO_GROUP,
    OFFICE_HOURS_GROUP,
    CUSTOM_BUTTON_GROUP,
    LOCALIZATION_GROUP,
    CUSTOM_CONTENT_GROUP,
    MAINTENANCE_GROUP,
  } = settingsGroupsVisibility

  const handleToggleSettingsGroup = (
    settingsGroup: SettingsGroups,
    checked: boolean,
  ) =>
    setSettingsGroupsVisibility((prev) => ({
      ...prev,
      [settingsGroup]: checked,
    }))

  const handleSettingsGroupInit = (
    settingGroups: Record<SettingsGroups, boolean>,
  ) => setSettingsGroupsVisibility((prev) => ({ ...prev, ...settingGroups }))

  const disabledSettings = useMemo(
    () => ({
      COMPANY_SETTINGS: !isCompanySettingsEnabled && isCompanySettingsMode,
      BUILDING_SETTINGS: !isBuildingSettingsEnabled && isBuildingSettingsMode,
      DEVICE_SETTINGS: !isDeviceSettingsEnabled && isDeviceSettingsMode,
      CHECK_INTO_MEETINGS: !isCheckIntoMeetingsEnabled,
      CUSTOM_LOGO: !isCustomLogoEnabled || isDeviceSettingsMode,
      CUSTOM_CONTENT: !isCustomContentEnabled,
      CUSTOM_BUTTON: !isCustomButtonEnabled,
      LANGUAGE: !isLanguageEnabled,
      OFFICE_HOURS: !isOfficeHoursEnabled || isDeviceSettingsMode,
      MAINTENANCE: isDeviceSettingsMode,
    }),
    [
      isBuildingSettingsEnabled,
      isCheckIntoMeetingsEnabled,
      isCompanySettingsEnabled,
      isCustomButtonEnabled,
      isCustomContentEnabled,
      isCustomLogoEnabled,
      isDeviceSettingsEnabled,
      isLanguageEnabled,
      isOfficeHoursEnabled,
      isBuildingSettingsMode,
      isCompanySettingsMode,
      isDeviceSettingsMode,
    ],
  )

  const generalSettingsDisabled = useMemo(
    () =>
      disabledSettings.COMPANY_SETTINGS ||
      disabledSettings.BUILDING_SETTINGS ||
      disabledSettings.DEVICE_SETTINGS,
    [
      disabledSettings.BUILDING_SETTINGS,
      disabledSettings.COMPANY_SETTINGS,
      disabledSettings.DEVICE_SETTINGS,
    ],
  )

  const customButtonActionTypeOptions = getCustomButtonActionTypeOptions()
  const replaceableButtonOptions = getReplaceableButtonOptions()
  const replaceableDevice6ProButtonOptions =
    getReplaceableDevice6ProButtonOptions()
  const deviceCancelationEventOptions =
    device?.type && DEVICES_WITH_RESTRICTED_CANCEL_OPTIONS.includes(device.type)
      ? getRestrictedDeviceCancelationEventOptions()
      : getDeviceCancelationEventOptions()
  const deviceCheckinPeriodOptions = getDeviceCheckinPeriodOptions()
  const meetNowDurationOptions = getDeviceMeetNowDurationOptions()
  const deviceOnSpotDurationOptions = getDeviceOnSpotDurationOptions()

  const defaultValues = useMemo(
    () => ({
      custom_button: settings?.custom_button
        ? {
            ...settings?.custom_button,
            payload: {
              ...settings?.custom_button?.payload,
              authentication: {
                ...settings?.custom_button?.payload?.authentication,
                type:
                  settings?.custom_button?.payload?.authentication?.type ??
                  AUTHENTICATION_TYPES.NONE,
              },
            },
            action: customButtonActionTypeOptions.find(
              (o) => o.value === settings?.custom_button?.action,
            ),
            replaces:
              replaceableButtonOptions.find(
                (o) => o.value === settings?.custom_button?.replaces,
              ) ?? replaceableButtonOptions[0],
            replaces_pro: replaceableDevice6ProButtonOptions.find(
              (o) => o.value === settings?.custom_button?.replaces_pro,
            ),
          }
        : {
            replaces: replaceableButtonOptions[0],
            replaces_pro: replaceableDevice6ProButtonOptions[0],
          },
      device_cancel_meetings: deviceCancelationEventOptions.find(
        (o) => o.value === settings?.device_cancel_meetings,
      ),
      device_check_into_timeout: deviceCheckinPeriodOptions.find(
        (o) => o.value === settings?.device_check_into_timeout,
      ),
      device_cleaning_status: settings?.device_cleaning_status,
      device_display_charging: settings?.device_display_charging,
      device_logo: settings?.device_logo,
      device_meet_later: settings?.device_meet_later,
      device_meet_now_duration: meetNowDurationOptions.find(
        (o) => o.value === settings?.device_meet_now_duration,
      ),
      device_on_spot_duration: deviceOnSpotDurationOptions.find(
        (o) => o.value === settings?.device_on_spot_duration,
      ),
      device_no_wifi: settings?.device_no_wifi,
      device_show_meeting_organizer: settings?.device_show_meeting_organizer,
      device_show_meeting_subject: settings?.device_show_meeting_subject,
      device_show_meeting_description:
        settings?.device_show_meeting_description,
      device_show_tentative_meetings: settings?.device_show_tentative_meetings,
      device_sound: settings?.device_sound,
      device_touchscreen: settings?.device_touchscreen,
      language: LANGUAGE_OPTIONS.find((o) => o.value === settings?.language),
      maintenance_email: settings?.maintenance_email,
      office_hours: {
        ...settings?.office_hours,
        start_hour: {
          label: settings?.office_hours?.start_hour
            ? timeWithoutSeconds(settings.office_hours.start_hour)
            : t(
                "desktop.settings.rooms.device_settings.form.office_hours.start",
              ),
          value: settings?.office_hours?.start_hour
            ? timeWithoutSeconds(settings.office_hours.start_hour)
            : null,
        },
        stop_hour: {
          label: settings?.office_hours?.stop_hour
            ? timeWithoutSeconds(settings.office_hours.stop_hour)
            : t("desktop.settings.rooms.device_settings.form.office_hours.end"),
          value: settings?.office_hours?.stop_hour
            ? timeWithoutSeconds(settings.office_hours.stop_hour)
            : null,
        },
      },
      timeformat: getOption(
        TIME_FORMAT_OPTIONS,
        settings?.timeformat?.toString(),
      ),
      timezone: settings?.timezone
        ? {
            label: settings?.timezone,
            value: settings?.timezone,
          }
        : undefined,
    }),
    [
      settings?.custom_button,
      settings?.device_cancel_meetings,
      settings?.device_check_into_timeout,
      settings?.device_cleaning_status,
      settings?.device_display_charging,
      settings?.device_logo,
      settings?.device_meet_later,
      settings?.device_meet_now_duration,
      settings?.device_on_spot_duration,
      settings?.device_no_wifi,
      settings?.device_show_meeting_organizer,
      settings?.device_show_meeting_subject,
      settings?.device_show_meeting_description,
      settings?.device_show_tentative_meetings,
      settings?.device_sound,
      settings?.device_touchscreen,
      settings?.language,
      settings?.maintenance_email,
      settings?.office_hours,
      settings?.timeformat,
      settings?.timezone,
    ],
  )

  const methods = useForm<FormValues>({
    defaultValues,
  })

  const { setError, reset } = methods

  const onUpdateClick = useCallback(
    async (formValues: FormValues) => {
      let response

      const getValues = (isEnabled: boolean, properties: PartialSettingsKeys) =>
        (properties as Array<keyof FormValues>).reduce(
          (acc: Partial<Settings>, key) => {
            const value = formValues[key]
            acc[key] = isEnabled
              ? isOptionType(formValues[key])
                ? ((formValues[key] as any).value ?? null)
                : value
              : null
            return acc
          },
          {},
        )

      const settings = {
        ...formValues,
        custom_button:
          CUSTOM_BUTTON_GROUP && formValues.custom_button
            ? {
                ...formValues.custom_button,
                action: formValues?.custom_button?.action?.value,
                device: formValues?.custom_button?.device ?? null,
                location: formValues?.custom_button?.location ?? null,
                payload: {
                  ...formValues.custom_button?.payload,
                  authentication: {
                    ...formValues?.custom_button?.payload?.authentication,
                    type: formValues?.custom_button?.payload?.authentication
                      ?.type,
                  },
                  message: formValues?.custom_button?.payload?.message ?? "",
                },
                state:
                  formValues?.custom_button?.state ??
                  CUSTOM_BUTTON_STATES.STATE_ENABLED,
                friendly_key:
                  formValues?.custom_button?.friendly_key ?? generateString(12),
                replaces: formValues?.custom_button?.replaces?.value,
                replaces_pro: formValues?.custom_button?.replaces_pro?.value,
                title: formValues?.custom_button?.title ?? "",
              }
            : null,
        device_check_into_timeout:
          formValues?.device_check_into_timeout?.value ?? null,
        device_logo: CUSTOM_LOGO_GROUP
          ? (formValues?.device_logo ?? null)
          : null,
        maintenance_email:
          MAINTENANCE_GROUP && formValues?.maintenance_email
            ? formValues?.maintenance_email
            : null,
        office_hours:
          OFFICE_HOURS_GROUP && formValues?.office_hours
            ? {
                ...formValues.office_hours,
                start_hour: formValues?.office_hours?.start_hour?.value ?? null,
                stop_hour: formValues?.office_hours?.stop_hour?.value ?? null,
              }
            : null,

        /*
         * Set the following properties to null initially, as they cannot be undefined.
         * These properties will be set in the getValues function calls at the bottom.
         */
        language: null,
        timeformat: null,
        timezone: null,
        device_cancel_meetings: null,
        device_meet_now_duration: null,
        device_on_spot_duration: null,

        ...getValues(LOCALIZATION_GROUP, SETTINGS_GROUPS_PROPS.LOCALIZATION),
        ...getValues(GENERAL_GROUP, SETTINGS_GROUPS_PROPS.GENERAL),
      }

      if (logo && CUSTOM_LOGO_GROUP) {
        const pictureWithFixedName = new File(
          [logo],
          removeDotsAndSpaces(logo.name),
        )

        const file = await actions.uploadFile(pictureWithFixedName)

        if (uploadFileCompat.fulfilled.match(file)) {
          settings.device_logo = file.payload
        } else {
          errorToast(file.error.message)
          return
        }
      } else if (logo === null) {
        settings.device_logo = null
      }
      if (device?.id && hasCustomContent) {
        const selectedContentArray = CUSTOM_CONTENT_GROUP
          ? (selectedContent ?? [])
          : []

        if (
          hasCustomContentChanged(currentDeviceContents, selectedContentArray)
        ) {
          const updatedContent = getUpdatedContent(
            customContentEntries,
            selectedContentArray,
            currentDeviceContents,
            device,
          )

          const responses = await Promise.all(
            updatedContent.map(async (content) => {
              return updateCustomContent(content)
            }),
          )

          if (responses.some((r) => isRejected(r))) {
            const res = responses.find((r) => isRejected(r))
            if (res && isRejected(res)) {
              errorToast(res.error.message)
              return
            }
          }
        }
      }

      if (isBuildingSettingsMode) {
        if (building?.id && building?.name) {
          response = await updateBuilding({
            id: building.id,
            name: building.name,
            settings,
          })
        }
      } else if (isDeviceSettingsMode) {
        if (device?.id) {
          response = await updateRoomDevice({
            id: device.id,
            settings,
          })
        }
      } else {
        response = await updateCompany({ settings })
      }

      if (isRejected(response)) {
        const { error } = response

        if (isApiResponseError(error)) {
          setErrors(error.formError, setError, errorToast, FORM_MAPPING)
        }
      } else {
        infoToast(
          t(
            "desktop.settings.rooms.device_settings.form.settings_updated_toast",
          ),
        )
      }
    },
    [
      CUSTOM_BUTTON_GROUP,
      CUSTOM_LOGO_GROUP,
      MAINTENANCE_GROUP,
      OFFICE_HOURS_GROUP,
      LOCALIZATION_GROUP,
      GENERAL_GROUP,
      logo,
      isBuildingSettingsMode,
      isDeviceSettingsMode,
      device,
      hasCustomContent,
      actions,
      errorToast,
      building?.id,
      building?.name,
      updateBuilding,
      updateRoomDevice,
      CUSTOM_CONTENT_GROUP,
      selectedContent,
      currentDeviceContents,
      customContentEntries,
      updateCustomContent,
      infoToast,
      t,
      updateCompany,
      setError,
    ],
  )

  const handleOnLocationClick = useCallback(
    (location: EffectiveSettingsLocation) => {
      switch (location) {
        case EFFECTIVE_SETTINGS_LOCATIONS.COMPANY:
          setDefaultFilters({
            building_id: FilterSpecialValues.ALL,
            device_id: FilterSpecialValues.ALL,
          })
          // this forces the filter component to reset and get new default values
          setFiltersKey(generateId())

          break
        case EFFECTIVE_SETTINGS_LOCATIONS.BUILDING:
          setDefaultFilters({
            building_id: building?.id ?? FilterSpecialValues.ALL,
            device_id: FilterSpecialValues.ALL,
          })

          // this forces the filter component to reset and get new default values
          setFiltersKey(generateId())
          break
      }
    },
    [building?.id, setDefaultFilters],
  )

  const getEffectiveSettingsLocation = (keys: PartialSettingsKeys) => {
    if (isDeviceSettingsMode && keys.some((key) => !!device?.settings?.[key])) {
      return
    }
    if (
      (isDeviceSettingsMode || isBuildingSettingsMode) &&
      keys.some((key) => !!building?.settings?.[key])
    ) {
      return isDeviceSettingsMode
        ? EFFECTIVE_SETTINGS_LOCATIONS.BUILDING
        : undefined
    }
    return (isDeviceSettingsMode || isBuildingSettingsMode) &&
      keys.some((key) => !!company?.settings_effective?.[key])
      ? EFFECTIVE_SETTINGS_LOCATIONS.COMPANY
      : undefined
  }

  useEffect(() => {
    const isSettingGroupEnabled = (
      settingsGroup: keyof typeof disabledSettings,
      keys: PartialSettingsKeys,
    ) => {
      if (disabledSettings[settingsGroup]) {
        return false
      }
      if (isCompanySettingsMode) {
        return keys.some((key) => !!company?.settings_effective?.[key])
      } else if (isDeviceSettingsMode) {
        return keys.some((key) => !!device?.settings?.[key])
      } else if (isBuildingSettingsMode) {
        return keys.some((key) => !!building?.settings?.[key])
      }
      return false
    }

    if (settings) {
      reset(defaultValues)

      handleSettingsGroupInit({
        [SETTINGS_GROUPS.GENERAL_GROUP]: isSettingGroupEnabled(
          "COMPANY_SETTINGS",
          SETTINGS_GROUPS_PROPS.GENERAL,
        ),
        [SETTINGS_GROUPS.CUSTOM_LOGO_GROUP]: isSettingGroupEnabled(
          "CUSTOM_LOGO",
          SETTINGS_GROUPS_PROPS.CUSTOM_LOGO,
        ),
        [SETTINGS_GROUPS.OFFICE_HOURS_GROUP]: isSettingGroupEnabled(
          "OFFICE_HOURS",
          SETTINGS_GROUPS_PROPS.OFFICE_HOURS,
        ),
        [SETTINGS_GROUPS.CUSTOM_BUTTON_GROUP]: isSettingGroupEnabled(
          "CUSTOM_BUTTON",
          SETTINGS_GROUPS_PROPS.CUSTOM_BUTTON,
        ),
        [SETTINGS_GROUPS.LOCALIZATION_GROUP]: isSettingGroupEnabled(
          "COMPANY_SETTINGS",
          SETTINGS_GROUPS_PROPS.LOCALIZATION,
        ),
        [SETTINGS_GROUPS.CUSTOM_CONTENT_GROUP]:
          currentDeviceContents.length > 0,
        [SETTINGS_GROUPS.MAINTENANCE_GROUP]: isSettingGroupEnabled(
          "MAINTENANCE",
          SETTINGS_GROUPS_PROPS.MAINTENANCE,
        ),
      })
    }
  }, [
    isCompanySettingsMode,
    defaultValues,
    reset,
    settings,
    isDeviceSettingsMode,
    disabledSettings,
    company?.settings_effective,
    device?.settings,
    isBuildingSettingsMode,
    building?.settings,
    currentDeviceContents.length,
  ])

  const formDisabled =
    isUpdatingCompany || isUpdatingBuilding || isUpdatingDevice

  useEffect(() => {
    setSelectedContent(currentDeviceContents)
  }, [customContentEntries, currentDeviceContents, setSelectedContent])

  useEffect(
    () => () => {
      if (pathname.endsWith(ROOMS_DEVICE_SETTINGS_PATHNAME)) {
        saveFilter(reqParams.current)
      }
    },
    [saveFilter, pathname],
  )

  if (isLoading) {
    return <DeviceSettingsSkeleton />
  }

  return (
    <div className="DeviceSettingsForm">
      <FormProvider {...methods}>
        <PageForm
          updateMode
          onUpdate={onUpdateClick}
          disabled={formDisabled}
          header={
            <div className="device-settings-header">
              <div className="device-settings-header__controls">
                <DeviceSettingsFilters
                  onChange={handleFilterChange}
                  defaultValues={storedFilterValues}
                  key={filtersKey}
                />
              </div>
              <div className="device-settings-header__content">
                {selectedSettingsTypeDescription}
              </div>
            </div>
          }
        >
          <GeneralGroup
            checked={GENERAL_GROUP}
            onToggleSettingsGroup={handleToggleSettingsGroup}
            disabled={generalSettingsDisabled}
            cancelMeetingsDisabled={generalSettingsDisabled}
            checkIntoMeetingsDisabled={
              generalSettingsDisabled || !isCheckIntoMeetingsEnabled
            }
            effectiveSettingsLocation={getEffectiveSettingsLocation(
              SETTINGS_GROUPS_PROPS.GENERAL,
            )}
            onLocationClick={handleOnLocationClick}
            isSwitchHidden={isCompanySettingsMode}
            featureExclusions={exclusions}
            deviceType={device?.type}
          />

          <hr />

          <CustomLogoGroup
            checked={CUSTOM_LOGO_GROUP}
            onToggleSettingsGroup={handleToggleSettingsGroup}
            disabled={disabledSettings.CUSTOM_LOGO}
            setLogo={setLogo}
            effectiveSettingsLocation={getEffectiveSettingsLocation(
              SETTINGS_GROUPS_PROPS.CUSTOM_LOGO,
            )}
            onLocationClick={handleOnLocationClick}
            isDeviceSpecificEdit={isDeviceSettingsMode}
            isUnavailableWithCurrentPlan={!isCustomLogoEnabled}
          />

          <hr />

          <OfficeHoursGroup
            checked={OFFICE_HOURS_GROUP}
            onToggleSettingsGroup={handleToggleSettingsGroup}
            disabled={disabledSettings.OFFICE_HOURS}
            effectiveSettingsLocation={getEffectiveSettingsLocation(
              SETTINGS_GROUPS_PROPS.OFFICE_HOURS,
            )}
            onLocationClick={handleOnLocationClick}
            isSwitchHidden={isCompanySettingsMode}
            isDeviceSpecificEdit={isDeviceSettingsMode}
          />

          <hr />

          <CustomButtonGroup
            checked={CUSTOM_BUTTON_GROUP}
            onToggleSettingsGroup={handleToggleSettingsGroup}
            disabled={
              (!hasCustomButton && isDeviceSettingsMode) ||
              disabledSettings.CUSTOM_BUTTON
            }
            effectiveSettingsLocation={getEffectiveSettingsLocation(
              SETTINGS_GROUPS_PROPS.CUSTOM_BUTTON,
            )}
            onLocationClick={handleOnLocationClick}
            isUnavailableWithCurrentPlan={!isCustomButtonEnabled}
            selectedDevice={device}
          />

          <hr />

          <LocalizationGroup
            checked={LOCALIZATION_GROUP}
            onToggleSettingsGroup={handleToggleSettingsGroup}
            effectiveSettingsLocation={getEffectiveSettingsLocation(
              SETTINGS_GROUPS_PROPS.LOCALIZATION,
            )}
            onLocationClick={handleOnLocationClick}
            isSwitchHidden={isCompanySettingsMode}
          />
          {hasCustomContent && isDeviceSettingsMode && (
            <>
              <hr />

              <CustomContentGroup
                checked={CUSTOM_CONTENT_GROUP}
                onToggleSettingsGroup={handleToggleSettingsGroup}
                selectedContent={selectedContent}
                setSelectedContent={setSelectedContent}
              />
            </>
          )}

          {!isDeviceSettingsMode && (
            <MaintenanceGroup
              checked={MAINTENANCE_GROUP}
              onToggleSettingsGroup={handleToggleSettingsGroup}
              disabled={disabledSettings.MAINTENANCE}
              effectiveSettingsLocation={getEffectiveSettingsLocation(
                SETTINGS_GROUPS_PROPS.MAINTENANCE,
              )}
              onLocationClick={handleOnLocationClick}
            />
          )}
        </PageForm>
      </FormProvider>
    </div>
  )
}

const timeWithoutSeconds = (time: string) =>
  time.split(":").slice(0, 2).join(":")

export default DeviceSettingsForm

/**
 * Checks if the custom content for a device has changed.
 * @param deviceContents - The current device contents.
 * @param newContent - The new device contents.
 * @returns A boolean indicating whether the content has changed or not.
 */
const hasCustomContentChanged = (
  deviceContents: CustomContentResponse[],
  newContent: CustomContentResponse[],
): boolean => {
  if (deviceContents.length !== newContent?.length) {
    return true
  }
  const sortedDeviceContents = deviceContents.sort((a, b) => a.id - b.id)
  const sortedNewContent = newContent.sort((a, b) => a.id - b.id)

  for (let i = 0; i < sortedDeviceContents.length; i++) {
    if (sortedDeviceContents[i].id !== sortedNewContent[i].id) {
      return true
    }
  }

  return false
}

/**
 * Updates the list of devices based on the selected content items.
 * @param newSelectedContentItem The newly selected content item.
 * @param oldSelectedContentItem The previously selected content item.
 * @param device The room device.
 * @returns The updated list of devices.
 */
const updateDevices = (
  newSelectedContentItem: CustomContentResponse | undefined,
  oldSelectedContentItem: CustomContentResponse | undefined,
  device: RoomDeviceResponse,
) => {
  let devices: string[] = []

  if (newSelectedContentItem && oldSelectedContentItem) {
    const addedDevices = newSelectedContentItem.devices.filter(
      (id) => !oldSelectedContentItem.devices.includes(id),
    )
    devices.push(...addedDevices, device.id)

    const removedDevices = oldSelectedContentItem.devices.filter(
      (id) => !newSelectedContentItem.devices.includes(id),
    )
    devices = devices.filter((id) => !removedDevices.includes(id))
  } else if (newSelectedContentItem) {
    devices.push(...newSelectedContentItem.devices, device.id)
  } else if (oldSelectedContentItem) {
    devices = oldSelectedContentItem.devices.slice()
    devices = devices.filter((id) => id !== device.id)
  }

  return devices
}

/**
 * Returns the updated content based on the provided parameters.
 * @param customContentEntries - An array of all custom content entries.
 * @param selectedContent - An array of selected custom content entries.
 * @param currentDeviceContents - An array of current device contents entries.
 * @param device - The device.
 * @returns An array of updated content entries.
 */
const getUpdatedContent = (
  customContentEntries: CustomContentResponse[],
  selectedContent: CustomContentResponse[] | undefined,
  currentDeviceContents: CustomContentResponse[],
  device: RoomDeviceResponse,
) => {
  return customContentEntries.flatMap((content) => {
    const { id } = content
    const newSelectedContentItem = selectedContent?.find((c) => c.id === id)
    const oldSelectedContentItem = currentDeviceContents.find(
      (c) => c.id === id,
    )
    if (newSelectedContentItem === oldSelectedContentItem) {
      return []
    }
    const devices: string[] = updateDevices(
      newSelectedContentItem,
      oldSelectedContentItem,
      device,
    )
    return [{ ...content, devices }]
  })
}
