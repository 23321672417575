import React from "react"

import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { companySettingsURL } from "../../../../api"

import { useFetchTimeslotsQuery } from "../../../../redux/api/timeslots"
import { useAppSelector } from "../../../../redux/reducers"
import { selectSettingsEffective } from "../../../../redux/settings/selectors"
import { fetchSettings } from "../../../../redux/settings/settingsSlice"
import { TimeslotResponse } from "../../../../redux/timeslots/types"
import {
  formatSlotTime,
  timeslotComparator,
} from "../../../../redux/timeslots/utils"
import { useActions } from "../../../../redux/utils"

import { AsyncSwitch } from "../../../../components/advanced/AsyncToggle"
import Button from "../../../../components/advanced/Button"
import Skeleton from "../../../../components/advanced/Skeleton"
import Card from "../../../../components/basic/Card"
import Divider from "../../../../components/basic/Divider"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Filters from "../../../../components/Filter/Filters"
import FilterSpace from "../../../../components/Filter/FilterSpace"
import Intro from "../../../../components/Intro"
import NoDataFound from "../../../../components/NoDataFound"
import Pagination from "../../../../components/Pagination"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./style.sass"

function TimeSlots() {
  const { t } = useTranslation()

  const history = useHistory()

  const { data: slots = [], isLoading } = useFetchTimeslotsQuery()

  const actions = useActions({
    fetchSettings: () => fetchSettings(),
  })

  const { entry: settings } = useAppSelector(selectSettingsEffective)

  const handleEditClick = (id: string) => {
    history.push(`/settings/desks/time-slots/edit/${id}`)
  }

  const handleNewClick = () => {
    history.push("/settings/desks/time-slots/add")
  }

  return (
    <View className="TimeSlots">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.desks.title"),
          t("desktop.settings.timeslots.title"),
        ]}
      />

      <Intro isConstrained>{t("desktop.settings.timeslots.subtitle")}</Intro>

      <Space size={0.75} />

      <Filters>
        <AsyncSwitch
          urlGenerator={() => companySettingsURL()}
          bodyGenerator={(value) => ({
            settings: {
              desk_force_timeslot_use: value,
            },
          })}
          refresh={actions.fetchSettings}
          value={settings?.desk_force_timeslot_use ?? false}
          label={t(
            "desktop.settings.booking_policies.form.require_pre_defined_timeslots",
          )}
        />

        <FilterSpace />
        <Button onClick={() => handleNewClick()} isSmall>
          {t("desktop.settings.timeslots.form.new_timeslot")}
        </Button>
      </Filters>

      <Space size={0.75} />

      <div className="timeslots-container">
        {isLoading && (
          <Skeleton
            skeletonGroup={{
              lines: [
                { width: "100%", space: 0.5 },
                { width: "100%", space: 0.5 },
                { width: "100%", space: 0.5 },
              ],
            }}
          />
        )}

        {!isLoading && slots.length === 0 && (
          <NoDataFound>
            {t("desktop.settings.timeslots.no_timeslots")}
          </NoDataFound>
        )}

        {!isLoading && (
          <Card>
            <>
              {slots
                .filter((s: TimeslotResponse) => s.active)
                .sort(timeslotComparator)
                .map(
                  (
                    { id, name, from, to, is_default }: TimeslotResponse,
                    i: number,
                  ) => (
                    <p key={`slot-${i}`}>
                      <span className="timerange">
                        {formatSlotTime(from)} - {formatSlotTime(to)}
                      </span>

                      <span className="name">
                        <strong>{name}</strong>
                        {is_default && (
                          <span>
                            {" "}
                            ({t("desktop.settings.timeslots.is_default")})
                          </span>
                        )}
                      </span>

                      <Button
                        onClick={() => handleEditClick(id)}
                        variant="secondary"
                      >
                        {t("desktop.settings.timeslots.form.edit_timeslot")}
                      </Button>
                    </p>
                  ),
                )}
            </>

            <Space size={0.75} />

            <Divider hasMargin={false} />

            <Pagination
              from={1}
              to={slots.length}
              hasPrevious={false}
              hasNext={false}
              total={slots.length}
              links={[]}
              setPage={() => {}}
              onNext={() => {}}
              onPrevious={() => {}}
              items={t("desktop.settings.timeslots.timeslots")}
            />
          </Card>
        )}
      </div>
    </View>
  )
}

export default TimeSlots
