import React, { useState } from "react"

import dayjs from "dayjs"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import ReactModal from "react-modal"

import { useToast } from "../../../../hooks/useToast"
import { contentStyle, overlayStyle } from "../../../../modals/modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import {
  useGenerateDeviceReportMutation,
  useGetDeviceReportStatusQuery,
} from "../../../../redux/api/devices"
import { DEVICE_REPORT_STATUS } from "../../../../redux/api/devices/types"

import { DatePicker } from "../../../../components/advanced/DatePicker"
import Field from "../../../../components/Field"
import ModalForm from "../../../../components/Form/ModalFormHook"

import "./DeviceReportModal.sass"

const MAX_DATE_RANGE = 30 // Maximum date range in days
const REPORT_LIMIT_MINUTES = 60 // Limit for report generation in minutes

type Props = {
  open: boolean
}

type FormValues = {
  start: Date
  end: Date
}

const DeviceReportModal = ({ open }: Props) => {
  const { t } = useTranslation()
  const { closeModal } = useModals()
  const { errorToast, infoToast } = useToast()

  const [lastReportTime, setLastReportTime] = useState<Date | null>(null)

  const today = dayjs().startOf("day")
  const thirtyDaysLater = today.add(MAX_DATE_RANGE, "day").endOf("day")

  const methods = useForm<FormValues>({
    defaultValues: {
      start: today.toDate(),
      end: thirtyDaysLater.toDate(),
    },
  })

  const { control, getValues, watch } = methods

  const startDate = watch("start")
  const endDate = watch("end")

  const { data: reportStatusData } = useGetDeviceReportStatusQuery(
    undefined,
    {},
  )

  const isReportInProgress =
    reportStatusData &&
    (reportStatusData.status === DEVICE_REPORT_STATUS.PENDING ||
      reportStatusData.status === DEVICE_REPORT_STATUS.PROCESSING)

  const [generateReport, { isLoading: isGenerating }] =
    useGenerateDeviceReportMutation()

  const validateReport = () => {
    const { start, end } = getValues()

    // Check if there's already a pending report
    if (isReportInProgress) {
      errorToast(
        t("desktop.settings.rooms.devices.report.toast.error_limit_exceeded", {
          minutes: REPORT_LIMIT_MINUTES,
        }),
      )
      return false
    }

    // Check if the date range is too far apart
    const diffDays = dayjs(end).diff(dayjs(start), "day")
    if (diffDays > MAX_DATE_RANGE) {
      errorToast(
        t("desktop.settings.rooms.devices.report.toast.error_max_range", {
          days: MAX_DATE_RANGE,
        }),
      )
      return false
    }

    // Check if we've already generated a report within the time limit
    if (lastReportTime) {
      const minutesSinceLastReport = dayjs().diff(
        dayjs(lastReportTime),
        "minute",
      )
      if (minutesSinceLastReport < REPORT_LIMIT_MINUTES) {
        errorToast(
          t(
            "desktop.settings.rooms.devices.report.toast.error_limit_exceeded",
            {
              minutes: REPORT_LIMIT_MINUTES,
            },
          ),
        )
        return false
      }
    }

    return true
  }

  const handleGenerateReport = async ({ start, end }: FormValues) => {
    if (!validateReport()) return

    try {
      await generateReport({
        start: dayjs(start).startOf("day").toISOString(),
        end: dayjs(end).endOf("day").toISOString(),
      })

      setLastReportTime(new Date())

      infoToast(t("desktop.settings.rooms.devices.report.toast.generating"))

      closeModal() // The report will continue generating in the background
    } catch (err) {
      errorToast(
        t("desktop.settings.rooms.devices.report.toast.error_generating"),
      )
    }
  }

  return (
    <ReactModal
      isOpen={open}
      style={{ content: contentStyle, overlay: overlayStyle }}
      onRequestClose={() => closeModal()}
      ariaHideApp={false}
      className="DeviceReportModal"
    >
      <FormProvider {...methods}>
        <ModalForm
          className="DeviceReportForm"
          updateMode={true}
          title={t("desktop.settings.rooms.devices.report.title")}
          onUpdate={handleGenerateReport}
          updateButtonText={t("general.download")}
          disabled={isGenerating || isReportInProgress}
          isLoading={isGenerating || isReportInProgress}
        >
          <Field
            control={control}
            name="start"
            label={t("desktop.manage.visitors.export.from")}
            className="field-width-50"
          >
            {(props) => <DatePicker {...props} maxDate={endDate} />}
          </Field>
          <Field
            control={control}
            name="end"
            label={t("desktop.manage.visitors.export.to")}
            className="field-width-50"
          >
            {(props) => <DatePicker {...props} minDate={startDate} />}
          </Field>
          <div className="hint">
            {t("desktop.settings.rooms.devices.report.description")}
          </div>
        </ModalForm>
      </FormProvider>
    </ReactModal>
  )
}

export default DeviceReportModal
