import React from "react"

import { useBackgroundCheckin } from "../../../hooks/mobile/useBackgroundCheckin"

export const BackgroundCheckinContext = React.createContext({})

/**
 * This provider is used to manage the background check-in functionality.
 * It calls the useBackgroundCheckin hook to manage the background check-in functionality.
 */

const BackgroundCheckinProvider: React.FC = () => {
  useBackgroundCheckin()

  return (
    <BackgroundCheckinContext.Provider
      value={{}}
    ></BackgroundCheckinContext.Provider>
  )
}

export default BackgroundCheckinProvider
