import { api } from ".."
import {
  roomBookingRoomsURL,
  roomBookingRoomURL,
  roomBookingScanRoomsURL,
} from "../../../api"
import { endScan, startScan } from "../../autoScanRooms/autoScanRoomsSlice"
import { PaginatedOptions } from "../../types"
import {
  CheckScanRoomsResponse,
  RbRoomResponse,
  RoomRequestWithId,
  RoomsFetchRequest,
  RoomsResponse,
} from "./types"

import { FilterSpecialValues } from "../../../components/Filter/types"

export const roomBookingRooms = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchRoomsRb: builder.query<
      RoomsResponse & { offset: number },
      PaginatedOptions & RoomsFetchRequest
    >({
      query: ({ floor, building, ...options }) => {
        const params = {
          ...(floor &&
            floor !== FilterSpecialValues.ALL && { floor_id: floor }),
          ...(building &&
            building !== FilterSpecialValues.ALL && { building_id: building }),
          ...options,
        }
        return {
          url: roomBookingRoomsURL({ ...params }),
        }
      },
      providesTags: (result, _error) =>
        result
          ? [
              ...result.results.map(({ key }) => ({
                type: "RbRooms" as const,
                id: key,
              })),
              { type: "RbRooms", id: "LIST" },
            ]
          : [{ type: "RbRooms", id: "LIST" }],
      transformResponse: (response: RoomsResponse, _, arg) => {
        return {
          ...response,
          offset: arg?.offset ?? 0,
        }
      },
    }),
    fetchRoomRb: builder.query<RbRoomResponse, number | string>({
      query: (id) => ({
        url: roomBookingRoomURL(id?.toString()),
      }),
      providesTags: (_result, _error, id) => [{ type: "RbRooms", id: id }],
    }),
    createRoomRb: builder.mutation<
      RbRoomResponse,
      { key: string; calendar_id: string | null }
    >({
      query: (body) => ({
        url: roomBookingRoomsURL(),
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "RbRooms", id: "LIST" }],
    }),
    updateRoomRb: builder.mutation<RbRoomResponse, RoomRequestWithId>({
      query: ({ id, ...body }) => ({
        url: roomBookingRoomURL(id?.toString()),
        method: "PATCH",
        body: {
          id,
          ...body,
        },
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "RbRooms", id: "LIST" },
        { type: "RbRooms", id: id },
      ],
    }),
    destroyRoomRb: builder.mutation<void, number>({
      query: (id) => ({
        url: roomBookingRoomURL(id.toString()),
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: "RbRooms", id: "LIST" },
        {
          type: "RbRooms",
          id,
        },
      ],
    }),
    startScanRoomsRb: builder.mutation<void, void>({
      query: () => ({
        url: roomBookingScanRoomsURL(),
        method: "POST",
      }),
    }),
    checkScanRoomsRb: builder.query<CheckScanRoomsResponse, void>({
      query: () => ({
        url: roomBookingScanRoomsURL(),
      }),
    }),
    startScanAndCheckRoomsRb: builder.mutation<null, void>({
      queryFn: async (_arg, { dispatch }, _extraOptions, fetchWithBQ) => {
        dispatch(startScan())

        const response = await fetchWithBQ({
          url: roomBookingScanRoomsURL(),
          method: "POST",
        })

        if (response.error) {
          dispatch(endScan())
          return {
            error: response.error,
          }
        }

        const scanStatus = setInterval(async () => {
          const response = await fetchWithBQ({
            url: roomBookingScanRoomsURL(),
          })

          if (response.error) {
            dispatch(endScan())
            return {
              error: response.error,
            }
          }

          const { in_progress } = response.data as CheckScanRoomsResponse

          if (!in_progress) {
            dispatch(endScan())
            clearInterval(scanStatus)
            dispatch(api.util.invalidateTags(["RbRooms"]))
          }
        }, 5000)

        return {
          data: null,
        }
      },
    }),
  }),
})

export const {
  useFetchRoomsRbQuery,
  useLazyFetchRoomsRbQuery,
  useFetchRoomRbQuery,
  useCreateRoomRbMutation,
  useUpdateRoomRbMutation,
  useDestroyRoomRbMutation,
  useStartScanRoomsRbMutation,
  useCheckScanRoomsRbQuery,
  useStartScanAndCheckRoomsRbMutation,
} = roomBookingRooms
