import SkeletonReact, {
  SkeletonProps as ReactSkeletonProps,
} from "react-loading-skeleton"

import Space from "../../Space"
import "react-loading-skeleton/dist/skeleton.css"

import "./style.sass"

type SkeletonProps = {
  skeletonGroup?: {
    width?: string
    lines: {
      width?: string
      height?: string
      space?: number
    }[]
  }
} & ReactSkeletonProps

const Skeleton = (props: SkeletonProps) => {
  const { skeletonGroup, ...rest } = props

  if (skeletonGroup?.lines.length) {
    return (
      <div className="skeleton-group" style={{ width: skeletonGroup.width }}>
        {skeletonGroup.lines.map((item, index) => (
          <>
            <SkeletonReact
              key={index}
              width={item.width}
              height={item.height}
            />
            <Space size={item.space || 0} />
          </>
        ))}
      </div>
    )
  }

  return <SkeletonReact containerClassName="container-skeleton" {...rest} />
}

export default Skeleton
