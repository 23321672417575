import { Trans, useTranslation } from "react-i18next"

import { FEATURE_FLAGS } from "../../../constants"
import { useCheckForFeatureFlag } from "../../../hooks/useCheckForFeatureFlag"
import { useToast } from "../../../hooks/useToast"
import { checkCalendarCompletion } from "../Integrations/Calendars/constants"
import { INTEGRATIONS_PATHS } from "../Integrations/constants"
import { ROOMS_PATHS } from "./constants"
import { SETTINGS_GROUPS } from "./DeviceSettings/constants"
import { isRejected } from "@reduxjs/toolkit"

import { useFetchCalendarsQuery } from "../../../redux/api/calendars"
import { useFetchCompanyQuery } from "../../../redux/api/company"
import { useFetchRoomsDevicesQuery } from "../../../redux/api/devices"
import { useStartScanAndCheckRoomsRbMutation } from "../../../redux/api/roomBookingRooms"
import {
  useFetchRoomsQuery,
  useStartScanAndCheckRoomsMutation,
} from "../../../redux/api/rooms"
import { isApiResponseError } from "../../../redux/api/types"
import { selectAutoScanRooms } from "../../../redux/autoScanRooms/selectors"
import { useAppSelector } from "../../../redux/reducers"

import Button from "../../../components/advanced/Button"
import { RedirectLink } from "../../../components/advanced/RedirectLink"
import NoDataFound from "../../../components/NoDataFound"

const OnboardingTableInfo = () => {
  const { t } = useTranslation()
  const { errorToast } = useToast()

  const {
    data: { results: calendars = [] } = {},
    isLoading: areCalendarsLoading,
  } = useFetchCalendarsQuery()

  const { data: { count: roomCount = 0 } = {}, isLoading: areRoomsLoading } =
    useFetchRoomsQuery({
      limit: 1,
    })

  const {
    data: { count: deviceCount = 0 } = {},
    isLoading: areDevicesLoading,
  } = useFetchRoomsDevicesQuery({
    limit: 1,
  })

  const { data: company, isLoading: isCompanyLoading } = useFetchCompanyQuery()

  const isRoomBookingEnabledFlag = useCheckForFeatureFlag(
    FEATURE_FLAGS.ROOM_BOOKING,
  )

  const [startScanAndCheckRoomsQuery] = useStartScanAndCheckRoomsMutation()
  const [startScanAndCheckRoomsRbQuery] = useStartScanAndCheckRoomsRbMutation()

  const { syncInProgress } = useAppSelector(selectAutoScanRooms)

  const handleScanRooms = async () => {
    const response = isRoomBookingEnabledFlag
      ? await startScanAndCheckRoomsRbQuery()
      : await startScanAndCheckRoomsQuery()

    if (isRejected(response)) {
      const { error } = response

      if (isApiResponseError(error)) {
        errorToast(error.message)
      }
      return
    }
  }

  const hasCalendar =
    calendars.length > 0 && checkCalendarCompletion(calendars[0])

  const hasOfficeHours = !!company?.settings?.office_hours

  if (
    areCalendarsLoading ||
    areRoomsLoading ||
    areDevicesLoading ||
    isCompanyLoading
  ) {
    return null
  }

  if (!hasCalendar) {
    return (
      <NoDataFound warning>
        <Trans i18nKey="desktop.settings.rooms.rooms.no_rooms.no_calendar_description">
          <Button
            className="OnboardingTableInfo__Button"
            variant="link"
            to={INTEGRATIONS_PATHS.calendars}
          >
            {t("desktop.settings.rooms.rooms.no_rooms.connect_calendar")}
          </Button>
        </Trans>
      </NoDataFound>
    )
  }

  if (roomCount === 0) {
    return (
      <NoDataFound warning>
        <Trans i18nKey="desktop.settings.rooms.rooms.no_rooms.description">
          <Button variant="link" to={ROOMS_PATHS.addRoom}>
            {t("desktop.settings.rooms.rooms.no_rooms.add_rooms_manually")}
          </Button>

          <Button
            variant="link"
            onClick={handleScanRooms}
            isDisabled={syncInProgress}
          >
            {t("desktop.settings.rooms.rooms.no_rooms.import_automatically")}
          </Button>
        </Trans>
      </NoDataFound>
    )
  }

  if (deviceCount === 0) {
    return (
      <NoDataFound warning>
        <Trans
          i18nKey="desktop.settings.rooms.devices.no_devices.description"
          components={{
            a: (
              <RedirectLink to="how-to-add-a-device-to-my-joan">
                this knowledge base article
              </RedirectLink>
            ),
          }}
        />
      </NoDataFound>
    )
  }

  if (!hasOfficeHours) {
    return (
      <NoDataFound warning>
        {t("desktop.settings.rooms.devices.no_office_hours.description")}{" "}
        <Button
          variant="link"
          to={`${ROOMS_PATHS.deviceSettings}#${SETTINGS_GROUPS.OFFICE_HOURS_GROUP}`}
        >
          {t("desktop.settings.rooms.devices.no_office_hours.description_link")}
        </Button>
      </NoDataFound>
    )
  }

  return null
}

export default OnboardingTableInfo
