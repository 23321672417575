import dayjs from "dayjs"

import { useNavigation } from "../../../../../hooks/useNavigation"
import { Children, ISODate } from "../../../../../types/sharedTypes"
import { ROOM_BOOKING_PATHS } from "../../constants"

type Props = {
  roomId: number
  day: ISODate
  children: Children
}

const WeekViewCell = ({ roomId, day, children }: Props) => {
  const { push } = useNavigation()

  const handleCellClick = () => {
    const queryParams = new URLSearchParams({
      roomId: roomId.toString(),
      startTime: "",
      date: dayjs(day).format("YYYY-MM-DD"),
    })

    push(`${ROOM_BOOKING_PATHS.add}?${queryParams.toString()}`)
  }

  return (
    <div className="cell" onClick={handleCellClick}>
      {children}
    </div>
  )
}

export default WeekViewCell
