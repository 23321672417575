import { MouseEvent, useEffect, useRef, useState } from "react"

import cn from "classnames"
import dayjs from "dayjs"
import { useDrag } from "react-dnd"
import { useTranslation } from "react-i18next"
import { Resizable, ResizeCallbackData } from "react-resizable"

import { timeZone } from "../../../../../dayjs"
import { useToast } from "../../../../../hooks/useToast"
import { ISODate } from "../../../../../types/sharedTypes"
import { userTimeFormat } from "../../../../../utils"
import {
  DRAGGABLE_IDENTIFIER,
  normalizeToIncrement,
  PIXELS_PER_HOUR,
} from "../constants"
import { ReservationPosition } from "./DayView"
import "react-resizable/css/styles.css"

import { usePatchRoomReservationMutation } from "../../../../../redux/api/roomReservations"
import { ReservationVisibility } from "../../../../../redux/api/roomReservations/types"
import { isApiResponseError, isRejected } from "../../../../../redux/api/types"

import "./ReservationCard.sass"

type Props = {
  reservationId: string
  scrollTop: number
  title: string
  startTime: ISODate
  endTime: ISODate
  gridHeight?: number
  onClick: (e: MouseEvent<HTMLDivElement>) => void
  my: boolean
  visibility: ReservationVisibility
  position: ReservationPosition
  isReadOnly?: boolean
  isPrivateTitle?: boolean
}

const ROUNDING_INTERVAL = 15

enum ReservationType {
  My = "my",
  Private = "private",
  Public = "public",
}

const ReservationCard = ({
  reservationId,
  scrollTop,
  title,
  startTime,
  endTime,
  my,
  visibility,
  onClick,
  position,
  isReadOnly,
  isPrivateTitle,
}: Props) => {
  const { t } = useTranslation()

  const [patchRoomReservation] = usePatchRoomReservationMutation()
  const { errorToast } = useToast()

  const [currEndTime, setCurrEndTime] = useState(endTime)

  const [isOverdue, setOverdue] = useState(dayjs(currEndTime).isBefore(dayjs()))

  const getReservationType = () => {
    if (visibility === ReservationVisibility.PRIVATE)
      return ReservationType.Private
    if (visibility === ReservationVisibility.PUBLIC)
      return my ? ReservationType.My : ReservationType.Public
  }

  const { height, width, left, top, zIndex } = position

  const [isResizing, setIsResizing] = useState(false)
  const [resizableHeight, setResizableHeight] = useState(height)

  const scrollTopOnDrag = useRef<number | null>(null)

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: DRAGGABLE_IDENTIFIER,
      item: () => {
        scrollTopOnDrag.current = scrollTop
        return {
          id: reservationId,
          startTime,
          endTime,
          initialScrollTop: scrollTopOnDrag.current,
        }
      },
      canDrag: !isResizing,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [startTime, endTime, position, scrollTop],
  )

  const handleResizeStop = async (
    e: React.SyntheticEvent,
    { size }: ResizeCallbackData,
  ) => {
    e.stopPropagation()

    if (isDragging) {
      return
    }

    const { height: newHeight } = size

    const addedMinutes = (newHeight / PIXELS_PER_HOUR) * 60

    const roundedMinutes = normalizeToIncrement(addedMinutes, ROUNDING_INTERVAL)

    const newEndTime = dayjs(startTime)
      .add(roundedMinutes, "minute")
      .toISOString()

    const response = await patchRoomReservation({
      id: reservationId,
      start: startTime,
      end: newEndTime,
      tz: timeZone,
    })

    if (isRejected(response)) {
      const { error } = response

      if (isApiResponseError(error)) {
        errorToast(error.message)
      }
    }

    setIsResizing(false)
  }

  const handleResize = (
    e: React.SyntheticEvent,
    { size }: ResizeCallbackData,
  ) => {
    e.stopPropagation()

    if (isDragging) {
      return
    }

    if (!isResizing) {
      setIsResizing(true)
    }

    const { height: newHeight } = size

    const addedMinutes = (newHeight / PIXELS_PER_HOUR) * 60

    const roundedMinutes = normalizeToIncrement(addedMinutes, ROUNDING_INTERVAL)

    let newEndTime = dayjs(startTime)
      .add(roundedMinutes, "minute")
      .toISOString()

    if (dayjs(newEndTime).isAfter(dayjs(startTime).endOf("day"))) {
      newEndTime = dayjs(startTime)
        .add(1, "day")
        .hour(0)
        .minute(0)
        .toISOString()
    }

    setCurrEndTime(newEndTime)
    setResizableHeight(newHeight)
  }

  const hoursLeft =
    24 - dayjs(startTime).hour() - dayjs(startTime).minute() / 60
  const maximumHeight = hoursLeft * PIXELS_PER_HOUR
  const minimumHeight = 15

  const reservationClassNames = cn("reservation", getReservationType(), {
    "is-overdue": isOverdue,
    "is-dragging": isDragging,
    "is-resizing": isResizing,
    compact: resizableHeight < PIXELS_PER_HOUR,
    full: resizableHeight >= PIXELS_PER_HOUR,
  })

  const formattedTitle = isPrivateTitle
    ? t("desktop.manage.room_booking.calendar.reservation_info.private")
    : title || t("desktop.manage.room_booking.calendar.no_title")

  useEffect(() => {
    setCurrEndTime(endTime)
  }, [endTime])

  useEffect(() => {
    const interval = setInterval(() => {
      setOverdue(dayjs(currEndTime).isSameOrBefore(dayjs()))
    }, 60 * 1000)

    return () => clearInterval(interval)
  }, [currEndTime])

  return (
    <Resizable
      height={resizableHeight}
      width={width}
      axis="y"
      minConstraints={[width, minimumHeight]}
      maxConstraints={[width, maximumHeight]}
      onResizeStop={!isReadOnly ? handleResizeStop : undefined}
      onResize={!isReadOnly ? handleResize : undefined}
      handle={!isReadOnly ? <div className="resizable-handle" /> : <></>}
    >
      <div
        ref={!isResizing && !isReadOnly ? dragRef : undefined}
        className={reservationClassNames}
        onClick={!isResizing ? onClick : undefined}
        style={{
          top: `${top}px`,
          left: `${left}%`,
          width: `${width}%`,
          height: `${resizableHeight}px`,
          zIndex,
        }}
      >
        <div className="name">{formattedTitle}</div>
        <div className="time">
          {dayjs(startTime).format(userTimeFormat())} –{" "}
          {dayjs(currEndTime).format(userTimeFormat())}
        </div>
      </div>
    </Resizable>
  )
}

export default ReservationCard
