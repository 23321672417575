import { useEffect, useMemo, useRef, useState } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { useFloorPlanContext } from "./FloorPlanProvider"
import { DeskReservationList } from "./SpotReservations/DeskReservationList"
import RoomReservationList from "./SpotReservations/RoomReservationList"
import { DeskWithReservations, RoomWithReservations, SpotTypes } from "./types"
import { getAmenities, getDepartments, normalizeSpotId } from "./utils"

import { AmenityResponse } from "../../../redux/amenities/types"
import { CalendarType } from "../../../redux/api/calendars/types"
import { DepartmentResponse } from "../../../redux/api/departments/types"

import Button from "../../../components/advanced/Button"

import ChevronSVG from "../../../assets/images/icons/Chevron.svg"

import "./SpotList.sass"

type SpotListProps = {
  spotsWithReservations: (DeskWithReservations | RoomWithReservations)[]
  canBookSpots: boolean
  roomsBookable?: Record<string, boolean>
}

/**
 * Component: SpotList
 */
const SpotList = ({ spotsWithReservations, canBookSpots }: SpotListProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      document.body.style.setProperty(
        "--spot-list-height",
        `${ref.current.clientHeight - 60}px`,
      )
    }
  }, [])

  useEffect(() => {
    ref.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, [spotsWithReservations.length])

  return (
    <div className="spot-list-wrapper">
      <div className="floor-plan-spot-list" ref={ref}>
        {spotsWithReservations.map((spot) => (
          <SpotItem canBookSpots={canBookSpots} key={spot.id} spot={spot} />
        ))}
      </div>
    </div>
  )
}

/**
 * Component: SpotItem
 */
type SpotItemProps = {
  spot: DeskWithReservations | RoomWithReservations
  canBookSpots: boolean
}

export const SpotItem = ({ spot, canBookSpots }: SpotItemProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isSubdued, setIsSubdued] = useState<boolean>(false)

  const spotId = normalizeSpotId(spot.id)

  const {
    hoveredSpot,
    clearHoveredSpot,
    openedSpot,
    setOpenedSpot,
    clearOpenedSpot,
    createNewDeskReservation,
    createNewRoomReservation,
    setHoveredSpot,
    scrollToView,
  } = useFloorPlanContext()
  const { t } = useTranslation()

  const isDeskSpot = spot.type === SpotTypes.Desk
  const isRoomSpot = spot.type === SpotTypes.Room

  const departments = getDepartments(spot)
  const amenities = getAmenities(spot)

  const isDisabled = spot.isDisabled
  const isOpened = spotId === openedSpot || spot.highlight
  const isHovered = spotId === hoveredSpot

  const hasReservations = spot.reservations.length > 0
  const hasDepartments = (departments.length ?? 0) > 0
  const hasAmenities = amenities.length > 0

  const spotStatus = useMemo(() => {
    if (isDisabled) return "disabled"

    if (hasReservations) return "booked"

    return "available"
  }, [isDisabled, hasReservations])

  const classes = classNames("spot-item", {
    hovered: isHovered,
    isOpened,
    disabled: isDisabled,
  })

  const reservationListClasses = classNames({ subdued: isSubdued })
  const detailsClasses = classNames("details", isOpened ? "opened" : "closed")

  const handleOnHeaderClick = (id: string) => {
    isOpened ? clearOpenedSpot() : setOpenedSpot(id)
  }

  const handleBookSpotClick = (
    spot: DeskWithReservations | RoomWithReservations,
  ) => {
    if (spot.type === SpotTypes.Desk) {
      createNewDeskReservation(spot)
    }

    if (spot.type === SpotTypes.Room) {
      createNewRoomReservation(spot)
    }
  }

  useEffect(() => {
    if (isOpened && scrollToView) {
      ref.current?.scrollIntoView({
        block: spot.highlight ? "center" : "start",
        behavior: "smooth",
      })
    }
  }, [isOpened, scrollToView])

  return (
    <div
      className={classes}
      ref={ref}
      onMouseEnter={() => setHoveredSpot(spotId)}
      onMouseLeave={() => clearHoveredSpot()}
    >
      <div className="header" onClick={() => handleOnHeaderClick(spotId)}>
        <div className="title">
          <div className="spot-name">{spot.name}</div>
          <div className="icon">
            <ChevronSVG />
          </div>
        </div>
        <div className="status">
          <StatusDot status={spotStatus} />{" "}
          {isDeskSpot && (
            <span>{t("desktop.manage.floor_plan.desk_item_desk")}</span>
          )}
          {isRoomSpot && (
            <span>{t("desktop.manage.floor_plan.spot_item_room")}</span>
          )}
        </div>
      </div>

      <div className={detailsClasses}>
        {hasDepartments && <DepartmentList departments={departments} />}

        {hasAmenities && <AmenitiesList amenities={amenities} />}

        {hasReservations && isDeskSpot && (
          <DeskReservationList
            reservations={spot.reservations}
            className={reservationListClasses}
          />
        )}

        {hasReservations && isRoomSpot && (
          <RoomReservationList
            reservations={spot.reservations}
            isReadOnlyCalendar={
              spot.calendar && spot.calendar.type === CalendarType.ICALENDAR
            }
            className={reservationListClasses}
          />
        )}

        {!spot.isDisabled && canBookSpots && (
          <Button
            className="book-spot"
            variant="link"
            onClick={() => handleBookSpotClick(spot)}
            onMouseEnter={() => setIsSubdued(true)}
            onMouseLeave={() => setIsSubdued(false)}
          >
            {t("desktop.manage.floor_plan.desk_item_book_desk")}
          </Button>
        )}
      </div>
    </div>
  )
}

/**
 * Component: StatusDot
 */
type StatusDotProps = { status: "booked" | "available" | "disabled" }

export const StatusDot = ({ status }: StatusDotProps) => {
  const classes = classNames("status-dot", status)

  return <div className={classes} />
}

/**
 * Component: DepartmentList
 */
type DepartmentListProps = {
  departments: DepartmentResponse[]
}

export const DepartmentList = ({ departments }: DepartmentListProps) => {
  return (
    <div className="department-list">
      {departments.map((d) => (
        <div className="department-item" key={d.id}>
          {d.name}
        </div>
      ))}
    </div>
  )
}

/**
 * Component: AmenitiesList
 */
type AmenitiesListProps = {
  amenities: AmenityResponse[]
}

export const AmenitiesList = ({ amenities }: AmenitiesListProps) => {
  const amenityNames = amenities.map((a) => a.name)

  return <div className="amenities-list">{amenityNames.join(", ")}</div>
}

export default SpotList
