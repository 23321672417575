import { ParseKeys, TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { labsURL } from "../api"
import i18n from "../i18n"

import { api } from "../redux/api"
import { useFetchProgramsQuery } from "../redux/api/labs"
import { fetchFeatureFlags } from "../redux/feature_flags/featureFlagsSlice"
import { useActions } from "../redux/utils"

import { AsyncSwitch } from "../components/advanced/AsyncToggle"
import Skeleton from "../components/advanced/Skeleton"
import Breadcrumbs from "../components/Breadcrumbs"
import Intro from "../components/Intro"
import Space from "../components/Space"
import View from "../components/View"

import "./Labs.sass"

const LabsSkeleton = (programsHead: JSX.Element) => (
  <div className="programs-skeleton">
    <div className="programs">
      {programsHead}
      {[...Array(5)].map((_, index) => (
        <div key={index} className="program-item-skeleton">
          <div className="program-item-skeleton-line">
            <Skeleton className="skeleton-column" count={2} />
          </div>
        </div>
      ))}
    </div>
  </div>
)

const Labs = () => {
  const { t } = useTranslation()

  const { data: { results: programs = [], count = 0 } = {}, isLoading } =
    useFetchProgramsQuery()

  const dispatch = useDispatch()

  const actions = useActions({
    fetchFeatureFlags: () => fetchFeatureFlags(),
  })

  const programsHead = (
    <>
      <div className="head">
        <div className="program-name">{t("desktop.settings.labs.name")}</div>
        <div className="program-description">
          {t("desktop.settings.labs.description")}
        </div>
      </div>
    </>
  )

  return (
    <View className="Labs">
      <Breadcrumbs depth={1} values={[t("desktop.settings.labs.title")]} />
      <Intro isConstrained>{t("desktop.settings.labs.intro")}</Intro>

      <Space size={0.75} />

      {isLoading && LabsSkeleton(programsHead)}

      {!isLoading && count === 0 && (
        <div className="no-programs">
          {t("desktop.settings.labs.no_programs")}
        </div>
      )}

      {!isLoading && count > 0 && (
        <div className="programs">
          {programsHead}
          <div>
            {programs.map((program) => (
              <div key={program.name} className="program-item">
                <div className="program-name">
                  <AsyncSwitch
                    updateMode={false}
                    className="program-switch"
                    value={program.enrolled ?? false}
                    urlGenerator={() => {
                      return labsURL()
                    }}
                    bodyGenerator={(value) => ({
                      name: program.name,
                      enrolled: value,
                      message: program.message,
                    })}
                    label={
                      i18n.exists(
                        `desktop.settings.labs.available_programs.${program.name}` as ParseKeys,
                      )
                        ? t(
                            `desktop.settings.labs.available_programs.${program.name}` as ParseKeys,
                          )
                        : program.name
                    }
                    analyticsGenerator={() => {
                      dispatch(api.util.invalidateTags(["Labs"]))
                    }}
                    successMessage={
                      !program.enrolled
                        ? t("desktop.settings.labs.enrolled_toast")
                        : t("desktop.settings.labs.unenrolled_toast")
                    }
                    refresh={() => actions.fetchFeatureFlags()}
                  ></AsyncSwitch>
                </div>
                <div className="program-description">{program.message}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </View>
  )
}

export default Labs
