import { Dayjs } from "dayjs"

export const getDateRange = (fromDate: Dayjs, toDate: Dayjs): Dayjs[] => {
  const dates: Dayjs[] = []
  let currentDate = fromDate.clone()

  while (
    currentDate.isBefore(toDate, "day") ||
    currentDate.isSame(toDate, "day")
  ) {
    dates.push(currentDate)
    currentDate = currentDate.add(1, "day")
  }

  return dates
}
