import React, { PropsWithChildren, useEffect, useMemo, useState } from "react"

import { Redirect, useHistory, useLocation } from "react-router-dom"

import { logBreadcrumb } from "../../analytics"
import { Device } from "@capacitor/device"
import { captureMessage } from "@sentry/capacitor"

import { appError } from "../../redux/app/appSlice"
import { authenticateTablet } from "../../redux/auth/authSlice"
import { useAppSelector } from "../../redux/reducers"
import { selectIsAuth } from "../../redux/selectors"
import { selectTablet } from "../../redux/tablet/selectors"
import { setTabletParams, TabletParams } from "../../redux/tablet/tabletSlice"
import { AuthenticateTabletRequest } from "../../redux/tablet/types"
import { useActions } from "../../redux/utils"

import Loader from "../../components/basic/Loader"

type Params = {
  building_id: string | null
  tablet_id: string | null
  touchless_pin: string | null
  invite_id: string | null
}

const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { building_id, tablet_id, touchless_pin, invite_id } =
    useMemo<Params>(() => {
      const query = new URLSearchParams(search)

      let buildingId = localStorage.getItem("visitor_param_building_id")
      let tabletId = localStorage.getItem("visitor_param_tablet_id")
      let touchlessPin = localStorage.getItem("visitor_param_touchless_pin")
      let inviteId = localStorage.getItem("visitor_param_invite_id")

      if (query.has("building_id")) {
        buildingId = query.get("building_id")
        localStorage.setItem("visitor_param_building_id", buildingId!)
      }

      if (query.has("tablet_id")) {
        tabletId = query.get("tablet_id")
        localStorage.setItem("visitor_param_tablet_id", tabletId!)
      }

      if (query.has("touchless_pin")) {
        touchlessPin = query.get("touchless_pin")
        localStorage.setItem("visitor_param_touchless_pin", touchlessPin!)

        localStorage.removeItem("visitor_param_invite_id")
        inviteId = null
      }

      if (query.has("invite_id")) {
        inviteId = query.get("invite_id")
        localStorage.setItem("visitor_param_invite_id", inviteId!)

        localStorage.removeItem("visitor_param_touchless_pin")
        touchlessPin = null
      }

      return {
        building_id: buildingId,
        tablet_id: tabletId,
        touchless_pin: touchlessPin,
        invite_id: inviteId,
      }
    }, [search])

  const isAuthPath = pathname.indexOf("/visitors/onboarding") > -1

  const [isLoading, setIsLoading] = useState(true)

  const isAuth = useAppSelector(selectIsAuth)
  const { pin, id, touchlessPin, inviteId } = useAppSelector(selectTablet)

  const actions = useActions({
    appError: (message: string) => appError(message),
    authenticateTablet: (body: AuthenticateTabletRequest) =>
      authenticateTablet(body),
    setTabletParams: (body: TabletParams) => setTabletParams(body),
  })

  useEffect(() => {
    if (!isAuth && pin && id) {
      Device.getId().then((deviceId) => {
        actions
          .authenticateTablet({
            device_id: deviceId.identifier,
            pin,
          })
          .catch((e) => {
            actions.appError(e.message)
          })
          .finally(() => {
            setIsLoading(false)
          })
      })
    } else {
      if (!building_id && !tablet_id) {
        setIsLoading(false)
      }
    }
  }, [actions, isAuth, pin, id, building_id, tablet_id])

  useEffect(() => {
    if (building_id && tablet_id && (touchless_pin || invite_id)) {
      logBreadcrumb(
        `Tablet params: tablet: ${tablet_id}, building: ${building_id}, touchless_pin: ${touchless_pin}, invite_id: ${invite_id}`,
      )
      captureMessage("Tablet parameters set")
      actions
        .setTabletParams({
          id: tablet_id,
          buildingId: building_id,
          touchlessPin: touchless_pin,
          inviteId: invite_id,
        })
        .finally(() => {
          setIsLoading(false)
          history.push("/visitors/name")
        })
    }
  }, [history, actions, building_id, tablet_id, touchless_pin, invite_id])

  if (isLoading) {
    return <Loader size="large" />
  }

  if (!isAuth && !touchlessPin && !inviteId && !isAuthPath) {
    return <Redirect to={"/visitors/onboarding"} />
  }

  return <>{children}</>
}

export default AuthProvider
