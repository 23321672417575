import { PaginatedResponse } from "../../types"

export enum CalendarType {
  ICALENDAR = "icalendar",
  EXCHANGE = "exchange",
  GOOGLE = "google",
  OFFICE365 = "office365",
}

export type UnpairCalendarRequest = {
  id: string
}

export type CalendarRequest = {
  type: CalendarType.OFFICE365 | CalendarType.GOOGLE
}

export type iCalendarRequest = {
  type: CalendarType.ICALENDAR
  calendar_id?: string
  user?: string
  password?: string
  password_repeat?: string
}

export type ExchangeRequest = {
  type: CalendarType.EXCHANGE
  url: string
  user: string
  password: string
  password_repeat: string
}

export type Calendar = {
  id: string
  type: CalendarType
}

export type iCalendarResponse = Calendar & {
  user?: string
}

export type ExchangeResponse = Calendar & {
  url: string
  user: string
}

export type GoogleOffice365Response = Calendar & {
  url: string
}

export type GoogleCalendar = {
  id: string
  type: CalendarType.GOOGLE
  user: string
  domain: string
}

export type ExchangeCalendar = {
  id: string
  type: CalendarType.EXCHANGE
  user: string
  url: string
}

export type iCalendar = {
  id: string
  type: CalendarType.ICALENDAR
  user: string
}

export type Office365Calendar = {
  id: string
  type: CalendarType.OFFICE365
  user: string
}

export type AvailableCalendars =
  | GoogleCalendar
  | ExchangeCalendar
  | iCalendar
  | Office365Calendar

export type CalendarsResponse = PaginatedResponse<AvailableCalendars>
