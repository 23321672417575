import { useEffect } from "react"

import { fetchPrinterSettings } from "../../../redux/printer_settings/printerSettingsSlice"
import { selectPrinterSettings } from "../../../redux/printer_settings/selectors"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"

import Skeleton from "../../../components/advanced/Skeleton"
import PrinterSettingsForm from "../../../components/Form/Visitors/PrinterSettingsForm"
import View from "../../../components/View"

import "./PrinterSettings.sass"

const PrinterSettingsSkeleton = () => {
  const pattern = [
    {
      width: "30%",
    },
    {
      width: "100%",
      space: 1,
    },
    {
      width: "20%",
    },
    {
      width: "100%",
      space: 0,
    },
  ]
  const lines = new Array(6).fill(pattern).flat()

  return <Skeleton skeletonGroup={{ lines }} />
}

const PrinterSettings = () => {
  const { entries: printerSettings, isLoaded } = useAppSelector(
    selectPrinterSettings,
  )

  const actions = useActions({
    fetchPrinterSettings: () => fetchPrinterSettings(),
  })

  useEffect(() => {
    actions.fetchPrinterSettings()
  }, [actions])

  return (
    <View className="PrinterSettings">
      {!isLoaded && <PrinterSettingsSkeleton />}
      {isLoaded && printerSettings && <PrinterSettingsForm />}
    </View>
  )
}

export default PrinterSettings
