import React from "react"

import { useTranslation } from "react-i18next"

import GeneralForm from "./GeneralForm"

import { useFetchMeQuery } from "../../../../redux/api/me"

import Skeleton from "../../../../components/advanced/Skeleton"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./styles.sass"

const GeneralSkeleton = () => {
  const pattern = [
    {
      width: "30%",
      space: 0.3,
    },
    {
      width: "100%",
      space: 1,
    },
  ]

  const lines = new Array(8).fill(pattern).flat()

  return (
    <div className="general-skeleton">
      <div className="user-picture-skeleton">
        <Skeleton width={160} height={160} className="picture" />
        <div className="labels">
          <Skeleton width={"50%"} />
          <Space size={0.5} />
          <Skeleton width={"100%"} count={3} />
        </div>
      </div>
      <Skeleton skeletonGroup={{ lines }} />
    </div>
  )
}

const General = () => {
  const { t } = useTranslation()

  const { data: user, isLoading } = useFetchMeQuery()

  return (
    <View className="General">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.profile.general.account"),
          t("desktop.settings.profile.general.title"),
        ]}
      />

      <Intro isConstrained>{t("desktop.settings.profile.general.intro")}</Intro>

      <Space size={0.75} />

      {isLoading ? <GeneralSkeleton /> : <GeneralForm user={user} />}
    </View>
  )
}

export default General
