import React from "react"

import cn from "classnames"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { useNavigation } from "../../../../../hooks/useNavigation"
import { ISODate } from "../../../../../types/sharedTypes"
import { userTimeFormat } from "../../../../../utils"
import { ROOM_BOOKING_PATHS } from "../../constants"

import "./ReservationItem.sass"

type Props = {
  id: string
  title: string
  startTime: ISODate
  endTime: ISODate
  isReadOnly: boolean
  isPrivate: boolean
}

const ReservationItem = ({
  id,
  title,
  startTime,
  endTime,
  isPrivate,
  isReadOnly,
}: Props) => {
  const { push } = useNavigation()
  const { t } = useTranslation()

  const isOverdue = dayjs(endTime).isBefore(dayjs())
  const isDisabled = isPrivate || isReadOnly

  const formattedTitle = isPrivate
    ? t("desktop.manage.room_booking.calendar.reservation_info.private")
    : title || t("desktop.manage.room_booking.calendar.no_title")

  const handleReservationClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (isDisabled) return

    push(ROOM_BOOKING_PATHS.edit.replace(":id", id))
  }

  return (
    <div
      className={cn("reservation-item", {
        "reservation-item--disabled": isDisabled,
        "reservation-item--overdue": isOverdue,
      })}
      onClick={handleReservationClick}
    >
      <div className="title">{formattedTitle}</div>
      <div className="range-time">
        {dayjs(startTime).format(userTimeFormat())} –{" "}
        {dayjs(endTime).format(userTimeFormat())}
      </div>
    </div>
  )
}

export default ReservationItem
