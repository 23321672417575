import { useCallback, useMemo } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { FETCH_WITH_NO_LIMIT } from "../../constants"
import { useToast } from "../../hooks/useToast"
import { OptionType } from "../../types/sharedTypes"
import Loader from "../basic/Loader"
import { Select } from "../basic/Select"
import Field from "../Field"
import { setErrors } from "./formUtils"
import ModalForm from "./ModalFormHook"
import { useModals } from "@mattjennings/react-modal-stack"

import {
  useFetchRoomsRbQuery,
  useUpdateRoomRbMutation,
} from "../../redux/api/roomBookingRooms"
import {
  RbRoomResponse,
  RoomRequestWithId,
} from "../../redux/api/roomBookingRooms/types"
import { isApiResponseError, isRejected } from "../../redux/api/types"
import { FloorResponse } from "../../redux/floors/types"

import "./RoomForm.sass"

type Props = {
  floor: FloorResponse | null
  room?: RoomRequestWithId | null
  roomsToEdit?: number[]
  isEdit?: boolean
}

type FormValues = {
  room_id: OptionType<number>
}

const generateRoomOptions = (rooms: RbRoomResponse[]) => {
  return rooms.map((room) => ({
    value: room.id,
    label: room.name,
  }))
}

const RoomForm = ({ floor, room, roomsToEdit, isEdit }: Props) => {
  const { closeModal } = useModals()
  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()

  const [updateRoom] = useUpdateRoomRbMutation()

  const multiple = roomsToEdit && roomsToEdit.length > 1

  const handleRemoveRoom = useCallback(async () => {
    if (multiple) {
      let roomUpdatedCount = 0

      const promises = roomsToEdit.map((roomId) =>
        updateRoom({ id: roomId, coords: null }),
      )

      const responses = await Promise.all(promises)

      responses.forEach((response) => {
        if (isRejected(response)) {
          errorToast(response.error.message)
        } else {
          roomUpdatedCount++
        }
      })

      infoToast(
        t("desktop.settings.rooms.layout.toasts.removed_rooms_toast", {
          count: roomUpdatedCount,
          selectedRoomsCount: roomsToEdit.length,
        }),
      )
    } else {
      const roomId = roomsToEdit?.[0]
      if (!roomId) {
        return
      }

      const response = await updateRoom({ id: roomId, coords: null })

      if (isRejected(response)) {
        errorToast(response.error.message)
        return
      }

      infoToast(t("desktop.settings.rooms.layout.toasts.removed_room_toast"))
    }
  }, [roomsToEdit, multiple, updateRoom, t])

  const {
    data: { results: unfilteredRooms = [] } = {},
    isLoading: areRoomsLoading,
  } = useFetchRoomsRbQuery({
    limit: FETCH_WITH_NO_LIMIT,
    floor: floor?.id,
  })

  const rooms = useMemo(
    () => unfilteredRooms.filter((room) => !room.coords),
    [unfilteredRooms],
  )

  const ROOM_OPTIONS = generateRoomOptions(rooms)

  const methods = useForm<FormValues>()

  const { setError, control } = methods

  const onUpdateClick = useCallback(
    async ({ room_id }: FormValues) => {
      if (!isEdit) {
        if (!floor) {
          return
        }

        const response = await updateRoom({
          id: room_id?.value,
          floor_id: floor.id,
          ...room,
        })

        if (!isRejected(response)) {
          infoToast(t("desktop.settings.rooms.layout.form.toasts.room_added"))
          closeModal()
        } else {
          if (isApiResponseError(response.error)) {
            setErrors(response.error.formError, setError, errorToast)
          }
        }
      } else {
        if (!roomsToEdit || !roomsToEdit.length) {
          return
        }

        const currentRoom = unfilteredRooms.find((r) => r.id == roomsToEdit[0])

        if (!currentRoom) {
          return
        }

        const nullifyOldRoomCoordsResponse = updateRoom({
          id: currentRoom.id,
          coords: null,
        })

        const updateNewRoomCoordsResponse = updateRoom({
          id: room_id.value,
          coords: currentRoom.coords,
        })

        const promises = await Promise.all([
          nullifyOldRoomCoordsResponse,
          updateNewRoomCoordsResponse,
        ])

        let requestFailed = false

        promises.forEach((promise) => {
          if (isRejected(promise)) {
            errorToast(promise.error.message)
            requestFailed = true
          }
        })

        if (requestFailed) {
          return
        }

        infoToast(t("desktop.settings.rooms.layout.form.toasts.room_updated"))
        closeModal()
      }
    },
    [floor, infoToast, t, setError, errorToast, closeModal],
  )

  const title = isEdit
    ? t("desktop.settings.rooms.layout.form.edit_title", {
        count: roomsToEdit?.length || 1,
      })
    : t("desktop.settings.rooms.layout.form.title")

  if (areRoomsLoading) {
    return <Loader />
  }

  return (
    <FormProvider {...methods}>
      <ModalForm
        deleteConfirmationPrompt={t(
          "desktop.settings.rooms.layout.form.delete_confirmation_prompt",
          {
            count: roomsToEdit?.length || 1,
          },
        )}
        deleteConfirmationHint={t(
          "desktop.settings.rooms.layout.form.delete_confirmation_hint",
          {
            count: roomsToEdit?.length || 1,
          },
        )}
        onDelete={isEdit ? handleRemoveRoom : undefined}
        className="RoomForm"
        updateMode={true}
        title={title}
        onUpdate={!multiple ? onUpdateClick : undefined}
      >
        {!multiple && (
          <Field
            control={control}
            name="room_id"
            label={t("desktop.settings.rooms.layout.form.fields.room.label")}
            rules={{
              required: t("desktop.settings.rooms.layout.form.fields.required"),
            }}
          >
            {({ ...props }) => (
              <Select
                {...props}
                options={ROOM_OPTIONS}
                clearable
                placeholder={
                  rooms.length
                    ? t(
                        "desktop.settings.rooms.layout.form.fields.room.placeholder",
                      )
                    : t(
                        "desktop.settings.rooms.layout.form.fields.room.no_rooms",
                      )
                }
              />
            )}
          </Field>
        )}
      </ModalForm>
    </FormProvider>
  )
}

export default RoomForm
