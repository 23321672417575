import React from "react"

import { useTranslation } from "react-i18next"

import { FEATURE_FLAGS } from "../../../../constants"
import { useCheckForFeatureFlag } from "../../../../hooks/useCheckForFeatureFlag"
import BillingSection from "../BillingSection"
import LicenseCodeCard from "../PrepaidCode/PrepaidCodeCard"
import PaymentDetails from "./PaymentDetails"

import "./styles.sass"

const Payments = () => {
  const { t } = useTranslation()

  // Don't show the license code card if the new business model feature flag is enabled
  const isNewBusinessModel = useCheckForFeatureFlag(
    FEATURE_FLAGS.NEW_BUSINESS_MODEL,
  )

  return (
    <BillingSection
      title={t("desktop.settings.billing.payments.title")}
      intro={t("desktop.settings.billing.payments.intro")}
      className="Payments"
    >
      <PaymentDetails />
      {!isNewBusinessModel && <LicenseCodeCard />}
    </BillingSection>
  )
}

export default Payments
