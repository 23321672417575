import {
  DeskWithReservations,
  RoomWithReservations,
  SpotType,
  SpotTypes,
} from "./types"

import { Reservation } from "../../../redux/api/roomReservations/types"
import { DeskScheduleReservation } from "../../../redux/desk_schedule/types"
import { ScheduleReservation } from "../../../redux/types"

import { FilterSpecialValues } from "../../../components/Filter/types"

export const getUserInfo = (
  reservation: DeskScheduleReservation | Reservation | ScheduleReservation,
) => {
  if (!reservation) return

  const possibleUsers = [
    "user" in reservation ? reservation.user : undefined,
    "organizer" in reservation ? reservation.organizer?.user : undefined,
    "organizer" in reservation ? reservation.organizer?.external : undefined,
    "creator" in reservation ? reservation.creator?.user : undefined,
    "creator" in reservation ? reservation.creator?.external : undefined,
  ]

  return possibleUsers.find(Boolean)
}

export const getSpotVisibility = (
  type: SpotType | FilterSpecialValues.ALL,
  isRoomBookingEnabledFlag: boolean,
) => {
  const isDesksSelected = type === SpotTypes.Desk

  const isRoomsSelected = type === SpotTypes.Room

  const isAllSelected = type === FilterSpecialValues.ALL

  return { isDesksSelected, isRoomsSelected, isAllSelected }
}

export const getCoordinates = (
  spot: DeskWithReservations | RoomWithReservations,
) => {
  if (spot.type === SpotTypes.Desk) {
    return { x: spot?.coord_x ?? 0, y: spot?.coord_y ?? 0 }
  }

  if (spot.type === SpotTypes.Room) {
    return { x: spot?.coords?.[0].x ?? 0, y: spot?.coords?.[0].y ?? 0 }
  }

  return { x: 0, y: 0 }
}

export const getDepartments = (
  spot: DeskWithReservations | RoomWithReservations,
) => {
  return spot.type === SpotTypes.Desk ? spot.departments : []
}

export const getAmenities = (
  spot: DeskWithReservations | RoomWithReservations,
) => {
  if (spot.type === SpotTypes.Desk) return spot.amenities

  if (spot.type === SpotTypes.Room) {
    return spot.amenities.map(({ id, name }) => ({ id: id.toString(), name }))
  }

  return []
}

export const getCapacity = (
  spot: DeskWithReservations | RoomWithReservations,
) => {
  return spot.type === SpotTypes.Room ? spot.capacity : undefined
}

export const normalizeSpotId = (spotId: string | number) =>
  typeof spotId === "number" ? spotId.toString() : spotId
