import { Redirect, useLocation } from "react-router-dom"

import { FEATURE_FLAGS } from "../constants"
import { useCheckForFeatureFlag } from "../hooks/useCheckForFeatureFlag"
import { useNavigation } from "../hooks/useNavigation"
import { BILLING_PATHS } from "../screens/Settings/Billing/constants"
import { Children } from "../types/sharedTypes"

import { useFetchPaymentsQuery } from "../redux/api/billing/payments"

const CompanyDetailsGuard = ({ children }: { children: Children }) => {
  const location = useLocation()
  const path = location.pathname
  const { getNextFromCurrentUrl, createLocationString } = useNavigation()
  const { data: payments, isFetching } = useFetchPaymentsQuery()

  const isNewBusinessModel = useCheckForFeatureFlag(
    FEATURE_FLAGS.NEW_BUSINESS_MODEL,
  )

  // Check if the current path is old billing plan path
  const isBillingPlanPath = path.match(
    /^\/settings\/billing\/(room|desk|visitor|sign)(\/.*)?$/,
  )

  // If the new business model feature flag is enabled and we are trying to access old billing plan paths, redirect to overview
  if (isNewBusinessModel && isBillingPlanPath) {
    return <Redirect to={BILLING_PATHS.overview.root} />
  }

  // If billing details are missing, redirect to company details form
  if (!payments?.billing_details && !isFetching) {
    const next = getNextFromCurrentUrl()
    const redirectPath = createLocationString(
      BILLING_PATHS.overview.companyDetails,
      { next },
    )
    return <Redirect to={redirectPath} />
  }

  return children
}

export default CompanyDetailsGuard
