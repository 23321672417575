import React from "react"

import { useTranslation } from "react-i18next"

import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

import { UserGroup } from "../../redux/user/types"

type Props = {
  value: string
  onChange: (v: string) => void
}

export default function RoleFilter({ value, onChange }: Props) {
  const { t } = useTranslation()

  const roleOptions = [
    { label: t("mobile.general.all_roles"), value: FilterSpecialValues.ALL },
    { label: t("general.users"), value: UserGroup.USER },
    { label: t("general.office_managers"), value: UserGroup.OFFICE_MANAGER },
    { label: t("general.admins"), value: UserGroup.ADMIN },
  ]

  return (
    <Dropdown
      className="role-filter"
      options={roleOptions}
      value={value}
      onChange={onChange}
    />
  )
}
