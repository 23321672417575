import { useCallback, useEffect } from "react"

import { isNative } from "../../utils"
import { useRefresh } from "./useRefresh"
import { BackgroundRunner } from "@joan/capacitor-background-runner"

import { useFetchBuildingQuery } from "../../redux/api/buildings"
import { useAppSelector } from "../../redux/reducers"
import { selectSettingsEffective } from "../../redux/settings/selectors"
import { selectUser } from "../../redux/user/selectors"

/**
 * This hook sends all the required data to the background process
 * to enable automatic desk check-ins.
 */

export const useBackgroundCheckin = () => {
  const { entry: user, isLoaded: isUserLoaded } = useAppSelector(selectUser)
  const { entry: settings } = useAppSelector(selectSettingsEffective)
  const accessToken = useAppSelector((state) => state.auth.access_token)

  const buildingId = user?.building?.id ?? ""
  const { data: building } = useFetchBuildingQuery(
    { id: buildingId },
    { skip: !buildingId },
  )

  const sendDataForCheckin = useCallback(async () => {
    const permissions = await BackgroundRunner.checkPermissions()

    if (
      !isNative() ||
      !accessToken ||
      !building?.latitude ||
      !building?.longitude ||
      permissions.geolocation !== "alwaysOn" ||
      settings?.desk_check_in_location === "on_spot"
    ) {
      return
    }

    try {
      await BackgroundRunner.dispatchEvent({
        label: "com.visionect.joan.desk.background",
        event: "storeDataForCheckin",
        details: {
          authToken: accessToken,
          locationPermissions: permissions.geolocation,
          deskCheckInLocation: settings?.desk_check_in_location,
          buildingLat: building.latitude,
          buildingLng: building.longitude,
        },
      })
    } catch (error) {
      console.log(`Error in sendDataForCheckin: ${error}`)
    }
  }, [accessToken, settings, building])

  // Sends data for automatic check-in on mount and when user and building data become available
  useEffect(() => {
    if (isUserLoaded && building) {
      sendDataForCheckin()
    }
  }, [isUserLoaded, building, sendDataForCheckin])

  // Re-sends data for automatic check-in when app comes back to foreground
  useRefresh(async () => {
    if (isUserLoaded && building) {
      sendDataForCheckin()
    }
  })
}
