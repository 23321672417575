import React, { useMemo } from "react"

import { useTranslation } from "react-i18next"

import { FEATURE_FLAGS } from "../../constants"
import { useCheckForFeatureFlag } from "../../hooks/useCheckForFeatureFlag"
import { SpotType, SpotTypes } from "../../screens/Manage/FloorPlan/types"
import { getSpotVisibility } from "../../screens/Manage/FloorPlan/utils"
import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

type Props = {
  value: string
  onChange: (v: string) => void
}

const ResourceTypeFilter = ({ value, onChange }: Props) => {
  const isRoomBookingEnabledFlag = useCheckForFeatureFlag(
    FEATURE_FLAGS.ROOM_BOOKING,
  )

  const { t } = useTranslation()

  const options = [
    {
      label: t("desktop.manage.floor_plan.filters.spot_types.all"),
      value: FilterSpecialValues.ALL,
    },
    {
      label: t("desktop.manage.floor_plan.filters.spot_types.desks"),
      value: SpotTypes.Desk,
    },
    {
      label: t("desktop.manage.floor_plan.filters.spot_types.rooms"),
      value: SpotTypes.Room,
    },
  ]

  if (!isRoomBookingEnabledFlag) {
    return
  }

  return (
    <Dropdown
      className="spot-type-filter"
      options={options}
      value={value}
      onChange={onChange}
    />
  )
}

export default ResourceTypeFilter
