import React from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { useCurrencyFormatter } from "../../../../hooks/useCurrencyFormatter"

import { Currency } from "../../../../redux/api/billing/types"

import Button from "../../../../components/advanced/Button"
import Card from "../../../../components/basic/Card"

import "./PricingCard.sass"

export type PricingCardProps = {
  title: string
  price: number
  currency: Currency
  period: string
  description: string
  forText: string
  userCount: number
  deviceCount: number
  onActionClick: () => void
  actionText: string
  className?: string
  isActive?: boolean
  isDisabled?: boolean
}

/**
 * This is the pricing card component for the new business model. (Old business model uses SubscriptionCard component -- src/components/advanced/SubscriptionCard/index.tsx)
 * It is currently under feature flag since the new business model is an experiment at the moment.
 */

const PricingCard = ({
  title,
  price,
  currency,
  period,
  description,
  forText,
  userCount,
  deviceCount,
  onActionClick,
  actionText,
  className,
  isActive = false,
  isDisabled = false,
}: PricingCardProps) => {
  const { t } = useTranslation()
  const currencyFormatter = useCurrencyFormatter(currency, "auto", undefined, 0)

  const cn = classNames("PricingCard", className, {
    "PricingCard--active": isActive,
    "PricingCard--disabled": isDisabled && !isActive,
  })

  return (
    <Card className={cn}>
      <div className="PricingCard__header">
        <h3 className="PricingCard__title">{title}</h3>
        {isActive && (
          <div className="PricingCard__active-label">
            {t("desktop.components.plan_variation_card.current_plan")}
          </div>
        )}
        <div className="PricingCard__price">
          {currencyFormatter.format(price)}
        </div>
        <div className="PricingCard__period">
          {t("desktop.settings.billing.plans.pricing_card.period.monthly")}
        </div>
      </div>

      <div className="PricingCard__description">{description}</div>

      <div className="PricingCard__action">
        <Button
          variant="primary"
          onClick={onActionClick}
          className="PricingCard__button"
          isDisabled={isActive || isDisabled}
        >
          {actionText}
        </Button>
      </div>

      <div className="PricingCard__features">
        <div className="PricingCard__feature">
          {t("desktop.settings.billing.plans.pricing_card.users_included", {
            count: userCount,
          })}
        </div>
        <div className="PricingCard__feature">
          {t("desktop.settings.billing.plans.pricing_card.devices_included", {
            count: deviceCount,
          })}
        </div>
      </div>
    </Card>
  )
}

export default PricingCard
