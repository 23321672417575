import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import { companySettingsURL } from "../../api/urls"
import { AsyncCheckbox } from "../advanced/AsyncCheckbox"
import { RedirectLink } from "../advanced/RedirectLink"
import { UncontrolledField } from "../Field"
import PageForm from "./PageFormHook"

import { useAppSelector } from "../../redux/reducers"
import { selectSettingsEffective } from "../../redux/settings/selectors"
import { fetchSettings } from "../../redux/settings/settingsSlice"
import { useActions } from "../../redux/utils"

import "./RoomsForm.sass"

type FormValues = {
  enabled: boolean
}

function RoomsForm() {
  const { t } = useTranslation()

  const actions = useActions({
    fetchSettings: () => fetchSettings(),
  })

  const { entry: companySettings } = useAppSelector(selectSettingsEffective)
  const isInAppRoomBookingEnabled = companySettings?.room_in_app_booking_enabled

  const methods = useForm<FormValues>()

  return (
    <FormProvider {...methods}>
      <PageForm className="RoomsForm" updateMode={true}>
        <UncontrolledField>
          <AsyncCheckbox
            updateMode={true}
            value={isInAppRoomBookingEnabled}
            label={t("desktop.settings.rooms.form.in_app_room_booking")}
            urlGenerator={() => companySettingsURL()}
            bodyGenerator={(value: boolean) => ({
              settings: {
                room_in_app_booking_enabled: value,
              },
            })}
            refresh={actions.fetchSettings}
            isSecondary
            successToastMessage={t(
              "desktop.settings.rooms.form.room_booking_settings_updated_toast",
            )}
            description={
              <Trans
                i18nKey="desktop.settings.rooms.form.in_app_room_booking_description"
                components={{
                  a1: (
                    <RedirectLink to="book-a-meeting-room-in-the-mobile-app">
                      this article
                    </RedirectLink>
                  ),
                  a2: (
                    <RedirectLink to="android-app-link">Android</RedirectLink>
                  ),
                  a3: <RedirectLink to="ios-app-link">iOS</RedirectLink>,
                }}
              />
            }
          />
        </UncontrolledField>
      </PageForm>
    </FormProvider>
  )
}

export default RoomsForm
