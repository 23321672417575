import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import classNames from "classnames"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"

import { useLongPress } from "../hooks/useLongPress"
import { SpotType, SpotTypes } from "../screens/Manage/FloorPlan/types"
import { IdAndName, ScheduleReservation } from "../types/sharedTypes"
import PlaceTooltip from "./PlaceTooltip"
import RoomSpotTooltip from "./RoomSpotTooltip"

import { DepartmentResponse } from "../redux/api/departments/types"
import { Reservation } from "../redux/api/roomReservations/types"
import { selectIsMobile } from "../redux/app/selectors"
import { useAppSelector } from "../redux/reducers"

import RoomSVG from "../assets/images/icons/Room.svg"

import "./Place.sass"

type Props = {
  id?: string
  x: number
  y: number
  title: string
  amenities?: IdAndName[]
  departments?: DepartmentResponse[]
  capacity?: number | null
  reservations?: ScheduleReservation[] | Reservation[]
  mapWidth: number
  mapHeight: number
  scale?: number
  onClick?: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  onToggleReposition?: () => void
  onDownDragPlace?: (id: string | number | undefined) => void
  onStopDragPlace?: (e: DraggableEvent, data: DraggableData) => void
  isInactive?: boolean
  isUnmarked?: boolean
  isBlinking?: boolean
  isAvailable?: boolean
  isOccupied?: boolean
  isDisabled?: boolean
  isSelected?: boolean
  isFocused?: boolean
  isCurrentlyBooked?: boolean
  isHovered?: boolean
  isHighlighted?: boolean
  isTooltipClickable?: boolean
  type?: SpotType
}

export default function Place({
  id,
  x,
  y,
  title,
  amenities,
  departments,
  capacity,
  reservations,
  mapWidth,
  mapHeight,
  scale = 1,
  onClick,
  onMouseOver,
  onMouseOut,
  onToggleReposition,
  onDownDragPlace,
  onStopDragPlace,
  isInactive = false,
  isUnmarked = false,
  isBlinking = false,
  isAvailable = false,
  isOccupied = false,
  isDisabled = false,
  isSelected = false,
  isFocused = false,
  isCurrentlyBooked = false,
  isHovered = false,
  isHighlighted = false,
  isTooltipClickable = false,
  type = SpotTypes.Desk,
}: Props) {
  const isMobile = useAppSelector(selectIsMobile)

  const isDesk = type === SpotTypes.Desk
  const isRoom = type === SpotTypes.Room

  const placeRef = useRef<HTMLDivElement>(null)

  const { reposition_desk } = useAppSelector((state) => state.app)

  const isRepositionDesk = reposition_desk && reposition_desk.id

  const isDisabledDrag =
    !isSelected || !isRepositionDesk || id !== reposition_desk.id

  const [isOpen, setIsOpen] = useState(false)

  const currentPosition = useMemo(
    () => ({
      x: x / mapWidth,
      y: y / mapHeight,
    }),
    [x, y, mapWidth, mapHeight],
  )

  const placeStyle = useMemo(
    () => ({
      top: `${(y / mapHeight) * 100}%`,
      left: `${(x / mapWidth) * 100}%`,
    }),
    [x, y, mapHeight, mapWidth],
  )
  const uniqueId = `${x}${y}`

  const handleMouseClick = useCallback(() => {
    if (isDisabled) {
      return undefined
    }

    onClick?.()
  }, [onClick, isDisabled])

  const handleLongMouseClick = useCallback(() => {
    if (isDisabled) {
      return undefined
    }

    if (!isSelected) {
      onClick?.()
    }

    onToggleReposition?.()
  }, [isDisabled, isSelected, onToggleReposition, onClick])

  const longPressEvent = useLongPress(
    {
      onLongPress: handleLongMouseClick,
      onClick: handleMouseClick,
    },
    {
      delay: 175,
      shouldPreventDefault: true,
    },
  )

  useEffect(() => {
    const tooltipEl = document.getElementById(uniqueId)
    const tooltipContent = tooltipEl?.nextElementSibling

    if (!tooltipEl || !tooltipContent) return

    const handleMouseEnter = () => setIsOpen(true)

    const handleMouseLeave = () => {
      setTimeout(() => {
        if (!tooltipEl.matches(":hover") && !tooltipContent.matches(":hover")) {
          setIsOpen(false)
        }
      }, 300)
    }

    tooltipEl.addEventListener("mouseenter", handleMouseEnter)
    tooltipEl.addEventListener("mouseleave", handleMouseLeave)
    tooltipContent.addEventListener("mouseenter", handleMouseEnter)
    tooltipContent.addEventListener("mouseleave", handleMouseLeave)

    return () => {
      tooltipEl.removeEventListener("mouseenter", handleMouseEnter)
      tooltipEl.removeEventListener("mouseleave", handleMouseLeave)
      tooltipContent.removeEventListener("mouseenter", handleMouseEnter)
      tooltipContent.removeEventListener("mouseleave", handleMouseLeave)
    }
  }, [uniqueId])

  const placeClassName = classNames({
    Place: true,
    isInactive,
    isUnmarked,
    isBlinking,
    isFocused,
    isSelected,
    isAvailable,
    isOccupied,
    isDisabled,
    isCurrentlyBooked,
    isHovered,
    isHighlighted,
    isMobile,
    isRoom,
    isDesk,
  })

  const wrapperClassName = classNames("PlaceWrapper", {
    "show-tooltip": isOpen,
  })

  return (
    <Draggable
      nodeRef={placeRef}
      axis={isDisabledDrag ? "none" : "both"}
      position={currentPosition}
      scale={scale}
      onMouseDown={() => (onDownDragPlace ? onDownDragPlace(id) : undefined)}
      onStop={onStopDragPlace}
      bounds="parent"
    >
      <div ref={placeRef} className={wrapperClassName} style={placeStyle}>
        {isDesk && (
          <PlaceTooltip
            title={title}
            amenities={amenities}
            departments={departments}
            reservations={reservations as ScheduleReservation[]}
            uniqueId={uniqueId}
            isTooltipClickable={isTooltipClickable}
          >
            <span
              className={placeClassName}
              id={uniqueId}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              {...longPressEvent}
            >
              &nbsp;
            </span>
          </PlaceTooltip>
        )}

        {isRoom && (
          <RoomSpotTooltip
            title={title}
            amenities={amenities}
            reservations={reservations as Reservation[]}
            capacity={capacity}
            uniqueId={uniqueId}
            isTooltipClickable={isTooltipClickable}
          >
            <span
              className={placeClassName}
              id={uniqueId}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              {...longPressEvent}
            >
              <RoomSVG />
            </span>
          </RoomSpotTooltip>
        )}
      </div>
    </Draggable>
  )
}
