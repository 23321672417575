import React, { useState } from "react"

import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"

import { forceMode, getLabel } from "../../../utils"
import { VISITOR_ONBOARDING_BASE_URL } from "../constants"
import { Device } from "@capacitor/device"

import { appError } from "../../../redux/app/appSlice"
import { registerTablet } from "../../../redux/tablet/tabletSlice"
import {
  RegisterTabletRequest,
  TabletConnectionType,
} from "../../../redux/tablet/types"
import { useActions } from "../../../redux/utils"

import Button from "../../../components/advanced/Button"
import Loader from "../../../components/basic/Loader"
import VisitorContent from "../../../components/Visitors/VisitorContent"
import VisitorFooter from "../../../components/Visitors/VisitorFooter"
import VisitorHeader from "../../../components/Visitors/VisitorHeader"
import VisitorView from "../../../components/Visitors/VisitorView"

import "./Initial.sass"

const Initial = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)

  const actions = useActions({
    appError: (message: string) => appError(message),
    registerTablet: (body: RegisterTabletRequest) => registerTablet(body),
  })

  const handleContinue = async () => {
    setIsLoading(true)

    const deviceInfo = await Device.getInfo()
    const deviceId = await Device.getId()

    const response = await actions.registerTablet({
      device_id: deviceId.identifier,
      model: deviceInfo.model,
      version: import.meta.env.VITE_APP_VERSION,
    })

    if (registerTablet.fulfilled.match(response)) {
      const tablet = response.payload

      setIsLoading(false)

      if (tablet.connection_status === TabletConnectionType.PAIRED) {
        history.push(`${VISITOR_ONBOARDING_BASE_URL}/confirmation`)
      } else {
        history.push(`${VISITOR_ONBOARDING_BASE_URL}/pin/${tablet.pin}`)
      }
    } else {
      actions.appError(response.error.message ?? t("tablet.general_error"))
    }
  }

  const handleForceMobile = () => {
    forceMode("mobile")
    window.location.href = window.location.origin
  }

  if (isLoading) {
    return <Loader size="large" />
  }

  return (
    <VisitorView className="Initial">
      <VisitorHeader />
      <VisitorContent>
        <div className="title">
          {t("tablet.visitors.onboarding.screens.initial.manage_your")}
        </div>
        <div className="info">
          {t(
            "tablet.visitors.onboarding.screens.initial.to_continue_visit_joan",
          )}
        </div>
        <Button
          variant="link"
          href={getLabel("links.howToConfigureDesksAndAssetsURL")}
        >
          {t(
            "tablet.visitors.onboarding.screens.initial.how_to_create_account",
          )}
        </Button>
      </VisitorContent>
      <VisitorFooter>
        <Button onClick={handleContinue} isLoading={isLoading}>
          {t("tablet.visitors.continue")}
        </Button>
        <div className="app-switch">
          {t("tablet.visitors.onboarding.screens.initial.joan_app")}
          <Button
            variant="link"
            onClick={handleForceMobile}
            isDisabled={isLoading}
          >
            {t("tablet.visitors.onboarding.screens.initial.click_here")}
          </Button>
        </div>
      </VisitorFooter>
    </VisitorView>
  )
}

export default Initial
