import { useMemo } from "react"

import { useLocation } from "react-router-dom"

import { CalendarView } from "../screens/Manage/Rooms"

/**
 * Custom hook to determine if the "day view" is active in the URL search parameters.
 * It checks the "view" query parameter to see if it's set to "week".
 *
 * @returns A boolean indicating whether the "day view" is active.
 */
const useIsRoomsDayViewActive = (): boolean => {
  const location = useLocation()

  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search)

    return searchParams.get("view") !== CalendarView.Week
  }, [location.search])
}

export default useIsRoomsDayViewActive
