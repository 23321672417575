import React from "react"

import { FormProvider } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useNavigation } from "../../../../hooks/useNavigation"
import { BILLING_PATHS } from "../constants"
import CompanyDetailsFrom from "./CompanyDetailsForm"
import { STEPS } from "./constants"
import {
  CompanyDetailsFormValues,
  useCompanyDetailsForm,
} from "./useCompanyDetailsForm"

import Skeleton from "../../../../components/advanced/Skeleton"
import Stepper from "../../../../components/basic/Stepper"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import PageForm from "../../../../components/Form/PageFormHook"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./CompanyDetailsFormPage.sass"

const CompanyDetailsFormPage = () => {
  const { t } = useTranslation()
  const { pushWithNext } = useNavigation()
  const { isUpdateMode, onUpdateClick, onCreateClick, methods, isLoaded } =
    useCompanyDetailsForm()
  const {
    control,
    formState: { isSubmitting },
  } = methods

  const handleUpdateClick = async (value: CompanyDetailsFormValues) => {
    const success = await onUpdateClick(value)
    if (success) {
      pushWithNext(BILLING_PATHS.overview.paymentMethod)
    }
  }

  const handleCreateClick = async (value: CompanyDetailsFormValues) => {
    const success = await onCreateClick(value)
    if (success) {
      pushWithNext(BILLING_PATHS.overview.paymentMethod)
    }
  }

  return (
    <View className="CompanyDetailsPage">
      <Breadcrumbs
        depth={2}
        includeParamsAsPath
        values={[
          t("desktop.settings.billing.title"),
          t("desktop.settings.billing.billing_details_form.title"),
        ]}
      />

      <Space size={0.75} />

      <Stepper steps={STEPS} currentStep="details" onClick={() => {}} />

      <Space size={0.75} />

      {!isLoaded ? (
        <Skeleton width={"45rem"} height={"45rem"} />
      ) : (
        <FormProvider {...methods}>
          <PageForm
            className="CompanyDetailsPageForm"
            updateMode={isUpdateMode}
            onUpdate={handleUpdateClick}
            onCreate={handleCreateClick}
            disabled={isSubmitting}
            backUrl={BILLING_PATHS.overview.root}
            submitButtonText={t("general.next")}
          >
            <CompanyDetailsFrom control={control} isSubmitting={isSubmitting} />
          </PageForm>
        </FormProvider>
      )}
    </View>
  )
}

export default CompanyDetailsFormPage
