import dayjs from "dayjs"
import { TFunction } from "i18next"
import { Trans } from "react-i18next"

import NotificationCard, { NotificationCardProps } from ".."
import { ReservationCheckinUtils } from "../../../../checkin_utils"
import { useDeskCheckIn } from "../../../../hooks/useDeskCheckIn"
import {
  ASSETS_SCHEDULE_PATHNAME,
  DESKS_SCHEDULE_PATHNAME,
} from "../../../../screens/Manage/constants"
import { FLOOR_PLAN_SCHEDULE_PATHNAME } from "../../../../screens/Manage/FloorPlan/FloorPlanProvider"
import { DESK_PATHS } from "../../../../screens/Mobile/Book/Desks/constants"
import {
  ROOMS_DEVICES_PATHNAME,
  ROOMS_PATHNAME,
} from "../../../../screens/Settings/Rooms/constants"
import { getLabel } from "../../../../utils"
import Button from "../../../basic/Button"
import { SensitivityOptions } from "../types"
import { EVENT_ICON, EVENT_SENSITIVITY } from "./constants"
import Date from "./Content/Date"
import Location from "./Content/Location"
import Time from "./Content/Time"

import { DeskReservation } from "../../../../redux/api/deskReservations/types"
import {
  ASSET_EVENTS,
  ASSET_RESERVATION_RELEASED,
  DESK_CHECK_IN_CONFIRMED,
  DESK_CHECK_IN_REMINDER,
  DESK_EVENTS,
  DESK_RESERVATION_DELETE,
  DESK_RESERVATION_RELEASED,
  INVITE_CANCELLED_HOST,
  INVITE_CHECKED_IN_HOST,
  INVITE_CREATED_HOST,
  INVITE_UPDATED_HOST,
  Notification,
  NotificationType,
  SYSTEM_CALENDAR_OUT_OF_SYNC,
  SYSTEM_DEVICE_ANALYTICS_REPORT,
  SYSTEM_EVENTS,
  SYSTEM_PREPAID_CODE_TO_EXPIRE,
  VISITOR_EVENTS,
} from "../../../../redux/api/notifications/types"
import { useAppSelector } from "../../../../redux/reducers"
import { selectSettingsEffective } from "../../../../redux/settings/selectors"
import { Settings } from "../../../../redux/settings/types"
import { selectUser } from "../../../../redux/user/selectors"
import { MeResponse } from "../../../../redux/user/types"

import "./style.sass"

type ActionGeneratorUtility = {
  push: (pathname: string) => void
  t: TFunction<"translation", undefined>
  isMobile: boolean
}

type NotificationCardGeneratorProps = {
  notification: Notification
  utilities: ActionGeneratorUtility
  listId: string
  discardable: boolean
}

type GeneratedProps = Omit<
  NotificationCardProps,
  "listId" | "discardable"
> | null

const generateActions = (
  type: NotificationType,
  utilities: ActionGeneratorUtility,
  notification: Notification,
  handleCheckIn:
    | ((params: { reservation: DeskReservation }) => void)
    | undefined,
  settings: Settings | null,
  currentUser: MeResponse | undefined,
) => {
  const { push, t, isMobile } = utilities

  switch (type) {
    case DESK_EVENTS.RESERVATION_RELEASED: {
      const pathName = isMobile
        ? DESK_PATHS.suggest
        : FLOOR_PLAN_SCHEDULE_PATHNAME
      return (
        <Button variant="link" onClick={() => push?.(pathName)}>
          {t?.(`desktop.settings.profile.notifications.cards.${type}.action`)}
        </Button>
      )
    }
    case DESK_EVENTS.RESERVATION_DELETE: {
      const pathName = isMobile ? DESK_PATHS.suggest : DESKS_SCHEDULE_PATHNAME
      return (
        <Button variant="link" onClick={() => push?.(pathName)}>
          {t?.(`desktop.settings.profile.notifications.cards.${type}.action`)}
        </Button>
      )
    }
    case DESK_EVENTS.CHECKIN_REMINDER: {
      const { reservation_id, start, end, tz, user, desk, building, floor } =
        notification.message as DESK_CHECK_IN_REMINDER

      const reservation: DeskReservation = {
        id: reservation_id,
        start: start || undefined,
        end: end || undefined,
        user,
        checked_in: null,
        tz: tz || "UTC",
        desk: { id: desk.id, name: desk.name },
        building,
        floor,
        timeslot_id: "",
      }

      const canCheckIn = ReservationCheckinUtils.isEnabled(
        "checkin",
        settings,
        currentUser,
        reservation,
      )

      if (!canCheckIn) return null

      return (
        <Button variant="link" onClick={() => handleCheckIn?.({ reservation })}>
          {t?.(`desktop.settings.profile.notifications.cards.${type}.action`)}
        </Button>
      )
    }
    case ASSET_EVENTS.RESERVATION_RELEASED: {
      const pathName = isMobile ? "/book/asset/time" : ASSETS_SCHEDULE_PATHNAME
      return (
        <Button variant="link" onClick={() => push?.(pathName)}>
          {t?.(`desktop.settings.profile.notifications.cards.${type}.action`)}
        </Button>
      )
    }
    case SYSTEM_EVENTS.CALENDAR_OUT_OF_SYNC: {
      if (isMobile) return null

      return (
        <Button variant="link" onClick={() => push?.(ROOMS_PATHNAME)}>
          {t?.(`desktop.settings.profile.notifications.cards.${type}.action`)}
        </Button>
      )
    }
    case SYSTEM_EVENTS.DEVICE_BATTERY_LOW_AGGREGATE: {
      if (isMobile) return null

      return (
        <Button variant="link" onClick={() => push?.(ROOMS_DEVICES_PATHNAME)}>
          {t?.(`desktop.settings.profile.notifications.cards.${type}.action`)}
        </Button>
      )
    }
    case SYSTEM_EVENTS.DEVICE_ANALYTICS_REPORT: {
      const deviceReport =
        notification.message as SYSTEM_DEVICE_ANALYTICS_REPORT

      if (!deviceReport?.response) return null

      return (
        <Button
          variant="link"
          onClick={() => window.open(deviceReport.response, "_blank")}
        >
          {t(`desktop.settings.profile.notifications.cards.${type}.action`)}
        </Button>
      )
    }
    default:
      return null
  }
}

const getNotificationSensitivity = (
  type: NotificationType,
  seen_at: Notification["seen_at"],
) => {
  if (seen_at !== null) {
    return SensitivityOptions.NONE
  }

  return EVENT_SENSITIVITY[type] || SensitivityOptions.NORMAL
}

const generateNotificationCardProps = (
  notification: Notification,
  utilities: ActionGeneratorUtility,
  handleCheckIn:
    | ((params: { reservation: DeskReservation }) => void)
    | undefined,
  settings: Settings | null,
  currentUser: MeResponse | undefined,
): GeneratedProps => {
  const { id, type, seen_at, message, created_at } = notification
  const { push, t, isMobile } = utilities

  const PREPOPULATED_VALUES = {
    id,
    icon: EVENT_ICON[type],
    actions: generateActions(
      type,
      utilities,
      notification,
      handleCheckIn,
      settings,
      currentUser,
    ),
    sensitivity: getNotificationSensitivity(type, seen_at),
    dismissable: seen_at === null,
    subtitle: dayjs(created_at).format("MMM DD, YYYY · HH:mm"),
  }

  switch (type) {
    case VISITOR_EVENTS.INVITE_CREATED_HOST: {
      const { visitor, start, end, building, floor, tz } =
        message as INVITE_CREATED_HOST
      const location = building || { id: "N/A", name: "N/A" }
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: visitor.full_name,
        }),
        content: (
          <>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={location} floor={floor} tz={tz} />
          </>
        ),
        type,
      }
    }
    case VISITOR_EVENTS.INVITE_UPDATED_HOST: {
      const { visitor, start, end, building, floor, tz } =
        message as INVITE_UPDATED_HOST
      const location = building || { id: "N/A", name: "N/A" }
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: visitor.full_name,
        }),
        content: (
          <>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={location} floor={floor} tz={tz} />
          </>
        ),
        type,
      }
    }
    case VISITOR_EVENTS.INVITE_CHECKED_IN_HOST: {
      const { visitor, start, end, building, floor, tz } =
        message as INVITE_CHECKED_IN_HOST
      const location = building || { id: "N/A", name: "N/A" }
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: visitor.full_name,
        }),
        content: (
          <>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={location} floor={floor} tz={tz} />
          </>
        ),
        type,
      }
    }
    case VISITOR_EVENTS.INVITE_CANCELLED_HOST: {
      const { visitor, start, end, building, floor, tz } =
        message as INVITE_CANCELLED_HOST
      const location = building || { id: "N/A", name: "N/A" }
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: visitor.full_name,
        }),
        content: (
          <>
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle`,
                {
                  name: visitor.full_name,
                },
              )}
            </div>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={location} floor={floor} tz={tz} />
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle_2`,
              )}
            </div>
          </>
        ),
        type,
      }
    }
    case DESK_EVENTS.RESERVATION_RELEASED: {
      const { desk, start, end, tz } = message as DESK_RESERVATION_RELEASED
      const deskName = desk?.name || t("desktop.manage.desk_booking.form.desk")
      const location = desk?.building || {
        id: "N/A",
        name: "N/A",
      }
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: deskName,
        }),
        content: (
          <>
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle`,
                {
                  name: desk?.name ? deskName : deskName.toLowerCase(),
                },
              )}
            </div>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={location} floor={desk?.floor} tz={tz} />
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle_2`,
              )}
            </div>
          </>
        ),
        type,
      }
    }
    case DESK_EVENTS.RESERVATION_DELETE: {
      const { desk, start, end, tz } = message as DESK_RESERVATION_DELETE
      const deskName = desk?.name || t("desktop.manage.desk_booking.form.desk")
      const location = desk?.building || {
        id: "N/A",
        name: "N/A",
      }
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: deskName,
        }),
        content: (
          <>
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle`,
                {
                  name: desk?.name ? deskName : deskName.toLowerCase(),
                },
              )}
            </div>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={location} floor={desk?.floor} tz={tz} />
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle_2`,
              )}
            </div>
          </>
        ),
        type,
      }
    }
    case DESK_EVENTS.CHECKIN_REMINDER: {
      const { desk, start, end, tz, building, floor } =
        message as DESK_CHECK_IN_REMINDER
      const deskName = desk?.name || t("desktop.manage.desk_booking.form.desk")
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: deskName,
        }),
        content: (
          <>
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle`,
                {
                  name: desk?.name ? deskName : deskName.toLowerCase(),
                },
              )}
            </div>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={building} floor={floor} tz={tz} />
          </>
        ),
        type,
      }
    }
    case DESK_EVENTS.CHECKIN_CONFIRMED: {
      const { start, end, tz } = message as DESK_CHECK_IN_CONFIRMED
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`),
        content: (
          <>
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle`,
              )}
            </div>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
          </>
        ),
        type,
      }
    }
    case ASSET_EVENTS.RESERVATION_RELEASED: {
      const { asset, start, end, tz } = message as ASSET_RESERVATION_RELEASED
      const assetName =
        asset?.name || t("desktop.manage.asset_booking.form.asset")
      const location = asset?.building || {
        id: "N/A",
        name: "N/A",
      }
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: assetName,
        }),
        content: (
          <>
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle`,
                {
                  name: asset?.name ? assetName : assetName.toLowerCase(),
                },
              )}
            </div>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={location} tz={tz} />
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle_2`,
              )}
            </div>
          </>
        ),
        type,
      }
    }
    case SYSTEM_EVENTS.PREPAID_CODE_TO_EXPIRE: {
      const { prepaid_code } = message as SYSTEM_PREPAID_CODE_TO_EXPIRE
      const prepaidCode = prepaid_code?.code || "N/A"
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: prepaidCode,
        }),
        content: (
          <>
            <div className="description-row">
              <div>
                <Trans
                  i18nKey={`desktop.settings.profile.notifications.cards.${type}.subtitle`}
                >
                  <a
                    href={`mailto:${getLabel("links.salesEmail")}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    link
                  </a>
                </Trans>
              </div>
            </div>
          </>
        ),
        type,
      }
    }
    case SYSTEM_EVENTS.CALENDAR_OUT_OF_SYNC: {
      const { rooms } = message as SYSTEM_CALENDAR_OUT_OF_SYNC
      const safeRooms = Array.isArray(rooms) ? rooms : []
      const remainingRooms = safeRooms.length - 3
      const moreRoomsText =
        remainingRooms > 0
          ? `+ ${t(`desktop.settings.profile.notifications.cards.${type}.subtitle_2`, { number: remainingRooms })}`
          : ""
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`),
        content: (
          <>
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle`,
              )}
            </div>
            <div className="description-row">
              <div>
                <ul>
                  {safeRooms.slice(0, 3).map((r) => (
                    <li key={r.id}>{r.name}</li>
                  ))}
                </ul>
                {Boolean(moreRoomsText) && (
                  <div className="more-rooms-text">{moreRoomsText}</div>
                )}
              </div>
            </div>
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle_3`,
              )}
            </div>
          </>
        ),
        type,
      }
    }
    case SYSTEM_EVENTS.DEVICE_BATTERY_LOW_AGGREGATE: {
      return {
        ...PREPOPULATED_VALUES,
        title: t?.(
          `desktop.settings.profile.notifications.cards.${type}.title`,
        ),
        content: (
          <>
            <div className="description-row">
              {t?.(
                `desktop.settings.profile.notifications.cards.${type}.subtitle`,
              )}
            </div>
          </>
        ),
        type,
      }
    }
    case SYSTEM_EVENTS.DEVICE_ANALYTICS_REPORT: {
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`),
        content: (
          <>
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle`,
              )}
            </div>
          </>
        ),
        type,
      }
    }
    default: {
      // return null // TODO: Uncomment this once all notification types are implemented

      // TODO: Remove this once all notification types are implemented
      return {
        ...PREPOPULATED_VALUES,
        title: `NOT IMPLEMENTED: ${type}`,
        content: "Notification type payload should be rendered here",
        icon: <div>Icon</div>,
        sensitivity: SensitivityOptions.NORMAL,
        type,
      }
    }
  }
}

const NotificationCardGenerator = ({
  notification,
  utilities,
  listId,
  discardable,
}: NotificationCardGeneratorProps) => {
  const handleCheckIn = useDeskCheckIn()
  const { entry: settings } = useAppSelector(selectSettingsEffective)
  const { entry: currentUser } = useAppSelector(selectUser)

  const props = generateNotificationCardProps(
    notification,
    utilities,
    handleCheckIn,
    settings,
    currentUser,
  )

  if (!props) {
    return null
  }

  return (
    <NotificationCard
      {...props}
      listId={listId}
      discardable={discardable}
      type={notification.type}
    />
  )
}

export default NotificationCardGenerator
