import React, { useEffect, useRef, useState } from "react"

import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { isBlank } from "../../../utils"

import { useLazyFetchMeQuery } from "../../../redux/api/me"
import { useAppSelector } from "../../../redux/reducers"
import { UserRequest } from "../../../redux/users/types"
import { patchUser } from "../../../redux/users/usersSlice"
import { useActions } from "../../../redux/utils"

import Button from "../../../components/advanced/Button"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"

import "./Edit.sass"

const Edit: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [fetchUser] = useLazyFetchMeQuery()

  const lastNameRef = useRef<HTMLInputElement>(null)

  const { isLoaded, isLoading, entry } = useAppSelector((state) => state.user)

  const actions = useActions({
    patchManagementUser: (email: string, payload: UserRequest) =>
      patchUser({ email, payload }),
  })

  const [firstName, setFirstName] = useState(entry.first_name || "")
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastName, setLastName] = useState(entry.last_name || "")
  const [lastNameError, setLastNameError] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (isLoaded !== undefined && isLoaded) {
      setFirstName(entry.first_name || "")
      setLastName(entry.last_name || "")
    }
  }, [actions, isLoaded, entry])

  const handleSave = async () => {
    if (!isBlank(firstName) && !isBlank(lastName)) {
      setIsSaving(true)

      await actions.patchManagementUser(entry.email, {
        first_name: firstName,
        last_name: lastName,
      })
      await fetchUser()

      setIsSaving(false)
      history.push("/profile/info")
    }
  }

  return (
    <SafeViewArea className="Edit">
      <TopNav title={t("mobile.profile.edit")} />
      <div className="body">
        <form>
          <div className="input-field">
            <input
              type="text"
              value={firstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFirstName(e.target.value)
                setFirstNameError(isBlank(e.target.value))
              }}
              placeholder={t("mobile.profile.first_name")}
              disabled={isLoading || isSaving}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  lastNameRef.current?.focus()
                }
              }}
            />
            {firstNameError && (
              <div className="input-error">
                {t("mobile.auth.mandatory_field")}
              </div>
            )}
          </div>
          <div className="input-field">
            <input
              type="text"
              value={lastName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLastName(e.target.value)
                setLastNameError(isBlank(e.target.value))
              }}
              placeholder={t("mobile.profile.last_name")}
              disabled={isLoading || isSaving}
              ref={lastNameRef}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  lastNameRef.current?.blur()
                }
              }}
            />
            {lastNameError && (
              <div className="input-error">
                {t("mobile.auth.mandatory_field")}
              </div>
            )}
          </div>
        </form>
      </div>
      <div className="action">
        <Button
          variant="mobile-action"
          isDisabled={isLoading || isSaving}
          onClick={handleSave}
        >
          {t("general.save")}
        </Button>
      </div>
    </SafeViewArea>
  )
}

export default Edit
