import { api } from "../"
import { buildingsURL, buildingURL } from "../../../api"
import { FEATURE_FLAGS, FETCH_WITH_NO_LIMIT } from "../../../constants"
import {
  BuildingFetchProps,
  BuildingRequest,
  BuildingResponse,
  BuildingsFetchOptions,
  BuildingsResponse,
  UpdateBuildingProps,
} from "./types"
import { createAsyncThunk } from "@reduxjs/toolkit"

import { RootState } from "../../../redux/reducers"
import { NewVisitsCreatedByOption } from "../../../redux/visitor_settings/types"
import { createVisitorSetting } from "../../../redux/visitor_settings/visitorSettingsSlice"

const FETCH_DEFAULTS = {
  limit: FETCH_WITH_NO_LIMIT,
  offset: 0,
  stats: false,
}

export const buildings = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchBuildings: builder.query<
      BuildingsResponse,
      BuildingsFetchOptions | void
    >({
      query: (options = {}) => {
        return {
          url: buildingsURL({
            ...FETCH_DEFAULTS,
            ...options,
            query: {
              fields: ["*", "settings"],
            },
          }),
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: "Buildings" as const,
                id,
              })),
              { type: "Buildings", id: "LIST" },
            ]
          : [{ type: "Buildings", id: "LIST" }],
    }),

    fetchBuilding: builder.query<BuildingResponse, BuildingFetchProps>({
      query: ({ id, stats = false }) => ({
        url: buildingURL(id, {
          stats,
          query: {
            fields: ["*", "settings", "settings_effective"],
          },
        }),
      }),
      providesTags: (_result, _error, { id }) => [{ type: "Buildings", id }],
    }),

    createBuilding: builder.mutation<BuildingResponse, BuildingRequest>({
      query: (body) => ({
        url: buildingsURL(),
        method: "POST",
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        // Wait for the building to be created
        const { data: building } = await queryFulfilled

        // Check if the new business model feature flag is enabled
        const state = getState() as RootState
        const featureFlags = state.featureFlags.entry || []
        const isNewBusinessModel = !!featureFlags.find(
          (flag) => flag.name === FEATURE_FLAGS.NEW_BUSINESS_MODEL,
        )

        // If feature flag is enabled, automatically enable visitor management
        if (isNewBusinessModel) {
          await dispatch(
            createVisitorSetting({
              building_id: building.id,
              enabled: true,
              create_visits_by: NewVisitsCreatedByOption.ALL_EMPLOYEES,
              document_signing_enabled: false,
              send_signed_email: false,
              document_send_to: "",
              automatic_visitor_registration: false,
              excluded_calendar_domain: "",
              enable_general_notification: true,
              send_app_notification: true,
              send_sms_notification: false,
              send_email_limit_notification: false,
              send_slack_notification: false,
              send_arrival_email_notification: false,
              enable_visitors_notification: true,
              send_invitation_email: true,
              send_welcome_email: true,
              enable_maintenance_notification: false,
            }),
          )
        }
      },
      invalidatesTags: [{ type: "Buildings", id: "LIST" }],
    }),

    updateBuilding: builder.mutation<BuildingResponse, UpdateBuildingProps>({
      query: ({ id, ...body }) => ({
        url: buildingURL(id),
        method: "PUT",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Buildings", id: "LIST" },
        { type: "Buildings", id },
      ],
    }),

    destroyBuilding: builder.mutation<void, string>({
      query: (id) => ({
        url: buildingURL(id),
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: "Buildings", id: "LIST" },
        { type: "Buildings", id },
      ],
    }),
  }),
})

export const {
  useFetchBuildingsQuery,
  useLazyFetchBuildingsQuery,
  useFetchBuildingQuery,
  useCreateBuildingMutation,
  useUpdateBuildingMutation,
  useDestroyBuildingMutation,
} = buildings

export const selectBuildingsWithStatuses =
  buildings.endpoints.fetchBuildings.select({
    stats: true,
  })
