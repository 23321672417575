import React, { useEffect, useState } from "react"

import classNames from "classnames"
import { useHistory } from "react-router-dom"

import { FETCH_FOR_COUNT } from "../../../constants"
import Calendar from "./Calendar"
import { ROOM_BOOKING_PATHS } from "./constants"
import Header from "./Header"

import { useFetchRoomsRbQuery } from "../../../redux/api/roomBookingRooms"
import { selectAppDates } from "../../../redux/app/selectors"
import { useAppSelector } from "../../../redux/reducers"

import Space from "../../../components/Space"
import View from "../../../components/View"

import "./style.sass"

export enum CalendarView {
  Day = "day",
  Week = "week",
}

const ManageRooms = () => {
  const history = useHistory()

  const { showWeekends } = useAppSelector(selectAppDates)

  const [calendarView, setCalendarView] = useState<CalendarView>(
    (new URLSearchParams(location.search).get("view") as CalendarView) ||
      CalendarView.Day,
  )

  const { data: { count: roomsCount = 0 } = {} } =
    useFetchRoomsRbQuery(FETCH_FOR_COUNT)

  const hasRooms = roomsCount > 0

  const manageRoomsClassName = classNames("ManageRooms", {
    showWeekends: calendarView === CalendarView.Week && showWeekends,
  })

  const handleNewReservation = () => history.push(ROOM_BOOKING_PATHS.add)

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const calendarViewParam = queryParams.get("view") as CalendarView

    if (calendarViewParam && calendarViewParam !== calendarView) {
      setCalendarView(calendarViewParam)
    }
  }, [location.search, calendarView])

  return (
    <View className={manageRoomsClassName}>
      <Header
        calendarView={calendarView}
        onNewReservation={() => handleNewReservation()}
        showNewReservationButton={hasRooms}
      />

      <Space size={0.75} />

      <Calendar
        calendarView={calendarView}
        onCalendarViewChange={setCalendarView}
      />
    </View>
  )
}

export default ManageRooms
