import { useEffect } from "react"

import { Trans, useTranslation } from "react-i18next"

import { useScrollToLocation } from "../hooks/useScrollToLocation"
import Button from "./advanced/Button"
import { Alert } from "./Alert"
import Loader from "./basic/Loader"
import { ConfirmButton } from "./ConfirmButton"

import { getAppParams } from "../redux/appParams/selectors"
import { useAppSelector } from "../redux/reducers"
import { selectSsoProviders } from "../redux/ssoProviders/selectors"
import {
  fetchSsoProviders,
  removeSsoProvider,
} from "../redux/ssoProviders/ssoProvidersSlice"
import {
  SsoProviderResponse,
  SsoProviderType,
} from "../redux/ssoProviders/types"
import { useActions } from "../redux/utils"

import { toast } from "../components/Toast"

import GoogleSVG from "../assets/images/icons/GoogleWorkplace.svg"
import Office365SVG from "../assets/images/icons/Office365.svg"

import "./SsoProviders.sass"

const SSO_PROVIDERS: {
  [x: string]: {
    label: string
    icon: JSX.Element
  }
} = {
  google: {
    label: "Google",
    icon: <GoogleSVG />,
  },
  office365: {
    label: "Office 365",
    icon: <Office365SVG />,
  },
}

const getProviderName = (provider: SsoProviderType) => {
  return SSO_PROVIDERS[provider]?.label ?? provider
}

type ProviderProps = {
  provider: SsoProviderType
}

const ProviderLabel = ({ provider }: ProviderProps) => {
  return <b>{getProviderName(provider)}</b>
}

const ProviderIcon = ({ provider }: ProviderProps) => {
  return SSO_PROVIDERS[provider]?.icon ?? null
}

const ProviderStatus = ({ provider, linked }: SsoProviderResponse) => {
  const { t } = useTranslation()

  if (linked) {
    return (
      <>
        <div>
          <div className="indicator" />
          <div>
            {t("desktop.settings.profile.general.form.connected_to_service", {
              service: getProviderName(provider),
            })}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div>
        <div className="indicator inactive" />
        <div>
          {t("desktop.settings.profile.general.form.not_connected_to_service", {
            service: getProviderName(provider),
          })}
        </div>
      </div>
    </>
  )
}

interface ProviderButtonProps extends SsoProviderResponse {
  onConnect: (url: string) => void
  onDisconnect: (provider: SsoProviderType) => void
}

const ProviderButton = ({
  provider,
  link_url,
  linked,
  onConnect,
  onDisconnect,
}: ProviderButtonProps) => {
  const { t } = useTranslation()
  const { appName } = useAppSelector(getAppParams)

  if (linked) {
    return (
      <ConfirmButton
        cardHint={
          <>
            <p>
              <Trans
                i18nKey="general.sso_providers.disconnect_message"
                values={{ provider: getProviderName(provider), appName }}
                components={{ strong: <strong /> }}
              />
            </p>
            {t("general.sso_providers.disconnect_confirm")}
          </>
        }
        className="btn-sso"
        onConfirm={() => onDisconnect(provider)}
        isSmall
        variant="secondary"
      >
        <ProviderIcon provider={provider} />
        {t("general.sso_providers.disconnect_from")}&nbsp;
        <ProviderLabel provider={provider} />
      </ConfirmButton>
    )
  }

  return (
    <Button
      variant="secondary"
      isSmall
      onClick={() => onConnect(link_url)}
      className="btn-sso"
    >
      <ProviderIcon provider={provider} />
      {t("general.sso_providers.connect_to")}&nbsp;
      <ProviderLabel provider={provider} />
    </Button>
  )
}

export const SsoProviders = () => {
  const { t } = useTranslation()

  useScrollToLocation()

  const { ssoProviders, isLoaded, isLoading, error } =
    useAppSelector(selectSsoProviders)
  const actions = useActions({
    fetchSsoProviders: () => fetchSsoProviders(),
    removeSsoProvider: (provider: SsoProviderType) =>
      removeSsoProvider(provider),
  })

  const onConnect = (link_url: string) => {
    let next = window.location.href

    if (!next.endsWith("#connected-accounts")) {
      next += "#connected-accounts"
    }

    window.location.href = `${link_url}&next=${encodeURIComponent(next)}`
  }

  const onDisconnect = async (provider: SsoProviderType) => {
    const response = await actions.removeSsoProvider(provider)

    if (removeSsoProvider.fulfilled.match(response)) {
      toast.info(
        `${getProviderName(provider)} ${t("general.sso_providers.unpaired_info")}`,
        {
          hideProgressBar: true,
        },
      )
    } else {
      toast.error(response.error.message, { hideProgressBar: true })
    }

    await actions.fetchSsoProviders()
  }

  useEffect(() => {
    actions.fetchSsoProviders()
  }, [actions])

  const renderProvider = (props: SsoProviderResponse) => {
    return (
      <div className="provider" key={props.provider}>
        <ProviderButton
          {...props}
          onConnect={onConnect}
          onDisconnect={onDisconnect}
        />
        <ProviderStatus {...props} />
      </div>
    )
  }

  return (
    <div className="SsoProviders" id="connected-accounts">
      {!isLoaded && <Loader />}
      {isLoaded && ssoProviders.map(renderProvider)}
      {!error && !isLoading && isLoaded && !ssoProviders.length && (
        <div>{t("general.sso_providers.no_providers")}</div>
      )}
      {error && <Alert type="error">{t("general.sso_providers.error")}</Alert>}
    </div>
  )
}
