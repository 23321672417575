import React, { PropsWithChildren, useCallback, useEffect } from "react"

import { useTranslation } from "react-i18next"

import { useLockOrientation } from "../../../hooks/useLockOrientation"
import { useNativeConnectivityChange } from "../../../hooks/useNativeConnectivityChange"
import BackgroundCheckinProvider from "./BackgroundCheckinProvider"
import DeepLinkProvider from "./DeepLinkProvider"
import PushNotificationsProvider from "./PushNotificationsProvider"
import { App as CapacitorApp } from "@capacitor/app"
import {
  ScreenOrientation,
  ScreenOrientationResult,
} from "@capacitor/screen-orientation"

import { appError, setIsPortraitOrientation } from "../../../redux/app/appSlice"
import { useActions } from "../../../redux/utils"

const PORTRAIT_ORIENTATIONS = [
  "portrait",
  "portrait-primary",
  "portrait-secondary",
]

// TODO: check if IosSwipeBack.enable() works
export const NativeProviders = ({ children }: PropsWithChildren<unknown>) => {
  const { t } = useTranslation()

  const actions = useActions({
    appError: (message: string | null) => appError(message),
    setIsPortraitOrientation: (isPortrait: boolean) =>
      setIsPortraitOrientation(isPortrait),
  })

  const checkConnectivity = useCallback(
    (connected: boolean) => {
      if (connected) {
        actions.appError(null)
      } else {
        actions.appError(t("tablet.no_internet"))
      }
    },
    [actions, t],
  )

  useNativeConnectivityChange(checkConnectivity)
  useLockOrientation()

  useEffect(() => {
    CapacitorApp.addListener("backButton", ({ canGoBack }) => {
      if (!canGoBack) {
        CapacitorApp.exitApp()
      } else {
        window.history.back()
      }
    })

    ScreenOrientation.addListener(
      "screenOrientationChange",
      async (newOrientation: ScreenOrientationResult) => {
        actions.setIsPortraitOrientation(
          PORTRAIT_ORIENTATIONS.includes(newOrientation.type),
        )

        // needs to wait for the orientation to change
        setTimeout(() => {
          const vh = window.innerHeight / 100
          document.documentElement.style.setProperty("--vh", `${vh}px`)
        }, 100)
      },
    )

    return () => {
      ScreenOrientation.removeAllListeners()
      CapacitorApp.removeAllListeners()
    }
  }, [actions])

  return (
    <DeepLinkProvider>
      <PushNotificationsProvider />
      <BackgroundCheckinProvider />
      {children}
    </DeepLinkProvider>
  )
}
