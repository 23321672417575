import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { useLocation, useRouteMatch } from "react-router-dom"

import useIsRoomsDayViewActive from "../hooks/useIsRoomsDayViewActive"
import { getLabel } from "../utils"
import Headway from "./Headway"

import { getAppParams } from "../redux/appParams/selectors"
import { useAppSelector } from "../redux/reducers"

import "./Footer.sass"

type Props = {
  isCentered?: boolean
}

export default function Footer({ isCentered }: Props) {
  const { t } = useTranslation()

  const location = useLocation()

  const { showWeekends } = useAppSelector((state) => state.app)

  const { isAppWhitelabel } = useAppSelector(getAppParams)
  const isRoomsDayViewActive = useIsRoomsDayViewActive()

  const isManage = useRouteMatch("/manage")
  const isManageFloorPlan = useRouteMatch("/manage/floor-plan")
  const isRooms = useRouteMatch("/manage/rooms")
  const isVisitor = location.pathname.startsWith("/manage/visitor")

  const footerClassName = classNames({
    Footer: true,
    isConstrained:
      (!(showWeekends && isManage) && !isManageFloorPlan) ||
      isVisitor ||
      (isRooms && isRoomsDayViewActive),
    isCentered,
  })

  if (isAppWhitelabel) {
    return null
  }

  return (
    <div className={footerClassName}>
      <div className="row">
        <div className="copy">&copy; {new Date().getFullYear()} Visionect</div>
        <div className="menu">
          <a href={getLabel("links.termsOfUseURL")}>
            {t("general.navigation_links.terms")}
          </a>
          <a href={getLabel("links.privacyPolicyURL")}>
            {t("general.navigation_links.privacy")}
          </a>
          <a href={`mailto:${getLabel("links.supportEmail")}`}>
            {t("general.navigation_links.support")}
          </a>
          <Headway />
        </div>
      </div>
    </div>
  )
}
