import { PropsWithChildren } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { getUserInfo } from "../screens/Manage/FloorPlan/utils"
import { Children, IdAndName, ScheduleReservation } from "../types/sharedTypes"
import { shortUserTimeFormat } from "../utils"
import Avatar from "./advanced/Avatar"
import { Tooltip } from "./basic/Tooltip"

import { DepartmentResponse } from "../redux/api/departments/types"
import { Reservation } from "../redux/api/roomReservations/types"
import { formatUser } from "../redux/user/utils"

import "./PlaceTooltip.sass"

type PlaceTooltipProps = {
  title: string
  departments?: DepartmentResponse[]
  amenities?: IdAndName[]
  reservations?: ScheduleReservation[]
  children: Children
  uniqueId: string
  isTooltipClickable: boolean
}

const PlaceTooltip = ({
  title,
  amenities,
  departments,
  reservations,
  children,
  uniqueId,
  isTooltipClickable,
}: PropsWithChildren<PlaceTooltipProps>) => {
  return (
    <div className="PlaceTooltip">
      <Tooltip
        className="place-tooltip"
        content={
          title && (
            <PlaceTooltipContent
              title={title}
              departments={departments}
              amenities={amenities}
              reservations={reservations}
            />
          )
        }
        uniqueId={uniqueId}
        clickable={isTooltipClickable}
        place="bottom"
        offset={20}
      >
        {children}
      </Tooltip>
    </div>
  )
}

type PlaceTooltipContentProps = {
  title: string
  departments?: DepartmentResponse[]
  amenities?: IdAndName[]
  reservations?: ScheduleReservation[] | Reservation[]
}

const PlaceTooltipContent = ({
  title,
  departments,
  amenities,
  reservations,
}: PlaceTooltipContentProps) => {
  const { t } = useTranslation()

  const hasDepartments = departments && departments.length > 0
  const hasAmenities = amenities && amenities.length > 0
  const hasReservations = reservations && reservations.length > 0
  const isBookedForVisitor = reservations?.some((res) => {
    if ("visit_id" in res) {
      return Boolean(res.visit_id)
    }
    return false
  })

  return (
    <div className="TooltipPlaceContent">
      <div className="MetaPlaceContent">
        <div className="PlaceTitle">{title}</div>

        {hasDepartments && (
          <div className="PlaceDepartments">
            {departments.map((d: DepartmentResponse) => (
              <span key={d.id}>{d.name}</span>
            ))}
          </div>
        )}
        {hasAmenities && (
          <div className="PlaceAmenities">
            {amenities.map((a: IdAndName) => (
              <span key={a.id}>{a.name}</span>
            ))}
          </div>
        )}
      </div>

      {hasReservations && (
        <div className="PlaceReservations PlaceReservations__desks">
          {reservations.map((r) => {
            const user = getUserInfo(r)

            return (
              <div key={r.id} className="PlaceReservationsRow">
                <div>
                  {user && <Avatar size="medium" user={user} />}
                  {formatUser(user)}
                </div>
                <div>
                  {dayjs(r.start).format(shortUserTimeFormat())}
                  {" - "}
                  {dayjs(r.end).format(shortUserTimeFormat())}
                </div>
              </div>
            )
          })}
        </div>
      )}

      {isBookedForVisitor && (
        <div className="PlaceVisitor">
          ({t("desktop.manage.floor_plan.reservation_for_visitor")})
        </div>
      )}
    </div>
  )
}

export default PlaceTooltip
