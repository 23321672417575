import React, { useEffect, useMemo, useRef, useState } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { cssTransition } from "react-toastify"

import { FETCH_UNREAD } from "../../../constants"
import { useBottomScrollListener } from "../../../hooks/useBottomScrollListener"
import { generateId } from "../../../hooks/useId"
import { useNavigation } from "../../../hooks/useNavigation"
import { ToastContainer } from "../../Toast"
import NotificationCard from "../NotificationCard"
import NotificationCardGenerator from "../NotificationCard/NotificationCardGenerator"
import { SupportedEventType } from "../NotificationCard/NotificationCardGenerator/types"
import { SensitivityOptions } from "../NotificationCard/types"
import { useVirtualizer } from "@tanstack/react-virtual"

import { api } from "../../../redux/api"
import { useLazyFetchNotificationsInfiniteQuery } from "../../../redux/api/notifications"
import { ROOM_EVENTS } from "../../../redux/api/notifications/types"
import { selectIsMobile } from "../../../redux/app/selectors"
import { useAppSelector } from "../../../redux/reducers"

import CheckmarkEmptySVG from "../../../assets/images/icons/CheckmarkEmpty.svg"

import "./style.sass"

export type NotificationsVariant = "popup" | "mobile" | "list"

type NotificationListProps = {
  limit?: number
  containerHeight: string
  type?: SupportedEventType[]
  onlyShowUnread?: boolean
  fullWidth?: boolean
  onListIdGenerated?: (id: string) => void
  variant: NotificationsVariant
}

const DEFAULT_LIMIT = 25
const NO_NOTIFICATIONS_POPUP_CONTAINER_HEIGHT = "17.3125rem"

type NoNotificationsFoundProps = {
  emptyNotificationListViewClassName: string
  variant: NotificationsVariant
}

const SlideTransition = cssTransition({
  enter: "slideInUp",
  exit: "slideOutDown",
})

const NoNotificationsFound = ({
  emptyNotificationListViewClassName,
  variant,
}: NoNotificationsFoundProps) => {
  const { t } = useTranslation()
  return (
    <div className={emptyNotificationListViewClassName}>
      <CheckmarkEmptySVG className="NoNotificationsIcon" />
      <div className="NoNotificationsText">
        {t("desktop.settings.profile.notifications.empty.caught_up")}
      </div>
      {variant !== "list" && (
        <div className="NoNotificationsAdditionalText">
          {t("desktop.settings.profile.notifications.empty.no_notifications")}
        </div>
      )}
    </div>
  )
}

/**
 * Provides a list of notification cards with infinite scroll
 * @param limit - The number of notifications to fetch per page - make sure to set this to a number that will overflow the container
 * @param containerHeight - The height of the container e.g. "80vh"
 * @param gap - The gap between each notification card e.g. "0.25rem"
 * @param type - The parameter used to filter the notifications based on their type. The `type` determines which category of notifications will be displayed (e.g., `system`, `visitor`, `desk`, etc.).
 *   Possible values could be:
 *    - `system`: Notifications related to system events such as device battery levels, calendar synchronization, etc.
 *    - `visitor`: Notifications related to visitor check-ins, invites, and other related events.
 *    - `desk`: Notifications related to desk reservations, cancellations, check-in reminders, etc.
 *    - `room`: Notifications related to room meeting reminders and end times.
 * @param onlyShowUnread - Only show unread notifications
 * @param fullWidth - Make the notification cards full width or compensate for scrollbar
 * @param onListIdGenerated - Callback to receive the listId
 * @param variant - The variant of the notification list
 */
const NotificationList = ({
  limit = DEFAULT_LIMIT,
  containerHeight,
  type,
  onlyShowUnread = false,
  fullWidth = false,
  onListIdGenerated,
  variant,
}: NotificationListProps) => {
  const isMobile = useAppSelector(selectIsMobile)

  const { t } = useTranslation()
  const { push } = useNavigation()
  const dispatch = useDispatch()
  const containerRef = useRef<HTMLDivElement>(null)
  const [listId, setListId] = useState(generateId())

  const [
    fetchNotifications,
    {
      data: { results: notifications = [], deletedCount = 0, next = null } = {},
      isFetching,
      isUninitialized,
    },
  ] = useLazyFetchNotificationsInfiniteQuery()

  const nextOffset = useMemo(
    () => (next ? Number(new URL(next).searchParams.get("offset") ?? 0) : 0),
    [next],
  )

  useBottomScrollListener({
    containerRef,
    offset: 50,
    onBottom: () => {
      if (next && !isFetching) {
        const offset = nextOffset - deletedCount

        const fetchNotificationsOptions = {
          listId,
          limit,
          offset,
          type,
          ...(onlyShowUnread && FETCH_UNREAD),
        }

        fetchNotifications(fetchNotificationsOptions)
      }
    },
  })

  const rowVirtualizer = useVirtualizer({
    count: notifications.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 250,
    getItemKey: (index) => notifications[index].id,
    overscan: 10,
  })

  /**
   * Invalidate the cache since we merge the results and we want to start new when clicking back into the page
   */
  useEffect(() => {
    const newListId = generateId()

    onListIdGenerated?.(newListId)

    const fetchNotificationsOptions = {
      listId: newListId,
      limit,
      offset: 0,
      type,
      ...(onlyShowUnread && FETCH_UNREAD),
    }

    fetchNotifications(fetchNotificationsOptions)

    // Scroll to top as the notification list changed
    containerRef.current?.scrollTo(0, 0)

    // Merge on top of the current instance to isolate the cache
    setListId(newListId)

    return () => {
      dispatch(
        api.util.invalidateTags([
          { type: "Notifications", id: `INFINITE-${listId}` },
        ]),
      )
    }
  }, [onlyShowUnread, type])

  const emptyNotificationListViewClassName = classNames(
    "NotificationListView__Empty",
    {
      [variant]: variant,
    },
  )

  const noNotificationsAvailable =
    notifications.length === 0 && !isUninitialized && !isFetching

  const isPopupWithoutNotifications =
    variant === "popup" && notifications.length === 0

  const height = isPopupWithoutNotifications
    ? NO_NOTIFICATIONS_POPUP_CONTAINER_HEIGHT
    : containerHeight

  const memoizedNotificationCardProps = useMemo(
    () =>
      notifications.map((notification) => (
        <NotificationCardGenerator
          key={notification.id}
          notification={notification}
          utilities={{ push, t, isMobile }}
          listId={listId}
          discardable={onlyShowUnread}
        />
      )),
    [notifications, push, t, isMobile, listId, onlyShowUnread],
  )

  const Skeletons = Array(variant === "list" ? 2 : 1)
    .fill(null)
    .map((_, index) => (
      <NotificationCard
        key={index}
        id={"#cardSkeleton#1"}
        listId="#skeletonList#1"
        title={undefined}
        content={undefined}
        subtitle={undefined}
        icon={undefined}
        type={ROOM_EVENTS.MEETING_REMINDER}
      />
    ))

  return (
    <div
      style={{
        height,
      }}
      className="NotificationListView"
      ref={containerRef}
    >
      {noNotificationsAvailable && (
        <NoNotificationsFound
          variant={variant}
          emptyNotificationListViewClassName={
            emptyNotificationListViewClassName
          }
        />
      )}

      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
        className="VirtualizerContainer"
      >
        {rowVirtualizer.getVirtualItems().map((virtualItem) => {
          const notificationCardElement =
            memoizedNotificationCardProps[virtualItem.index]

          return (
            <div
              key={virtualItem.key}
              data-index={virtualItem.index}
              ref={rowVirtualizer.measureElement}
              style={{
                top: `${virtualItem.start}px`,
                width: fullWidth ? "100%" : "calc(100% - 0.5rem)",
              }}
              className="VirtualizerItem"
            >
              {notificationCardElement}
            </div>
          )
        })}

        {isFetching && Skeletons}
      </div>

      <ToastContainer
        position="bottom-left"
        transition={SlideTransition}
        containerId={`notification-${variant}-toast-container`}
        closeButton={false}
      />
    </div>
  )
}

export default NotificationList
