import React, { useEffect, useState } from "react"

import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from "capacitor-native-settings"
import { useTranslation } from "react-i18next"

import { useRefresh } from "../../../hooks/mobile/useRefresh"
import { isNative } from "../../../utils"
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic"
import { Capacitor } from "@capacitor/core"
import { Device } from "@capacitor/device"
import { BackgroundRunner } from "@joan/capacitor-background-runner"

import { useAppSelector } from "../../../redux/reducers"
import {
  updatePrivacyMode,
  updatePushEnabled,
} from "../../../redux/user/userSlice"
import { useActions } from "../../../redux/utils"

import Accordion from "../../../components/basic/Accordion"
import { CategoryRow } from "../../../components/Mobile/CategoryRow"
import { Checker } from "../../../components/Mobile/Checker"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"

import "./Settings.sass"

declare let window: any

const Settings: React.FC = () => {
  const { t } = useTranslation()

  const { isLoading, entry } = useAppSelector((state) => state.user)

  const [isAutoCheckInEnabled, setIsAutoCheckInEnabled] = useState(false)

  const actions = useActions({
    updatePrivacyMode: (privacyMode: boolean) => updatePrivacyMode(privacyMode),
    updatePushEnabled: (pushEnabled: boolean) => updatePushEnabled(pushEnabled),
  })

  const handlePrivateValue = () => {
    const newValue = !entry.privacy_mode_enabled

    actions.updatePrivacyMode(newValue)
  }

  const handlePushEnable = async () => {
    const newValue = !entry.push_enabled
    if (newValue) {
      const isPushNotificationsAvailable =
        Capacitor.isPluginAvailable("PushNotifications")

      if (isPushNotificationsAvailable) {
        const deviceInfo = await Device.getInfo()

        const isRemoteNotificationEnabled: boolean =
          deviceInfo.operatingSystem === "ios"
            ? (await Diagnostic.getRemoteNotificationsAuthorizationStatus()) ===
              "authorized"
            : await Diagnostic.isRemoteNotificationsEnabled()
        if (!isRemoteNotificationEnabled) {
          if (window.confirm(t("mobile.profile.confirm_settings_change"))) {
            NativeSettings.open({
              optionAndroid: AndroidSettings.ApplicationDetails,
              optionIOS: IOSSettings.App,
            })
          }
        } else {
          actions.updatePushEnabled(newValue)
        }
      } else {
        actions.updatePushEnabled(newValue)
      }
    } else {
      actions.updatePushEnabled(newValue)
    }
  }

  const handleAutoCheckIn = async () => {
    let permissions = await BackgroundRunner.checkPermissions()

    if (permissions.geolocation !== "alwaysOn") {
      if (
        Capacitor.getPlatform() === "ios" &&
        permissions.geolocation === "prompt"
      ) {
        // On iOS, we need to request permissions first if status is "prompt"
        await BackgroundRunner.requestPermissions({
          apis: ["geolocation"],
        })
      } else {
        if (window.confirm(t("mobile.profile.location.desc_modal_enable"))) {
          await NativeSettings.open({
            optionAndroid: AndroidSettings.ApplicationDetails,
            optionIOS: IOSSettings.App,
          })
        }
      }

      // Refresh the permissions
      permissions = await BackgroundRunner.checkPermissions()
    } else {
      if (window.confirm(t("mobile.profile.location.desc_modal_disable"))) {
        await NativeSettings.open({
          optionAndroid: AndroidSettings.ApplicationDetails,
          optionIOS: IOSSettings.App,
        })
      }
    }

    // Initial state based on current permissions
    setIsAutoCheckInEnabled(permissions.geolocation === "alwaysOn")
  }

  // Syncs UI related to permissions with the actual permission data on mount
  useEffect(() => {
    const checkPermissions = async () => {
      const permissions = await BackgroundRunner.checkPermissions()
      setIsAutoCheckInEnabled(permissions.geolocation === "alwaysOn")
    }
    checkPermissions()
  }, [])

  // Syncs UI related to permissions with the actual permission data when app comes to foreground
  useRefresh(async () => {
    const permissions = await BackgroundRunner.checkPermissions()
    setIsAutoCheckInEnabled(permissions.geolocation === "alwaysOn")
  })

  return (
    <SafeViewArea className="MobileSettings">
      <TopNav title={t("mobile.profile.settings")} />
      <div className="body">
        <CategoryRow name={t("mobile.profile.privacy")}>
          <div className="input">
            <div className="text">
              <div className="name">{t("mobile.profile.hide_user_info")}</div>
              <div className="helper-text">
                {t("mobile.profile.hide_user_info_desc")}
              </div>
            </div>
            <div className="action">
              <Checker
                checked={entry.privacy_mode_enabled}
                disabled={isLoading}
                onChange={handlePrivateValue}
              />
            </div>
          </div>
        </CategoryRow>
        {isNative() && (
          <CategoryRow name={t("mobile.profile.push_notifications")}>
            <div className="input">
              <div className="text">
                <div className="name">
                  {t("mobile.profile.push_notifications")}
                </div>
                <div className="helper-text">
                  {t("mobile.profile.push_notifications_desc")}
                </div>
              </div>
              <div className="action">
                <Checker
                  checked={entry.push_enabled}
                  disabled={isLoading}
                  onChange={handlePushEnable}
                />
              </div>
            </div>
          </CategoryRow>
        )}
        {isNative() && (
          <CategoryRow name={t("mobile.profile.location.feature_title")}>
            <div className="input">
              <div className="text">
                <div className="name">{t("mobile.profile.location.title")}</div>
                <div className="helper-text">
                  {t("mobile.profile.location.desc")}
                  <div className="helper-text__location">
                    <p>
                      <strong>
                        {t("mobile.profile.location.desc_more_title")}
                      </strong>
                    </p>
                    <p>{t("mobile.profile.location.desc_more_intro")}</p>
                    <p>{t("mobile.profile.location.desc_more_requirements")}</p>
                    <ul className="helper-text__location-requirements">
                      <li>
                        {t("mobile.profile.location.desc_more_requirement1")}
                      </li>
                      <li>
                        {t("mobile.profile.location.desc_more_requirement2")}
                      </li>
                    </ul>
                    <Accordion
                      header={t("general.show_less")}
                      headerClosed={t("general.show_more")}
                      defaultOpen={false}
                    >
                      <div className="helper-text__section">
                        <p className="helper-text__title">
                          <strong>
                            {t(
                              "mobile.profile.location.desc_more_disable_title",
                            )}
                          </strong>
                        </p>
                        <div className="helper-text__content">
                          <ul className="helper-text__requirements-list">
                            <li>
                              {t(
                                "mobile.profile.location.desc_more_disable_requirement1",
                              )}
                            </li>
                            <li>
                              {t(
                                "mobile.profile.location.desc_more_disable_requirement2",
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Accordion>
                  </div>
                </div>
              </div>
              <div className="action">
                <Checker
                  checked={isAutoCheckInEnabled}
                  disabled={isLoading}
                  onChange={handleAutoCheckIn}
                />
              </div>
            </div>
          </CategoryRow>
        )}
      </div>
    </SafeViewArea>
  )
}

export default Settings
