import { api } from "../"
import {
  roomReservationsScheduleURL,
  roomReservationsUrl,
  roomReservationURL,
} from "../../../api"
import { PaginatedResponse } from "../../types"
import {
  FetchRoomReservationsOptions,
  RoomReservation,
  RoomReservationEditRequest,
  RoomReservationRequest,
  RoomReservationResponse,
} from "./types"

import { FilterSpecialValues } from "../../../components/Filter/types"

export const roomReservations = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchRoomReservations: builder.query<
      PaginatedResponse<RoomReservation>,
      FetchRoomReservationsOptions
    >({
      query: ({ building_id, floor_id, amenity_id, room_id, ...options }) => {
        const isAllFloorsFilter = floor_id?.[0]
          ? floor_id[0] === FilterSpecialValues.ALL
          : true

        const isAllAmenitiesFilter = amenity_id?.[0]
          ? amenity_id[0] === FilterSpecialValues.ALL
          : true

        const isAllRoomsFilter = room_id?.[0]
          ? room_id[0] === FilterSpecialValues.ALL
          : true

        const params = {
          ...(building_id && building_id !== FilterSpecialValues.ALL
            ? {
                building_id: building_id,
              }
            : {
                building_id: undefined,
              }),
          floor_id:
            isAllFloorsFilter || typeof floor_id === "string"
              ? undefined
              : floor_id?.join(","),
          amenity_id:
            isAllAmenitiesFilter || typeof amenity_id === "string"
              ? undefined
              : amenity_id?.join(","),
          room_id:
            isAllRoomsFilter || typeof room_id === "string"
              ? undefined
              : room_id?.join(","),
          ...options,
        }

        return {
          url: roomReservationsScheduleURL(params),
        }
      },
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ key }) => ({
                type: "RoomReservations" as const,
                id: key,
              })),
              { type: "RoomReservations", id: "LIST" },
            ]
          : [{ type: "RoomReservations", id: "LIST" }],
    }),
    fetchRoomReservation: builder.query<RoomReservationResponse, string>({
      query: (id) => ({
        url: roomReservationURL(id),
      }),
      providesTags: (_result, _error, id) => [{ type: "RoomReservations", id }],
    }),
    addRoomReservation: builder.mutation<
      RoomReservationResponse,
      RoomReservationRequest
    >({
      query: (body) => ({
        url: roomReservationsUrl(),
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "RoomReservations", id: "LIST" }],
    }),
    updateRoomReservation: builder.mutation<
      RoomReservationResponse,
      RoomReservationEditRequest
    >({
      query: (body) => ({
        url: roomReservationURL(body.id),
        method: "PUT",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "RoomReservations", id: "LIST" },
        { type: "RoomReservations", id: id },
      ],
    }),
    patchRoomReservation: builder.mutation<
      RoomReservationResponse,
      Partial<RoomReservationEditRequest> & { id: string }
    >({
      query: ({ id, ...body }) => ({
        url: roomReservationURL(id),
        method: "PATCH",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "RoomReservations", id: "LIST" },
        { type: "RoomReservations", id },
      ],
    }),
    deleteRoomReservation: builder.mutation<void, string>({
      query: (id) => ({
        url: roomReservationURL(id),
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: "RoomReservations", id: "LIST" },
        { type: "RoomReservations", id: id },
      ],
    }),
  }),
})

export const {
  useFetchRoomReservationsQuery,
  useLazyFetchRoomReservationsQuery,
  useFetchRoomReservationQuery,
  useAddRoomReservationMutation,
  useUpdateRoomReservationMutation,
  usePatchRoomReservationMutation,
  useDeleteRoomReservationMutation,
} = roomReservations
