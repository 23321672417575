import { IdAndName, ISODate } from "../../../types/sharedTypes"
import { SettingsResponse } from "../../settings/types"
import { PaginatedResponse } from "../../types"
import { BuildingResponse } from "../buildings/types"
import { FloorResponse } from "../floors/types"
import { RoomResponse } from "../rooms/types"

export type RoomCoordinates = {
  x: number
  y: number
}

export type RbRoomResponse = {
  id: number
  key: string
  name: string
  capacity: number
  building: BuildingResponse | null
  floor: FloorResponse | null
  amenities: IdAndName[]
  coords?: RoomCoordinates[]
} & SettingsResponse

export type RoomsResponse = PaginatedResponse<RbRoomResponse>

export type RoomsFetchRequest = {
  building?: string
  floor?: string
  search?: string
  limit?: number
  sync?: boolean | null
}

export type RoomUpdateRequest = {
  key?: string
  name?: string
  capacity?: number
  building_id?: string | null
  floor_id?: string | null
  amenities?: number[]
  settings?: Partial<SettingsResponse["settings"]>
  coords?: RoomCoordinates[] | null
}

export type RoomRequestWithId = RoomUpdateRequest & {
  id: number
}

export enum RoomBulkAction {
  DELETE = "DELETE",
  EDIT = "EDIT",
}

export type CheckScanRoomsResponse = {
  in_progress: boolean
  started_at: ISODate | null
}

export const isRbRoom = (
  room: RoomResponse | RbRoomResponse | undefined,
): room is RbRoomResponse => {
  return !!room && "id" in room
}

export const isIdAndNameAmenity = (
  amenity: IdAndName | number,
): amenity is IdAndName => {
  return typeof amenity === "object"
}
