import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"

import { useToast } from "../../../hooks/useToast"
import { useModals } from "@mattjennings/react-modal-stack"

import {
  deletePrinter,
  fetchDevices,
  repairTablet,
  unpairTablet,
} from "../../../redux/devices/devicesSlice"
import { selectDevices } from "../../../redux/devices/selectors"
import { DeviceType } from "../../../redux/devices/types"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"

import Button from "../../../components/advanced/Button"
import { ConfirmationModal } from "../../../components/advanced/ConfirmationModal"
import Skeleton from "../../../components/advanced/Skeleton"
import Breadcrumbs from "../../../components/Breadcrumbs"
import DeviceForm from "../../../components/Form/Visitors/DeviceForm"
import Space from "../../../components/Space"
import View from "../../../components/View"
import TabletStatus from "../../../components/Visitors/TabletStatus"

import "./DeviceDetail.sass"

type ParamsType = {
  id: string
}

const DeviceDetailSkeleton = () => {
  const pattern = [
    {
      width: "10%",
      space: 0.3,
    },
    {
      width: "100%",
      space: 2,
    },
    {
      width: "100%",
      height: "15rem",
      space: 1,
    },
    {
      width: "30%",
      space: 0.3,
    },
    {
      width: "100%",
      space: 2,
    },
    {
      width: "30%",
      space: 0.3,
    },
    {
      width: "100%",
      space: 2,
    },
  ]

  return (
    <div className="device-detail-container-skeleton">
      <Skeleton skeletonGroup={{ lines: pattern }} />
    </div>
  )
}

const DeviceDetail = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { id } = useParams<ParamsType>()
  const { errorToast, infoToast } = useToast()
  const { openModal, closeModal } = useModals()

  const actions = useActions({
    fetchDevices: () => fetchDevices(),
    unpairTablet: (id: string) => unpairTablet(id),
    repairTablet: (id: string) => repairTablet(id),
    deletePrinter: (id: string) => deletePrinter(id),
  })

  const { entries: devices, isLoading } = useAppSelector(selectDevices)

  const device = devices.find((d) => d.id === id)

  const deviceName = device?.name ? device.name : ""

  useEffect(() => {
    if (!device) {
      actions.fetchDevices()
    }
  }, [actions, device])

  const handleRePair = async () => {
    if (device) {
      const response = await actions.repairTablet(id)

      if (repairTablet.fulfilled.match(response)) {
        infoToast(t("desktop.settings.visitors.devices.tablet_repaired"))
      } else {
        errorToast(response.error.message)
      }
    }
  }

  const handleUnpairConfirmation = () => {
    openModal(ConfirmationModal, {
      onConfirm: async () => {
        await handleUnpair()
        closeModal()
      },
    })
  }

  const handleUnpair = async () => {
    if (device) {
      if (device.type === DeviceType.TABLET) {
        const response = await actions.unpairTablet(id)

        if (unpairTablet.fulfilled.match(response)) {
          infoToast(t("desktop.settings.visitors.devices.device_deleted"))
          history.push(`/settings/visitors/devices/`)
        } else {
          errorToast(response.error.message)
        }
      } else {
        const response = await actions.deletePrinter(id)

        if (deletePrinter.fulfilled.match(response)) {
          infoToast(t("desktop.settings.visitors.devices.device_deleted"))
          history.push(`/settings/visitors/devices/`)
        } else {
          errorToast(response.error.message)
        }
      }
    }
  }

  return (
    <View className="DeviceDetail">
      <Breadcrumbs
        depth={deviceName ? 3 : 2}
        values={[
          t("desktop.settings.visitors.title"),
          t("desktop.settings.visitors.devices.title"),
          deviceName,
        ]}
        includeParamsAsPath
      />

      <Space size={0.75} />

      {isLoading ? (
        <div className="loading">
          <DeviceDetailSkeleton />
        </div>
      ) : device ? (
        <div className="device-detail-content">
          <DeviceForm device={device} />
          <div className="info-panel">
            <div className="infos">
              {device.type === DeviceType.PRINTER && (
                <div className="info">
                  <div className="info-name">
                    {t("desktop.settings.visitors.devices.ip_address")}
                  </div>
                  <div className="info-data">{device.ip_address}</div>
                </div>
              )}
              <div className="info">
                <div className="info-name">
                  {t("desktop.settings.visitors.devices.model")}
                </div>
                <div className="info-data">{device.model}</div>
              </div>
              {device.type === DeviceType.TABLET && (
                <div className="info">
                  <div className="info-name">
                    {t("desktop.settings.visitors.devices.status")}
                  </div>
                  <div className="info-data">
                    <TabletStatus tablet={device} />
                  </div>
                </div>
              )}
              {device.type === DeviceType.TABLET && (
                <div className="info">
                  <div className="info-name">
                    {t("desktop.settings.visitors.devices.visitors_version")}
                  </div>
                  <div className="info-data">{device.version}</div>
                </div>
              )}
            </div>
            <div className="actions">
              <Button
                isSmall
                variant="danger-pop"
                noConfirm
                onClick={handleUnpairConfirmation}
              >
                {t("desktop.settings.visitors.devices.unpair")}
              </Button>
              {device.type === DeviceType.TABLET && (
                <Button isSmall variant="secondary" onClick={handleRePair}>
                  {t("desktop.settings.visitors.devices.re_pair")}
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>{t("desktop.settings.visitors.devices.no_device")}</div>
      )}
    </View>
  )
}

export default DeviceDetail
