import { useState } from "react"

import cn from "classnames"
import ReactModal from "react-modal"

import {
  contentStyle,
  mobileContentStyle,
  mobileOverlayStyle,
  overlayStyle,
} from "../../../modals/modalStyles"
import { Children } from "../../../types/sharedTypes"
import Button from "../Button"

import CrossSVG from "../../../assets/images/icons/Cross.svg"

import "./style.sass"

export type ConfirmationModalProps = {
  open: boolean
  title?: Children
  hint?: Children
  cancelText?: Children
  confirmText?: Children
  onConfirm: () => Promise<void>
  onCancel: () => void
  className?: string
  isMobile?: boolean
  hideActions?: boolean
}

export const ConfirmationModal = ({
  open,
  title = "Are you sure?",
  hint = "If you wish to continue, Press Confirm.",
  cancelText = "Cancel",
  confirmText = "Confirm",
  onConfirm,
  onCancel,
  className,
  isMobile = false,
  hideActions = false,
}: ConfirmationModalProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirm = async () => {
    setIsLoading(true)
    try {
      await onConfirm()
    } finally {
      setIsLoading(false)
    }
  }

  if (!open) {
    return null
  }

  return (
    <ReactModal
      isOpen={open}
      style={{
        content: isMobile ? mobileContentStyle : contentStyle,
        overlay: isMobile ? mobileOverlayStyle : overlayStyle,
      }}
      onRequestClose={() => onCancel()}
      ariaHideApp={false}
    >
      <div
        className={cn(
          "ModalForm",
          isMobile ? "ConfirmationMenu" : "ConfirmationModal",
          className,
        )}
      >
        <div className="title">
          <h1>{title}</h1>
          <div className="close" onClick={() => onCancel()}>
            <CrossSVG />
          </div>
        </div>

        <div className="hint">{hint}</div>

        {!hideActions && (
          <div className="actions actions-edit">
            <Button variant="secondary" onClick={onCancel}>
              {cancelText}
            </Button>
            <Button
              variant="primary"
              onClick={handleConfirm}
              isLoading={isLoading}
              disabled={isLoading}
            >
              {confirmText}
            </Button>
          </div>
        )}
      </div>
    </ReactModal>
  )
}
