import React, { useMemo } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useCalculateDeskBookable } from "../../../../hooks/useCalculateDeskBookable"
import { DESK_PATHS } from "./constants"
import DeskList from "./DeskList"
import NoDeskFound from "./NoDeskFound"

import { useFetchDeskReservationsQuery } from "../../../../redux/api/deskReservations"
import {
  useFetchDeskQuery,
  useFetchDesksQuery,
} from "../../../../redux/api/desks"

import Loader from "../../../../components/basic/Loader"

import "./NearbyDesks.sass"

type NearbyDesksProps = {
  deskId: string
}

const NearbyDesks = ({ deskId }: NearbyDesksProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const date = dayjs()
  const startOfDay = date.startOf("day").toISOString()
  const endOfDay = date.endOf("day").toISOString()
  const timeslot = {
    from: startOfDay,
    to: endOfDay,
  }

  // Calculate the distance between two points
  // using Euclidean distance formula
  const calculateDistance = (
    x1: number | undefined,
    y1: number | undefined,
    x2: number | undefined,
    y2: number | undefined,
  ) => {
    if (
      x1 === undefined ||
      y1 === undefined ||
      x2 === undefined ||
      y2 === undefined
    ) {
      return Infinity
    }
    return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)
  }

  // Fetch current desk
  const { data: desk, isLoading: isDeskLoading } = useFetchDeskQuery(deskId)

  // Fetch desks on the same floor and building
  const { data: desks, isLoading: isDesksLoading } = useFetchDesksQuery(
    {
      floor: desk?.floor?.id,
      building: desk?.building?.id,
    },
    {
      skip: !desk || !desk.floor?.id || !desk.building?.id,
    },
  )

  // Fetch desk reservations on the same floor and building
  const {
    data: { results: reservations = [] } = {},
    isLoading: isReservationsLoading,
  } = useFetchDeskReservationsQuery(
    {
      start: startOfDay,
      end: endOfDay,
      building_id: desk?.building?.id,
      floor_id: desk?.floor?.id,
    },
    {
      skip: !desk || !desk.floor?.id || !desk.building?.id,
    },
  )

  // Fetch bookable desks
  const { desksBookable, isLoading: isBookableLoading } =
    useCalculateDeskBookable({
      date: startOfDay,
      timeslot,
      floorId: desk?.floor?.id,
      buildingId: desk?.building?.id,
    })

  // Filter bookable desks that are not reserved and calculate distances
  const bookableDesks = useMemo(() => {
    if (!desks || !desks.results || !desksBookable || !desk) return []

    return desks.results
      .filter(
        (nearbyDesk) =>
          desksBookable[nearbyDesk.id] && // Must be bookable
          !reservations.some((res) => res.desk.id === nearbyDesk.id) && // Not reserved
          nearbyDesk.coord_x !== undefined && // Valid X coordinate
          nearbyDesk.coord_y !== undefined, // Valid Y coordinate
      )
      .map((nearbyDesk) => ({
        ...nearbyDesk,
        distance: calculateDistance(
          desk.coord_x,
          desk.coord_y,
          nearbyDesk.coord_x,
          nearbyDesk.coord_y,
        ),
      }))
      .sort((a, b) => a.distance - b.distance) // Sort by distance
  }, [desks, desksBookable, reservations, desk])

  // Map the bookable desks to the structure expected by DeskList and limit to 3
  const bookableNearbyDesks = bookableDesks.slice(0, 3).map((desk) => ({
    id: desk.id,
    name: desk.name,
    buildingName: desk.building.name,
    floorName: desk.floor.name,
  }))

  const isLoading =
    isDeskLoading ||
    isDesksLoading ||
    isBookableLoading ||
    isReservationsLoading

  const handleDeskClick = (desk: { id: string }) => {
    history.push(DESK_PATHS.confirm, { desk_id: desk.id })
  }

  return (
    <div className="NearbyDesks">
      {isLoading && <Loader variant="fullScreen" />}

      {!isLoading && bookableNearbyDesks.length > 0 && (
        <DeskList
          desks={bookableNearbyDesks || []}
          onDeskClick={handleDeskClick}
        />
      )}

      {!isLoading && bookableNearbyDesks.length === 0 && (
        <NoDeskFound
          message={t("mobile.book.no_available_nearby_desks_found")}
        ></NoDeskFound>
      )}
    </div>
  )
}

export default NearbyDesks
