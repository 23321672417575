import { Redirect } from "react-router-dom"

import { useNavigation } from "../hooks/useNavigation"
import { BILLING_PATHS } from "../screens/Settings/Billing/constants"
import { Children } from "../types/sharedTypes"

import { useFetchPaymentsQuery } from "../redux/api/billing/payments"

const PaymentTypeGuard = ({ children }: { children: Children }) => {
  const { getNextFromCurrentUrl, createLocationString } = useNavigation()
  const { data: payments, isFetching } = useFetchPaymentsQuery()

  if (!payments?.type && !isFetching) {
    const next = getNextFromCurrentUrl()
    const redirectPath = createLocationString(
      BILLING_PATHS.overview.paymentMethod,
      { next },
    )
    return <Redirect to={redirectPath} />
  }

  return children
}

export default PaymentTypeGuard
