import { useCurrencyFormatter } from "../../../../../hooks/useCurrencyFormatter"

import { CURRENCIES } from "../../../../../redux/api/billing/constants"
import { Currency } from "../../../../../redux/api/billing/types"

import Skeleton from "../../../../../components/advanced/Skeleton"

type PriceProps = {
  price: number
  currency?: Currency
  showPrefix?: boolean
  isFetching?: boolean
}

const Price = ({
  price,
  currency = CURRENCIES.EUR,
  showPrefix = false,
  isFetching = false,
}: PriceProps) => {
  const currencyFormatter = useCurrencyFormatter(
    currency,
    showPrefix ? "always" : "auto",
  )
  return (
    <span className="Price">
      {isFetching ? (
        <Skeleton width={"3rem"} />
      ) : (
        <>{currencyFormatter.format(price)}</>
      )}
    </span>
  )
}

export default Price
