import { api, invalidateOn } from ".."
import {
  meEmailPortalURL,
  mePasswordPortalURL,
  mePortalURL,
  meURL,
} from "../../../api"
import { setCompany } from "../../app/appSlice"
import { setUser } from "../../user/userSlice"
import {
  getLegacyOfficeManagerEnabled,
  legacyTransformUserGroups,
  transformUserGroups,
} from "../../user/utils"
import { UserRequest } from "../users/types"
import {
  MeResponse,
  UpdateEmailRequest,
  UpdateEmailResponse,
  UpdatePasswordRequest,
  UpdatePasswordResponse,
} from "./types"

export const me = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchMe: builder.query<MeResponse, void>({
      query: () => ({
        url: meURL(),
      }),
      providesTags: ["Me"],
      transformResponse: (response: MeResponse) => ({
        ...response,
        groups: transformUserGroups(response.groups),
      }),
      // update user state so we don't make two calls to the API
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          const result = await queryFulfilled
          dispatch(setUser(result.data))
          if (result.data.companies?.length > 0) {
            dispatch(setCompany(result.data.companies[0].id))
          } else if ((result.data as any).company?.id) {
            dispatch(setCompany((result.data as any).company.id))
          }
        } catch (e) {
          console.error(e)
        }
      },
    }),

    fetchMeWithNotificationSettings: builder.query<MeResponse, void>({
      query: () => ({
        url: mePortalURL(),
      }),
      providesTags: ["Me"],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          const result = await queryFulfilled
          dispatch(setUser(result.data))
          if (result.data.companies?.length > 0) {
            dispatch(setCompany(result.data.companies[0].id))
          } else if ((result.data as any).company?.id) {
            dispatch(setCompany((result.data as any).company.id))
          }
        } catch (e) {
          console.error(e)
        }
      },
    }),

    updateMe: builder.mutation<MeResponse, Partial<UserRequest>>({
      query: (payload) => {
        const body = getLegacyOfficeManagerEnabled()
          ? {
              ...payload,
              ...(payload.groups && {
                groups: legacyTransformUserGroups(payload.groups),
              }),
            }
          : payload
        return {
          url: mePortalURL(),
          method: "PUT",
          body,
        }
      },
      invalidatesTags: invalidateOn({ success: ["Me"] }),
    }),

    updateEmail: builder.mutation<UpdateEmailResponse, UpdateEmailRequest>({
      query: ({ newEmail, confirmation }) => ({
        url: meEmailPortalURL(),
        method: "PUT",
        body: {
          new: newEmail,
          confirmation,
        },
      }),
      invalidatesTags: invalidateOn({ success: ["Me"] }),
      transformResponse: (response: UpdateEmailResponse, _, arg) => {
        return {
          ...response,
          newEmail: response.new,
        }
      },
    }),

    updatePassword: builder.mutation<
      UpdatePasswordResponse,
      UpdatePasswordRequest
    >({
      query: ({ newPassword, confirmation }) => ({
        url: mePasswordPortalURL(),
        method: "PUT",
        body: {
          new: newPassword,
          confirmation,
        },
      }),
      invalidatesTags: invalidateOn({ success: ["Me"] }),
    }),
  }),
})

export const {
  useFetchMeQuery,
  useLazyFetchMeQuery,
  useFetchMeWithNotificationSettingsQuery,
  useLazyFetchMeWithNotificationSettingsQuery,
  useUpdateMeMutation,
  useUpdateEmailMutation,
  useUpdatePasswordMutation,
} = me
