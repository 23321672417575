import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { FEATURE_FLAGS } from "../../../../constants"
import { useCheckForFeatureFlag } from "../../../../hooks/useCheckForFeatureFlag"
import { ROOMS_PATHS } from "../constants"
import EditRoomForm from "./EditRoomForm"
import { skipToken } from "@reduxjs/toolkit/query"

import { useFetchRoomRbQuery } from "../../../../redux/api/roomBookingRooms"
import { useFetchRoomQuery } from "../../../../redux/api/rooms"

import Skeleton from "../../../../components/advanced/Skeleton"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import NoDataFound from "../../../../components/NoDataFound"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./EditRoom.sass"

type ParamsType = {
  key: string
}

const EditRoomSkeleton = () => {
  const pattern = [
    {
      width: "30%",
    },
    {
      width: "100%",
      space: 1,
    },
  ]

  const lines = new Array(2).fill(pattern).flat()

  return <Skeleton skeletonGroup={{ lines }} />
}

const EditRoom = () => {
  const { t } = useTranslation()

  const { key } = useParams<ParamsType>()

  const isRoomBookingEnabledFlag = useCheckForFeatureFlag(
    FEATURE_FLAGS.ROOM_BOOKING,
  )

  const { data: room, isLoading: isRoomLoading } = useFetchRoomQuery(
    isRoomBookingEnabledFlag || !key ? skipToken : decodeURIComponent(key),
  )

  const { data: roomRb, isLoading: isRoomRbLoading } = useFetchRoomRbQuery(
    isRoomBookingEnabledFlag && key ? key : skipToken,
  )

  const roomsNotFound = !room?.key && !roomRb?.id && key

  const isLoading = isRoomLoading || isRoomRbLoading

  return (
    <View className="EditSettingsRooms">
      <Breadcrumbs
        depth={3}
        urls={[ROOMS_PATHS.overview, ROOMS_PATHS.rooms]}
        values={[
          t("desktop.settings.rooms.title"),
          t("desktop.settings.rooms.rooms.title"),
          key
            ? t("desktop.settings.rooms.rooms.form.edit_room_title")
            : t("desktop.settings.rooms.rooms.form.add_room_title"),
        ]}
      />
      <Intro isConstrained>{t("desktop.settings.rooms.rooms.intro")}</Intro>
      <Space size={0.75} />

      <div className="rooms-container">
        {isLoading ? (
          <EditRoomSkeleton />
        ) : roomsNotFound ? (
          <NoDataFound />
        ) : (
          <EditRoomForm room={isRoomBookingEnabledFlag ? roomRb : room} />
        )}
      </div>
    </View>
  )
}

export default EditRoom
