import React from "react"

import { useTranslation } from "react-i18next"

import EmailForm from "./EmailForm"

import { useFetchMeQuery } from "../../../../redux/api/me"

import Skeleton from "../../../../components/advanced/Skeleton"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

const EmailSkeleton = () => {
  const pattern = [
    {
      width: "30%",
      space: 0.3,
    },
    {
      width: "100%",
      space: 1,
    },
  ]

  const lines = new Array(4).fill(pattern).flat()

  return <Skeleton skeletonGroup={{ lines }} />
}

const Email = () => {
  const { t } = useTranslation()

  const { data: user, isLoading } = useFetchMeQuery()

  return (
    <View className="Email">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.profile.general.title"),
          t("desktop.settings.profile.email.title"),
        ]}
      />

      <Intro isConstrained>{t("desktop.settings.profile.email.intro")}</Intro>

      <Space size={0.75} />

      {isLoading ? <EmailSkeleton /> : <EmailForm email={user?.email} />}
    </View>
  )
}

export default Email
