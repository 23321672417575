import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { Link, useHistory, useRouteMatch } from "react-router-dom"

import { FEATURE_FLAGS, PERMISSIONS } from "../../../constants"
import { timeZone as defaultTimezone, timeZone } from "../../../dayjs"
import { useCalculateDeskBookable } from "../../../hooks/useCalculateDeskBookable"
import { useCheckForFeatureFlag } from "../../../hooks/useCheckForFeatureFlag"
import { useCheckForPermission } from "../../../hooks/useCheckForPermission"
import useCheckReservationWindowLength from "../../../hooks/useCheckReservationWindowLength"
import { useStoredFilter } from "../../../hooks/useStoredFilter"
import DeskBookingModal from "../../../modals/DeskBookingModal"
import { parseQueryWithDefault, updateHistory } from "../../../utils"
import { BUILDINGS_PATHS } from "../../Settings/Buildings/constants"
import FloorPlanFilters, { Filter } from "./Filters"
import {
  FLOOR_PLAN_SCHEDULE_PATHNAME,
  useFloorPlanContext,
} from "./FloorPlanProvider"
import Header from "./Header"
import SpotList from "./SpotList"
import { DeskWithReservations, RoomWithReservations, SpotTypes } from "./types"
import {
  getAmenities,
  getCapacity,
  getCoordinates,
  getDepartments,
  getSpotVisibility,
  getUserInfo,
  normalizeSpotId,
} from "./utils"
import { useModals } from "@mattjennings/react-modal-stack"
import { skipToken } from "@reduxjs/toolkit/dist/query"

import { useFetchBuildingsQuery } from "../../../redux/api/buildings"
import { CalendarType } from "../../../redux/api/calendars/types"
import { useLazyFetchDesksQuery } from "../../../redux/api/desks"
import {
  useFetchFloorQuery,
  useFetchFloorsQuery,
} from "../../../redux/api/floors"
import { useLazyFetchRoomReservationsQuery } from "../../../redux/api/roomReservations"
import { selectAppDates } from "../../../redux/app/selectors"
import { fetchDesksSchedule } from "../../../redux/desk_schedule/deskScheduleSlice"
import { selectDeskSchedule } from "../../../redux/desk_schedule/selectors"
import { useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { isOfficeManager, isPortalAdmin } from "../../../redux/user/utils"
import { useActions } from "../../../redux/utils"

import Card from "../../../components/basic/Card"
import Loader from "../../../components/basic/Loader"
import { FilterSpecialValues } from "../../../components/Filter/types"
import { createScheduleOption } from "../../../components/Form/options"
import Map from "../../../components/Map"
import NoDataFound from "../../../components/NoDataFound"
import OccupancyInfo from "../../../components/OccupancyInfo"
// import { OccupancyWarning } from "../../../components/OccupancyWarning"
import Place from "../../../components/Place"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./style.sass"

export const ENTRIES_PER_PAGE = 1000
export const FILTER_LOCAL_STORE_NAME = "manage-floor-plan_filters"

type Params = {
  id?: string
}

type Props = {
  add?: boolean
}

const MissingValueCard = ({
  type,
}: {
  type: "building" | "floor" | "floorPlan"
}) => {
  const { t } = useTranslation()

  const canAddFloorPlans = useCheckForPermission(
    PERMISSIONS.floorPlans.canAddFloorPlan,
  )
  const canAddFloor = useCheckForPermission(PERMISSIONS.floorPlans.canAddFloor)
  const canAddBuilding = useCheckForPermission(
    PERMISSIONS.buildings.canAddBuilding,
  )

  const noPermissionsText = t("desktop.manage.floor_plan.no_permissions")

  let permission, title, linkText, pathDescriptor, path

  switch (type) {
    case "building":
      permission = canAddBuilding
      title = t("desktop.manage.floor_plan.no_buildings_configured")
      linkText = t("desktop.manage.floor_plan.add_new_buildings")
      pathDescriptor = t("desktop.manage.floor_plan.add_new_buildings_link")
      path = BUILDINGS_PATHS.add
      break
    case "floor":
      permission = canAddFloor
      title = t("desktop.manage.floor_plan.no_floors_configured")
      linkText = t("desktop.manage.floor_plan.add_new_floors")
      pathDescriptor = t("desktop.manage.floor_plan.add_new_floors_link")
      path = "/settings/floor-plans"
      break
    default:
      permission = canAddFloorPlans
      title = t("desktop.manage.floor_plan.no_floor_plan_configured")
      linkText = t("desktop.manage.floor_plan.add_new_floor_plan")
      pathDescriptor = t("desktop.manage.floor_plan.add_new_floor_plan_link")
      path = "/settings/floor-plans"
      break
  }

  return (
    <NoDataFound warning className="floor-plan-missing">
      <p className="error">
        {title}
        <br />
        {permission ? (
          <>
            {linkText}{" "}
            <Link
              to={{
                pathname: path,
              }}
            >
              {pathDescriptor}
            </Link>
            .
          </>
        ) : (
          <>{noPermissionsText}</>
        )}
      </p>
    </NoDataFound>
  )
}

/**
 * Component: ManageFloorPlan
 */
const ManageFloorPlan = ({ add = false }: Props) => {
  const isRoomBookingEnabledFlag = useCheckForFeatureFlag(
    FEATURE_FLAGS.ROOM_BOOKING,
  )

  const { currentDate } = useAppSelector(selectAppDates)
  const currentDateRef = useRef(currentDate.toISOString())
  const { schedule: deskSchedule, isLoading: isDeskScheduleLoading } =
    useAppSelector(selectDeskSchedule)
  const { entry: currentUser } = useAppSelector(selectUser)
  const {
    entry: { building },
  } = useAppSelector(selectUser)

  const [
    fetchDesks,
    {
      data: { results: desks = [] } = {},
      isFetching: isDesksFetching = false,
    } = {},
  ] = useLazyFetchDesksQuery()

  const [
    fetchRoomsSchedule,
    {
      data: { results: roomsSchedule = [] } = {},
      isFetching: areRoomsScheduleFetching,
    },
  ] = useLazyFetchRoomReservationsQuery()

  const desk_reservation_window_length = useCheckReservationWindowLength({
    globalSettings: true,
  })

  const today = dayjs().format("YYYY-MM-DD")
  const lastDay = dayjs(today)
    .add(useCheckReservationWindowLength() ?? 7, "day")
    .endOf("day")

  const isAdminManager =
    isOfficeManager(currentUser) || isPortalAdmin(currentUser)
  const canBookSpots = isAdminManager || currentDate < lastDay

  const areDesksAndScheduleLoading = isDesksFetching && isDeskScheduleLoading
  const areRoomsAndScheduleLoading = areRoomsScheduleFetching

  const defaultType = isRoomBookingEnabledFlag
    ? FilterSpecialValues.ALL
    : SpotTypes.Desk

  const defaultFilter: Filter = {
    building: building ? building.id : FilterSpecialValues.ALL,
    floor: FilterSpecialValues.ALL,
    type: defaultType,

    department_id: FilterSpecialValues.ALL,
    desk_amenity_id: [FilterSpecialValues.ALL],

    room_amenity_id: [FilterSpecialValues.ALL],
    room_id: [FilterSpecialValues.ALL],
  }

  const [storedFilterValues, saveFilter] = useStoredFilter({
    filterName: FILTER_LOCAL_STORE_NAME,
    defaultFilterValues: defaultFilter,
  })

  if (typeof storedFilterValues.desk_amenity_id === "string") {
    storedFilterValues.desk_amenity_id = [storedFilterValues.desk_amenity_id]
  }

  if (typeof storedFilterValues.room_amenity_id === "string") {
    storedFilterValues.room_amenity_id = [storedFilterValues.room_amenity_id]
  }

  const reqParams = useRef<Filter>(storedFilterValues)
  const [isPanningDisabled, setPanningDisabled] = useState(false)
  const [filters, setFilters] = useState<Filter>(storedFilterValues)
  const [users, setUsers] = useState<string[]>([])

  const { isDesksSelected, isRoomsSelected, isAllSelected } = useMemo(
    () => getSpotVisibility(filters.type, isRoomBookingEnabledFlag),
    [filters.type, isRoomBookingEnabledFlag],
  )

  const { data: floor = null, isLoading: isFloorLoading } = useFetchFloorQuery(
    filters.floor !== FilterSpecialValues.ALL
      ? {
          id: filters.floor,
          stats: true,
        }
      : skipToken,
  )

  const hasCapacityLimit = floor?.capacity_limit || floor?.capacity_limit === 0
  const mainFloorPlanContentClasses = classNames("main-floorplan-content", {
    "capacity-limit": hasCapacityLimit,
  })

  const {
    clearHoveredSpot,
    setHoveredSpot,
    hoveredSpot,
    setOpenedSpot,
    createNewDeskReservation,
    createNewRoomReservation,
    clearOpenedSpot,
    openedSpot,
  } = useFloorPlanContext()
  /**
   * Hooks
   */
  const {
    params: { id },
  } = useRouteMatch<Params>()
  const { openModal, closeAllModals } = useModals()
  const history = useHistory()
  const { search, pathname } = history.location

  const desksWithReservations: DeskWithReservations[] = useMemo(() => {
    return desks
      .map((d) => {
        const reservations =
          deskSchedule.find((s) => s.id === d.id)?.schedule[0]?.reservations ??
          []
        return { ...d, reservations, type: SpotTypes.Desk }
      })
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
  }, [desks, deskSchedule])

  const roomsWithReservations: RoomWithReservations[] = useMemo(() => {
    return roomsSchedule
      .filter((r) => r?.coords && r?.coords?.length > 0)
      .map((r) => {
        const reservations =
          roomsSchedule.find((s) => s.id === r.id)?.schedule[0]?.reservations ??
          []
        return {
          id: r.id,
          key: r.key,
          name: r.name,
          company: r.company,
          calendar: r.calendar,
          amenities: r.amenities,
          capacity: r.capacity,
          coords: r.coords,
          reservations,
          type: SpotTypes.Room,
        }
      })
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
  }, [roomsSchedule])

  const { desksBookable } = useCalculateDeskBookable({
    date: currentDate.toISOString(),
    buildingId: filters.building,
    floorId: filters.floor,
    departmentId:
      filters.type === SpotTypes.Desk
        ? filters.department_id
        : FilterSpecialValues.ALL,
  })

  /**
   * This allows the user to search for different users and highlight their reservations.
   */
  const spotsWithReservationsHighlighted = useMemo(() => {
    let spots: (DeskWithReservations | RoomWithReservations)[] = []

    if (isDesksSelected || isAllSelected) {
      spots = [...spots, ...desksWithReservations]
    }

    if (isRoomsSelected || isAllSelected) {
      spots = [...spots, ...roomsWithReservations]
    }

    return spots
      .map((spot) => {
        let isDisabled = true

        if (spot.type === SpotTypes.Desk) {
          isDisabled = !desksBookable[spot.id]
        }

        if (spot.type === SpotTypes.Room) {
          isDisabled = false // No need to calculate since the booking of rooms has no restrictions, rooms cannot be inactive, etc.
        }

        if ((users?.length ?? 0) < 1) {
          return { ...spot, isDisabled }
        }

        const wasMadeByUser = spot.reservations.find((reservation) =>
          users.some((user) => getUserInfo(reservation)?.email === user),
        )

        const user = wasMadeByUser ? getUserInfo(wasMadeByUser) : undefined

        if (wasMadeByUser) {
          return {
            ...spot,
            highlight: true,
            isDisabled,
            userEmail: user?.email,
          }
        }

        return { ...spot, isDisabled }
      })
      .sort((a, b) => a?.userEmail?.localeCompare(b.userEmail ?? "") ?? -1)
      .sort((a, b) => (a.highlight ? -1 : b.highlight ? 1 : 0))
  }, [
    desksWithReservations,
    roomsWithReservations,
    users,
    desksBookable,
    isDesksSelected,
    isRoomsSelected,
    isAllSelected,
  ])

  const actions = useActions({
    fetchDesksSchedule: (
      { building, floor, department_id, desk_amenity_id }: Partial<Filter>,
      date: Dayjs,
    ) =>
      fetchDesksSchedule({
        building_id: building,
        floor_id: floor,
        department_id,
        amenity_id: desk_amenity_id,
        start: date.startOf("day").toISOString(),
        end: date.endOf("day").toISOString(),
        limit: ENTRIES_PER_PAGE,
      }),
  })

  /**
   * Handlers
   */
  const handleFilterChange = useCallback(
    async (filter: Filter) => {
      reqParams.current = filter
      updateHistory(pathname, filter)

      const { building, floor } = filter

      if (!building || !floor || floor === FilterSpecialValues.ALL) {
        setFilters(filter)
        return
      }

      const { isAllSelected, isDesksSelected, isRoomsSelected } =
        getSpotVisibility(filter.type, isRoomBookingEnabledFlag)

      const commonDeskParams = {
        building: filter.building,
        floor: filter.floor,
      }

      const commonRoomScheduleParams = {
        building_id: filter.building,
        floor_id: [filter.floor],
        start: currentDate.startOf("day").toISOString(),
        end: currentDate.endOf("day").toISOString(),
        tz: defaultTimezone,
      }

      if (isAllSelected) {
        fetchDesks(commonDeskParams)
        actions.fetchDesksSchedule(commonDeskParams, currentDate)

        fetchRoomsSchedule(commonRoomScheduleParams)
      }

      if (isDesksSelected) {
        fetchDesks({
          ...commonDeskParams,
          department_id: filter.department_id,
          amenity_id: filter.desk_amenity_id,
          limit: ENTRIES_PER_PAGE,
        })

        actions.fetchDesksSchedule(
          {
            ...commonDeskParams,
            department_id: filter.department_id,
            desk_amenity_id: filter.desk_amenity_id,
          },
          currentDate,
        )
      }

      if (isRoomsSelected) {
        fetchRoomsSchedule({
          ...commonRoomScheduleParams,
          amenity_id: filter.room_amenity_id,
          room_id: filter.room_id,
        })
      }

      setFilters(filter)
    },
    [
      actions,
      currentDate,
      fetchDesks,
      fetchRoomsSchedule,
      pathname,
      isRoomBookingEnabledFlag,
    ],
  )

  const handleMouseOver = (id: string) => {
    setHoveredSpot(id)
    setPanningDisabled(true)
  }

  const handleMouseOut = () => {
    clearHoveredSpot()
    setPanningDisabled(false)
  }

  const handleDeskClick = (desk: DeskWithReservations) => {
    setOpenedSpot(desk.id, true)

    if (!desk.isDisabled && !desk.reservations.length && canBookSpots) {
      createNewDeskReservation(desk)
    }
  }

  const handleRoomClick = (room: RoomWithReservations) => {
    setOpenedSpot(normalizeSpotId(room.id), true)

    if (!room.isDisabled && !room.reservations.length && canBookSpots) {
      createNewRoomReservation(room)
    }
  }

  const handleModalClose = () => {
    history.push(FLOOR_PLAN_SCHEDULE_PATHNAME)
  }

  /**
   * useEffects
   */

  /**
   * Main routing logic for create and edit modal views.
   **/
  useEffect(() => {
    if (add) {
      const queryFormData = parseQueryWithDefault(search, {
        start: "",
        end: "",
        date: currentDate.toString(),
        schedule: "Once",
        desk: "",
        deskName: "",
        scheduleDate: dayjs()
          .add(desk_reservation_window_length ?? 7, "day")
          .toString(),
        tz: timeZone,
      })

      openModal(DeskBookingModal, {
        formData: {
          ...queryFormData,
          date: dayjs(queryFormData.date),
          schedule: createScheduleOption(
            queryFormData.schedule,
            queryFormData.date,
          ),
          scheduleDate: dayjs(queryFormData.scheduleDate),
        },
        onClose: handleModalClose,
      })
    } else if (id) {
      openModal(DeskBookingModal, {
        reservationId: id,
        onClose: handleModalClose,
      })
    } else {
      updateHistory(pathname, reqParams.current)

      const commonDeskScheduleParams = {
        building: reqParams.current.building,
        floor: reqParams.current.floor,
      }

      const commonRoomScheduleParams = {
        building_id: reqParams.current.building,
        floor_id: [reqParams.current.floor],
        start: currentDate.startOf("day").toISOString(),
        end: currentDate.endOf("day").toISOString(),
        tz: defaultTimezone,
      }

      if (isAllSelected) {
        actions.fetchDesksSchedule(commonDeskScheduleParams, currentDate)

        fetchRoomsSchedule(commonRoomScheduleParams)
      }

      if (isDesksSelected) {
        actions.fetchDesksSchedule(
          {
            ...commonDeskScheduleParams,
            department_id: reqParams.current.department_id,
            desk_amenity_id: reqParams.current.desk_amenity_id,
          },
          currentDate,
        )
      }

      if (isRoomsSelected) {
        fetchRoomsSchedule({
          ...commonRoomScheduleParams,
          room_id: reqParams.current.room_id,
          amenity_id: reqParams.current.room_amenity_id,
        })
      }
      closeAllModals()
    }
  }, [
    add,
    id,
    search,
    currentDate,
    isDesksSelected,
    isRoomsSelected,
    isAllSelected,
  ])

  // Saves the current params into the locale store
  useEffect(
    () => () => {
      if (pathname.endsWith(FLOOR_PLAN_SCHEDULE_PATHNAME)) {
        saveFilter({
          ...reqParams.current,
          type: isRoomBookingEnabledFlag
            ? reqParams.current.type
            : SpotTypes.Desk,
        })
      }
    },
    [saveFilter, pathname],
  )

  useEffect(() => {
    clearOpenedSpot()
  }, [filters, currentDate])

  useEffect(() => {
    const date = currentDate.toISOString()

    if (currentDateRef.current !== date) {
      const commonDeskScheduleParams = {
        building: reqParams.current.building,
        floor: reqParams.current.floor,
      }

      const commonRoomScheduleParams = {
        building_id: reqParams.current.building,
        floor_id: [reqParams.current.floor],
        start: currentDate.startOf("day").toISOString(),
        end: currentDate.endOf("day").toISOString(),
        tz: defaultTimezone,
      }

      if (isAllSelected) {
        actions.fetchDesksSchedule(commonDeskScheduleParams, currentDate)

        fetchRoomsSchedule(commonRoomScheduleParams)
      }

      if (isDesksSelected) {
        actions.fetchDesksSchedule(
          {
            ...commonDeskScheduleParams,
            department_id: reqParams.current.department_id,
            desk_amenity_id: reqParams.current.desk_amenity_id,
          },
          currentDate,
        )
      }

      if (isRoomsSelected) {
        fetchRoomsSchedule({
          ...commonRoomScheduleParams,
          amenity_id: reqParams.current.room_amenity_id,
          room_id: reqParams.current.room_id,
        })
      }

      currentDateRef.current = date
    }
  }, [actions, currentDate, isDesksSelected, isRoomsSelected, isAllSelected])

  const {
    data: { results: buildings = [] } = {},
    isLoading: areBuildingsLoading,
  } = useFetchBuildingsQuery()

  const { data: { results: entries = [] } = {}, isLoading: areFloorsLoading } =
    useFetchFloorsQuery({
      building: filters.building ? filters.building : null,
    })

  const hasNoBuildings = buildings.length === 0
  const hasNoFloors = !hasNoBuildings && entries.length === 0
  const hasFloorImage = !hasNoFloors && floor && floor.image
  const hasNoFloorImage = !hasNoFloors && floor && !floor.image
  const showCapacityLimit = hasCapacityLimit && floor

  const floorPlanClasses = classNames("floor-plan", {
    "no-spot-list": !spotsWithReservationsHighlighted.length,
  })

  const HeaderWithFloorPlanFilters = (
    <>
      <Header />
      <FloorPlanFilters
        onChange={handleFilterChange}
        onUsersChange={setUsers}
        defaultValues={storedFilterValues}
        hasSpots={spotsWithReservationsHighlighted.length > 0}
      />
    </>
  )

  if (
    isFloorLoading ||
    areFloorsLoading ||
    areBuildingsLoading ||
    areDesksAndScheduleLoading ||
    areRoomsAndScheduleLoading
  ) {
    return (
      <View className="ManageFloorPlan">
        {HeaderWithFloorPlanFilters}

        <Loader className="loader" />
      </View>
    )
  }

  return (
    <View className="ManageFloorPlan">
      {HeaderWithFloorPlanFilters}

      {showCapacityLimit && (
        <>
          <Space size={0.75} />
          <OccupancyInfo type="Floor" floor={floor} />
        </>
      )}

      {hasNoBuildings && <MissingValueCard type="building" />}

      {hasNoFloors && <MissingValueCard type="floor" />}

      {hasNoFloorImage && <MissingValueCard type="floorPlan" />}

      {hasFloorImage && (
        <div className={mainFloorPlanContentClasses}>
          <Card isFloorPlan className={floorPlanClasses}>
            <Map
              map={floor}
              showZoomControls
              showPanControls
              isDisabled={isPanningDisabled}
              key={`building-${filters.building}-floor-{${filters.floor}}`}
            >
              {(!areDesksAndScheduleLoading || !areRoomsAndScheduleLoading) &&
                spotsWithReservationsHighlighted.map((spot, i: number) => {
                  const {
                    id,
                    name,
                    type,
                    isDisabled,
                    highlight,
                    reservations,
                  } = spot

                  const isDesk = type === SpotTypes.Desk
                  const isRoom = type === SpotTypes.Room

                  const spotId = normalizeSpotId(id)

                  const { x: coordX, y: coordY } = getCoordinates(spot)
                  const departments = getDepartments(spot)
                  const amenities = getAmenities(spot)
                  const capacity = getCapacity(spot)

                  const isAvailable = (reservations?.length ?? 0) === 0
                  const isOccupied =
                    !isDisabled && (reservations?.length ?? 0) > 0

                  const onClick = () => {
                    if (isDesk) handleDeskClick(spot)
                    if (isRoom) handleRoomClick(spot)
                  }

                  return (
                    <Place
                      key={`spot-${spotId}`}
                      x={coordX}
                      y={coordY}
                      mapWidth={floor.width!}
                      mapHeight={floor.height!}
                      title={name}
                      amenities={amenities}
                      departments={departments}
                      capacity={capacity}
                      reservations={reservations}
                      isAvailable={isAvailable}
                      isOccupied={isOccupied}
                      isDisabled={isDisabled}
                      isHovered={spotId === hoveredSpot}
                      isFocused={spotId === openedSpot || highlight}
                      isHighlighted={highlight}
                      type={type}
                      onClick={onClick}
                      onMouseOver={() => handleMouseOver(spotId)}
                      onMouseOut={handleMouseOut}
                      isTooltipClickable
                    />
                  )
                })}
            </Map>
          </Card>

          {(!areDesksAndScheduleLoading || !areRoomsAndScheduleLoading) &&
            spotsWithReservationsHighlighted.length > 0 && (
              <Card isFloorPlan className="user-list">
                <SpotList
                  canBookSpots={canBookSpots}
                  spotsWithReservations={spotsWithReservationsHighlighted}
                />
              </Card>
            )}
          {/* we will reimplement this when we have a BE ready
						{floor && (
							<OccupancyWarning
								id={filters.floor}
								company={company}
								day={currentDate}
							/>
						)} */}
        </div>
      )}
    </View>
  )
}

export default ManageFloorPlan
