import { useTranslation } from "react-i18next"

import InvoiceList from "./Invoices/InvoiceList"
import Payments from "./Payments"
import Plans from "./Plans"

import { useFetchInvoicesQuery } from "../../../redux/api/billing/invoices"

import Skeleton from "../../../components/advanced/Skeleton"
import Breadcrumbs from "../../../components/Breadcrumbs"
import Space from "../../../components/Space"
import View from "../../../components/View"

const Overview = () => {
  const { t } = useTranslation()
  const { data: invoices, isSuccess: areInvoicesLoaded } =
    useFetchInvoicesQuery({ limit: 5 })

  return (
    <View className="Overview">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.billing.title"),
          t("desktop.settings.billing.overview.title"),
        ]}
      />

      <Space size={0.75} />

      <Payments />

      <Space size={1.5} />

      <Plans />

      <Space size={1.5} />

      {!areInvoicesLoaded && <Skeleton height={"20rem"} />}

      {areInvoicesLoaded && invoices?.results?.length > 0 && (
        <InvoiceList invoices={invoices.results} />
      )}
    </View>
  )
}

export default Overview
