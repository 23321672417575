import React, { useCallback, useMemo, useRef, useState } from "react"

import { useTranslation } from "react-i18next"

import { usersURL } from "../../../api"
import { FEATURE_FLAGS } from "../../../constants"
import { useCheckForFeatureFlag } from "../../../hooks/useCheckForFeatureFlag"
import { SpotType, SpotTypes } from "./types"
import { getSpotVisibility } from "./utils"

import { useFetchBuildingsQuery } from "../../../redux/api/buildings"
import { formatUser } from "../../../redux/user/utils"
import { UserResponse } from "../../../redux/users/types"

import BuildingFilter from "../../../components/Filter/BuildingFilter"
import DepartmentFilter from "../../../components/Filter/DepartmentFilter"
import FiltersBar from "../../../components/Filter/FiltersBar"
import FloorFilter from "../../../components/Filter/FloorFilter"
import MultiAmenitiesFilter from "../../../components/Filter/MultiAmenitiesFilter"
import { MultipleAsyncSelector } from "../../../components/Filter/MultipleAsyncSelector"
import MultiRoomAmenitiesFilter from "../../../components/Filter/MultiRoomAmenitiesFilter"
import MultiRoomFilter from "../../../components/Filter/MultiRoomFilter"
import ResourceTypeFilter from "../../../components/Filter/ResourceTypeFilter"
import { FilterSpecialValues } from "../../../components/Filter/types"

export type Filter = {
  type: SpotType | FilterSpecialValues.ALL
  building: string
  floor: string
  department_id: string
  desk_amenity_id: string[]
  room_amenity_id: string[]
  room_id: string[]
}

type FilterKeys = keyof Filter

type Props = {
  onChange: (filter: Filter) => Promise<void>
  onUsersChange: (users: string[]) => void
  defaultValues: Filter
  hasSpots: boolean
}

const FloorPlanFilters = ({
  onChange,
  defaultValues,
  onUsersChange,
  hasSpots,
}: Props) => {
  const isRoomBookingEnabledFlag = useCheckForFeatureFlag(
    FEATURE_FLAGS.ROOM_BOOKING,
  )

  const floorRef = useRef<string>(defaultValues.floor)
  const filtersRef = useRef<Filter>(defaultValues)
  const [filters, setFilters] = useState<Filter>(defaultValues)
  const [users, setUsers] = useState<UserResponse[]>([])

  const { isDesksSelected, isRoomsSelected, isAllSelected } = useMemo(
    () => getSpotVisibility(filters.type, isRoomBookingEnabledFlag),
    [filters.type, isRoomBookingEnabledFlag],
  )

  const { data: { results: buildings = [] } = {} } = useFetchBuildingsQuery()

  const { t } = useTranslation()

  const handleOnChange = useCallback(
    (filterName: FilterKeys) => (value: string | string[] | UserResponse[]) => {
      // the FloorFilter triggers unwanted onChanges this is temporary fix
      if (filterName === "floor" && floorRef.current === value) {
        return
      }

      filtersRef.current = {
        ...filtersRef.current,
        [filterName]: value,
      }
      floorRef.current = filtersRef.current.floor

      if (filterName === "building") {
        if (buildings.find((b) => b.id === value)) {
          setFilters(filtersRef.current)
          return
        } else {
          filtersRef.current = {
            ...filtersRef.current,
            floor: "",
          }
        }
      }

      onChange(filtersRef.current as Filter)
      setFilters(filtersRef.current)
    },
    [buildings, onChange],
  )

  const handleOnUserChange = (users: UserResponse[]) => {
    onUsersChange(users.map((u) => u.email))
    setUsers(users)
  }

  const deskAmenities =
    typeof filters.desk_amenity_id === "string"
      ? [filters.desk_amenity_id]
      : filters.desk_amenity_id

  const roomAmenities =
    typeof filters.room_amenity_id === "string"
      ? [filters.room_amenity_id]
      : filters.room_amenity_id

  const rooms =
    typeof filters.room_id === "string" ? [filters.room_id] : filters.room_id

  const getAmenitiesLabel = (type: SpotType, isAllSelected: boolean) => ({
    optionName: isAllSelected
      ? `desktop.manage.floor_plan.filters.${type}_amenities.multiple`
      : "desktop.manage.floor_plan.filters.amenities.multiple",
    defaultLabel: isAllSelected
      ? `desktop.manage.floor_plan.filters.${type}_amenities.all`
      : "desktop.manage.floor_plan.filters.amenities.all",
  })

  const {
    optionName: deskAmenitiesOptionName,
    defaultLabel: deskAmenitiesDefaultLabel,
  } = getAmenitiesLabel(SpotTypes.Desk, isAllSelected)

  const {
    optionName: roomAmenitiesOptionName,
    defaultLabel: roomAmenitiesDefaultLabel,
  } = getAmenitiesLabel(SpotTypes.Room, isAllSelected)

  return (
    <div className="FiltersBarWrapper">
      <FiltersBar className="FloorPlanFiltersBar">
        <ResourceTypeFilter
          value={filters.type}
          onChange={handleOnChange("type")}
        />

        <BuildingFilter
          value={filters.building ?? ""}
          onChange={handleOnChange("building")}
        />

        <FloorFilter
          value={filters.floor ?? ""}
          onChange={handleOnChange("floor")}
          buildingId={filters.building}
        />

        {isDesksSelected && (
          <DepartmentFilter
            value={filters.department_id ?? ""}
            onChange={handleOnChange("department_id")}
            showAll={true}
            showNotAssigned={true}
          />
        )}

        {isDesksSelected && (
          <MultiAmenitiesFilter
            preSelectedValues={deskAmenities}
            onChange={handleOnChange("desk_amenity_id")}
            optionsName={t(deskAmenitiesOptionName)}
            defaultLabel={t(deskAmenitiesDefaultLabel)}
          />
        )}

        {isRoomsSelected && (
          <MultiRoomAmenitiesFilter
            preSelectedValues={roomAmenities}
            onChange={handleOnChange("room_amenity_id")}
            optionsName={t(roomAmenitiesOptionName)}
            defaultLabel={t(roomAmenitiesDefaultLabel)}
          />
        )}

        {isRoomsSelected && (
          <MultiRoomFilter
            values={rooms}
            onChange={handleOnChange("room_id")}
            buildingId={filters.building}
            optionsName={t(
              "desktop.manage.room_booking.calendar.toolbar.filters.multiple_rooms",
            ).toLowerCase()}
            defaultLabel={t(
              "desktop.manage.room_booking.calendar.toolbar.filters.all_rooms",
            )}
            showAll
          />
        )}
      </FiltersBar>

      {hasSpots && (
        <FiltersBar className="UserSpotsFiltersBar">
          <MultipleAsyncSelector
            options={users}
            onChange={handleOnUserChange}
            urlGenerator={(fetchOptions) => usersURL(fetchOptions)}
            placeholder={t("general.select.user")}
            nothingFoundMessage={t("general.not_found.no_options_found")}
            getOptionLabel={(dataPoint) => formatUser(dataPoint)}
            getOptionValue={(dataPoint) => dataPoint.email}
            extraClassName="UserSelector"
          />
        </FiltersBar>
      )}
    </div>
  )
}

export default FloorPlanFilters
