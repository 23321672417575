import React, { useEffect, useState } from "react"

import dayjs from "dayjs"
import { ParseKeys } from "i18next"
import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import { useCurrencyFormatter } from "../../../../../hooks/useCurrencyFormatter"
import { useDebounce } from "../../../../../hooks/useDebounce"
import { useNavigation } from "../../../../../hooks/useNavigation"
import { useToast } from "../../../../../hooks/useToast"
import { getLabel } from "../../../../../utils"
import SolutionActivationModal from "../../../Overview/SolutionActivationModal"
import { planGroupToSolutionType } from "../../../Overview/utils"
import { BILLING_PATHS, getPlanGroupPath } from "../../constants"
import { toFriendlyName } from "../../utils"
import CurrentSubscriptionDetails from "./CurrentSubscriptionDetails"
import EstimateDetails from "./EstimateDetails"
import QuantityCard from "./QuantityCard"
import SubscriptionChangeWarnings from "./SubscriptionChangeWarnings"
import { useModals } from "@mattjennings/react-modal-stack"
import { skipToken } from "@reduxjs/toolkit/dist/query"

import { useLazyCalculateEstimateQuery } from "../../../../../redux/api/billing/estimates"
import {
  Estimate,
  EstimateRequest,
} from "../../../../../redux/api/billing/estimates/types"
import {
  useCreateSubscriptionMutation,
  useFetchSubscriptionQuery,
  useUpdateSubscriptionMutation,
} from "../../../../../redux/api/billing/subscriptions"
import { SubscriptionUnit } from "../../../../../redux/api/billing/subscriptions/types"
import {
  Currency,
  PlanType,
  SubscriptionPeriod,
} from "../../../../../redux/api/billing/types"
import {
  getPlanGroup,
  groupToTranslationKey,
} from "../../../../../redux/api/billing/utils"
import {
  useActivateSolutionMutation,
  useFetchSolutionsQuery,
} from "../../../../../redux/api/solutions"
import { isRejected } from "../../../../../redux/api/types"

import Button from "../../../../../components/advanced/Button"
import Skeleton from "../../../../../components/advanced/Skeleton"
import InputNumber from "../../../../../components/basic/InputNumber"
import Breadcrumbs from "../../../../../components/Breadcrumbs"
import Field from "../../../../../components/Field"
import PageForm from "../../../../../components/Form/PageFormHook"
import Intro from "../../../../../components/Intro"
import Space from "../../../../../components/Space"
import View from "../../../../../components/View"

import "./styles.sass"

type FormValues = {
  quantity: number
}

type QueryProps = {
  planType: PlanType
  currency: Currency
  period: SubscriptionPeriod
  id?: string
}

const SubscriptionSkeleton = () => {
  const pattern = [
    {
      width: "50%",
      space: 0.5,
    },
    {
      width: "30%",
      space: 1,
    },
    {
      width: "80%",
      space: 0.5,
    },
    {
      width: "80%",
      space: 0.5,
    },
    {
      width: "80%",
    },
  ]
  const lines = new Array(4).fill(pattern).flat()

  return <Skeleton skeletonGroup={{ lines }} />
}

const SubscriptionQuantity = () => {
  const { t } = useTranslation()
  const { infoToast, errorToast } = useToast()
  const { openModal, closeModal } = useModals()
  const { pushWithNext, pushToNextOr, searchParams } = useNavigation()
  const { planType, currency, period, id } = (searchParams || {}) as QueryProps
  const { data: subscription } = useFetchSubscriptionQuery(
    id ? Number(id) : skipToken,
  )
  const [fetchCalculateEstimate, { isFetching }] =
    useLazyCalculateEstimateQuery()
  const { data: solutions } = useFetchSolutionsQuery()
  const [createSubscription] = useCreateSubscriptionMutation()
  const [updateSubscription] = useUpdateSubscriptionMutation()
  const [activateSolution] = useActivateSolutionMutation()
  const currencyFormatter = useCurrencyFormatter(currency)

  const subscriptionQuantity = subscription?.unit[0].quantity ?? 1

  const [estimate, setEstimate] = useState<Estimate | null>(null)
  const methods = useForm<FormValues>({
    defaultValues: {
      quantity: subscriptionQuantity,
    },
  })
  const {
    control,
    getValues,
    reset,
    formState: { isDirty },
  } = methods

  const currentPlanType = subscription?.plan_type
  const currentPeriod = subscription?.plan_variation.period_unit
  const planGroup = subscription?.planGroup ?? getPlanGroup(planType)
  const solution = planGroupToSolutionType(planGroup)

  const getPlanLabel = (type?: PlanType, planPeriod?: string) =>
    type
      ? `${toFriendlyName(type)} / ${t(
          `desktop.settings.billing.subscription_quantity.${
            planPeriod === "month" ? "monthly" : "yearly"
          }`,
        )}`
      : ""

  const currentPlanLabel = getPlanLabel(currentPlanType, currentPeriod)
  const selectedPlanLabel = getPlanLabel(planType, period)

  const {
    type = "device",
    quantity: purchased = 0,
    quantity_used = 0,
  } = subscription?.unit[0] ?? ({} as SubscriptionUnit)
  const unitTranslation = t(`desktop.components.subscription_card.${type}`, {
    count: 1,
  })
  const nextBillingDate = dayjs(subscription?.next_billing_date ?? "").format(
    "l",
  )

  const fetchEstimate = async (request: EstimateRequest) => {
    const result = await fetchCalculateEstimate(request)
    if (isRejected(result)) {
      errorToast(result.error.message)
      return
    }
    if (result.data) {
      setEstimate(result.data)
    }
  }

  const handleOnQuantityChange = () => {
    fetchEstimate({
      subscription_id: subscription?.id,
      plan: planType,
      currency,
      period_unit: period,
      quantity: getValues().quantity,
    })
  }
  const debouncedFetchEstimate = useDebounce(handleOnQuantityChange, 200)

  const handleOnPlanChange = () => {
    pushWithNext(getPlanGroupPath(planGroup).planSelection, {
      planType: currentPlanType ?? planType,
      currency,
      period: currentPeriod,
      id,
    })
  }

  const handleOnSubscriptionChange = async (values: FormValues) => {
    const request = {
      currency,
      period_unit: period,
      plan: planType,
      quantity: values.quantity,
    }

    const response = id
      ? await updateSubscription({ id: Number(id), ...request })
      : await createSubscription(request)
    if (isRejected(response)) {
      errorToast(response.error.message)

      return
    }

    infoToast(
      t(
        `desktop.settings.billing.subscription_quantity.toasts.${
          id ? "update" : "create"
        }_success`,
      ),
    )
    if (!id && !solutions?.[solution].active) {
      const activationResponse = await activateSolution(solution)
      if (isRejected(activationResponse)) {
        errorToast(activationResponse.error.message)

        return
      }
    }

    pushToNextOr(BILLING_PATHS.overview.root)
  }

  const handleCancelSubscription = () => {
    openModal(SolutionActivationModal, {
      onConfirm: async () => {
        closeModal()
      },
      onCancel: closeModal,
      isActivation: false,
      isTrial: false,
      hasTrialAvailable: false,
      solution: solution,
      hasActiveSubscription: true,
    })
  }

  /**
   * Fetch estimate on mount if no subscription id is provided
   */
  useEffect(() => {
    if (!id && planType && currency && period) {
      fetchEstimate({
        plan: planType,
        currency,
        period_unit: period,
        quantity: 1,
      })
    }
  }, [planType, id, currency, period])

  /**
   * Fetch estimate if subscription plan type is different from the current plan type
   */
  useEffect(() => {
    if (
      id &&
      subscription &&
      (currentPlanType !== planType || currentPeriod !== period)
    ) {
      fetchEstimate({
        subscription_id: subscription.id,
        plan: planType,
        currency,
        period_unit: period,
        quantity: subscription.unit[0].quantity,
      })
    }
  }, [
    planType,
    id,
    currency,
    period,
    subscription,
    currentPlanType,
    currentPeriod,
  ])

  const isDisabled = id
    ? currentPlanType === planType && currentPeriod === period && !isDirty
    : false

  /**
   * Set default quantity value on subscription change
   */
  useEffect(() => {
    reset({ quantity: subscriptionQuantity })
  }, [reset, subscription?.unit])

  const BreadcrumbsAndIntro = (
    <>
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.billing.title"),
          t(groupToTranslationKey(planGroup)),
        ]}
      />

      <Intro isSubscriptionIntro>
        <Trans
          i18nKey="desktop.settings.billing.subscription_info_text"
          components={{
            decrease_licenses_form: (
              <a
                href={getLabel("links.supportFormURL")}
                target="_blank"
                rel="noopener noreferrer"
              >
                form
              </a>
            ),
            cancel_subscription_form: (
              <Button
                className="CancelSubscriptionButton"
                variant="link"
                onClick={handleCancelSubscription}
              />
            ),
          }}
        />
      </Intro>
    </>
  )

  if (
    !planType ||
    (!subscription && id) ||
    (planType !== subscription?.plan_type && !estimate)
  ) {
    return (
      <View className="SubscriptionQuantity">
        {BreadcrumbsAndIntro}
        <Skeleton className="skeleton-full" />
      </View>
    )
  }

  return (
    <View className="SubscriptionQuantity">
      {BreadcrumbsAndIntro}

      <Space size={0.75} />

      {isFetching && <SubscriptionSkeleton />}
      {!isFetching && (
        <FormProvider {...methods}>
          <PageForm
            updateMode={true}
            submitButtonText={t("general.confirm")}
            backUrl={BILLING_PATHS.overview.root}
            onUpdate={handleOnSubscriptionChange}
            disabled={isDisabled}
          >
            <QuantityCard
              changeButton={
                <Button variant="primary" onClick={handleOnPlanChange}>
                  {t("general.change")}
                </Button>
              }
              unit={unitTranslation}
              planLabel={selectedPlanLabel}
            >
              <Field control={control} name="quantity">
                {({ onChange, value, ...props }) => (
                  <InputNumber
                    {...props}
                    minValue={subscriptionQuantity}
                    value={
                      value < subscriptionQuantity
                        ? subscriptionQuantity
                        : value
                    }
                    onChange={(value) => {
                      onChange(value)
                      debouncedFetchEstimate()
                    }}
                  />
                )}
              </Field>
            </QuantityCard>
            {id && (
              <CurrentSubscriptionDetails
                period={t(`general.periods.${currentPeriod}` as ParseKeys)}
                unit={unitTranslation}
                planLabel={currentPlanLabel}
                nextBillingDate={nextBillingDate}
                purchased={purchased}
                used={quantity_used}
                cost={currencyFormatter.format(subscription?.amount ?? 0)}
              />
            )}
            {estimate && (
              <>
                <EstimateDetails
                  estimate={estimate}
                  isFetching={isFetching}
                  usedQuantity={quantity_used}
                  nextBillingDate={nextBillingDate}
                  currentPrice={subscription?.amount ?? 0}
                  currentQuantity={subscription?.unit[0]?.quantity ?? 0}
                  currentPlanType={subscription?.plan_type}
                  planType={planType}
                />

                <SubscriptionChangeWarnings
                  estimate={estimate}
                  isFetching={isFetching}
                  usedQuantity={quantity_used}
                  nextBillingDate={nextBillingDate}
                  currentPrice={subscription?.amount ?? 0}
                  currentQuantity={subscription?.unit[0]?.quantity ?? 0}
                  currentPlanType={subscription?.plan_type}
                  planType={planType}
                />
              </>
            )}
          </PageForm>
        </FormProvider>
      )}
    </View>
  )
}

export default SubscriptionQuantity
