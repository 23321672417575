import React, { useEffect, useMemo, useRef, useState } from "react"

import cn from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"

import { timeZone as defaultTimezone } from "../../../../../dayjs"
import { useHasScrollbar } from "../../../../../hooks/useHasScrollbar"
import { useNavigation } from "../../../../../hooks/useNavigation"
import { useOnClickOutside } from "../../../../../hooks/useOnClickOutside"
import { ISODate } from "../../../../../types/sharedTypes"
import { toInternalTime, userTimeFormat } from "../../../../../utils"
import { ROOM_BOOKING_PATHS } from "../../constants"
import {
  HOURS_IN_A_DAY,
  MINUTES_IN_A_DAY,
  normalizeToIncrement,
  PIXELS_PER_HOUR,
} from "../constants"
import CurrentTimeIndicator from "../CurrentTimeIndicator"
import ReservationInfoModal from "../ReservationInfoModal"
import RoomOverviewCell from "../RoomOverviewCell"
import DayViewCell from "./DayViewCell"
import ReservationCard from "./ReservationCard"
import { calculateOverlapCount } from "./utils"
import { useModals } from "@mattjennings/react-modal-stack"

import { CalendarType } from "../../../../../redux/api/calendars/types"
import {
  Reservation,
  ReservationVisibility,
  RoomReservation,
} from "../../../../../redux/api/roomReservations/types"
import { selectAppDates } from "../../../../../redux/app/selectors"
import { useAppSelector } from "../../../../../redux/reducers"
import { selectUser } from "../../../../../redux/user/selectors"
import { isOfficeManager, isPortalAdmin } from "../../../../../redux/user/utils"

import "./DayView.sass"

export type ReservationPosition = {
  top: number
  left: number
  width: number
  height: number
  zIndex: number
}

type Props = {
  roomsReservation: RoomReservation[]
}

type TempReservation = {
  roomId: number | null
  start: Dayjs
  end: Dayjs
  title: string
  position: ReservationPosition & {
    originalTop: number
    originalLeft: number
  }
}

const SHORTEST_RESERVATION_HEIGHT = PIXELS_PER_HOUR / 4
const TIMESLOT_SIDEBAR_WIDTH = 80

const DayView = ({ roomsReservation }: Props) => {
  const { push } = useNavigation()
  const { openModal, closeModal } = useModals()
  const { t } = useTranslation()
  const [offset, setOffset] = useState<number>(calculateOffset())

  const calendarLayoutContentRef = useRef<HTMLDivElement>(null)
  const calendarLayoutHeaderWrapperRef = useRef<HTMLDivElement>(null)
  const calendarLayoutHeaderRef = useRef<HTMLDivElement>(null)
  const calendarLayoutSidebarRef = useRef<HTMLDivElement>(null)

  function calculateOffset() {
    return (dayjs().minute() / 60) * PIXELS_PER_HOUR
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setOffset(calculateOffset())
    }, 60 * 1000)

    return () => clearInterval(interval)
  }, [])

  const handleScrollPosition = () => {
    const calendarLayoutContentEl = calendarLayoutContentRef.current

    if (!calendarLayoutContentEl) return

    const {
      scrollLeft,
      scrollTop,
      scrollWidth,
      scrollHeight,
      clientWidth,
      clientHeight,
    } = calendarLayoutContentEl

    setScrollbarPositionClassName(
      [
        scrollTop > 0 && "scroll-top-visible",
        scrollTop + clientHeight < scrollHeight && "scroll-bottom-visible",
        scrollLeft > 0 && "scroll-left-visible",
        scrollLeft + clientWidth < scrollWidth && "scroll-right-visible",
      ]
        .filter(Boolean)
        .join(" "),
    )
  }

  const handleScroll = () => {
    const calendarLayoutContentEl = calendarLayoutContentRef.current
    const calendarLayoutHeaderEl = calendarLayoutHeaderWrapperRef.current
    const calendarLayoutSidebarEl = calendarLayoutSidebarRef.current

    if (
      !calendarLayoutContentEl ||
      !calendarLayoutHeaderEl ||
      !calendarLayoutSidebarEl
    ) {
      return
    }

    calendarLayoutHeaderEl.scrollLeft = calendarLayoutContentEl.scrollLeft
    calendarLayoutSidebarEl.scrollTop = calendarLayoutContentEl.scrollTop
    setCurrentScrollPosition(calendarLayoutContentEl.scrollTop)
  }

  const { currentDate, showWeekends } = useAppSelector(selectAppDates)

  const [scrollbarPositionClassName, setScrollbarPositionClassName] =
    useState<string>("")

  const hasScrollbar = useHasScrollbar(calendarLayoutContentRef, [
    roomsReservation.length,
    showWeekends,
  ])

  const start = useMemo(() => {
    return currentDate
  }, [currentDate])

  const generateTimeSlots = () => {
    return Array.from({ length: HOURS_IN_A_DAY }, (_, i) => ({
      value: `${i}:00`,
      label: dayjs().hour(i).minute(0).format(userTimeFormat()),
    }))
  }

  const isEventInSlot = (eventStart: ISODate, hour: number) => {
    const startHour = dayjs(eventStart).tz(defaultTimezone).hour()

    return hour === startHour
  }

  const calculateWidthAndLeftPosition = (
    index: number,
    totalCols: number,
    cellWidth: number,
    overlapCount: number,
  ) => {
    const minWidth = 20

    let width = Math.max(minWidth, cellWidth / Math.max(1, overlapCount))

    const overlapFactor = 0.25
    width += width * overlapFactor

    width = Math.min(width, cellWidth)

    const availableWidth = cellWidth - width * overlapFactor
    const left = Math.min(
      (availableWidth / totalCols) * index,
      cellWidth - width,
    )

    return { width, left }
  }

  const calculateHeightAndTop = (
    startTime: string,
    endTime: string,
    timezone: string,
  ) => {
    const start = dayjs(startTime).tz(timezone)
    const end = dayjs(endTime).tz(timezone)
    const durationInMinutes = end.diff(start, "minute")
    return {
      height: (durationInMinutes / 60) * PIXELS_PER_HOUR,
      top: (start.minute() / 60) * PIXELS_PER_HOUR,
    }
  }

  const calculateReservationPositions = (reservations: Reservation[]) => {
    const totalCols = reservations.length
    const cellWidth = 100

    return reservations.map((reservation, index) => {
      const overlapCount = calculateOverlapCount(reservation, reservations)

      const { width, left } = calculateWidthAndLeftPosition(
        index,
        totalCols,
        cellWidth,
        overlapCount,
      )

      const { height, top } = calculateHeightAndTop(
        reservation.start,
        reservation.end,
        defaultTimezone,
      )

      return {
        reservation,
        position: {
          top,
          left,
          height,
          width,
          zIndex: index + 1,
        },
      }
    })
  }

  const timeSlots = generateTimeSlots()

  const handleReservationClick = (
    reservationId: string,
    visibility: ReservationVisibility,
    isReadOnly = false,
  ) => {
    openModal(ReservationInfoModal, {
      reservationId,
      visibility,
      isReadOnly,
      onCloseClick: closeModal,
    })
  }

  const calendarLayoutClassName = cn(
    "calendar-day-view-layout",
    {
      "has-scrollbar": hasScrollbar,
    },
    scrollbarPositionClassName,
  )

  useEffect(() => {
    const calendarLayoutContentEl = calendarLayoutContentRef.current

    if (calendarLayoutContentEl) {
      calendarLayoutContentEl.addEventListener("scroll", handleScrollPosition)
    }

    return () => {
      if (calendarLayoutContentEl) {
        calendarLayoutContentEl.removeEventListener(
          "scroll",
          handleScrollPosition,
        )
      }
    }
  }, [])

  useEffect(() => {
    handleScrollPosition()
  }, [roomsReservation])

  useEffect(() => {
    calendarLayoutContentRef.current?.classList.toggle(
      "has-scrollbar",
      hasScrollbar,
    )
  }, [hasScrollbar])

  const [temporaryReservation, setTemporaryReservation] =
    useState<TempReservation | null>(null)
  const holdTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const isDraggingRef = useRef<boolean>(false)

  useOnClickOutside([calendarLayoutContentRef], () => {
    if (temporaryReservation) {
      setTemporaryReservation(null)
    }
  })

  useEffect(() => {
    const calendarLayoutContentEl = calendarLayoutContentRef.current
    if (!calendarLayoutContentEl) {
      return
    }

    const handleMouseDown = (event: MouseEvent) => {
      if (event.button !== 0) {
        return
      }

      // Prevent dragging when clicking on a reservation or children, scrollbars or when resizing a reservation
      const classList = (event.target as HTMLElement).classList
      if (
        classList.contains("reservation") ||
        classList.contains("calendar-layout-content-wrapper") ||
        classList.contains("resizable-handle") ||
        classList.contains("time") ||
        classList.contains("name")
      ) {
        return
      }

      holdTimeoutRef.current = setTimeout(() => {
        const boundingRect = calendarLayoutContentEl.getBoundingClientRect()
        const reservationWidth =
          event.target instanceof HTMLElement ? event.target.offsetWidth : 0
        const yPos =
          event.clientY - boundingRect.top + calendarLayoutContentEl.scrollTop
        const xPos =
          event.clientX - boundingRect.left + calendarLayoutContentEl.scrollLeft

        const normalizedY = normalizeToIncrement(
          yPos,
          SHORTEST_RESERVATION_HEIGHT,
          "round",
        )

        const normalizedX = normalizeToIncrement(
          xPos,
          reservationWidth,
          "floor",
        )

        const minutes = (normalizedY / PIXELS_PER_HOUR) * 60

        const startTime = dayjs(start).add(minutes, "minute")
        const endTime = dayjs(startTime).add(30, "minute")

        const roomIndex = normalizedX / reservationWidth
        const roomReservation = roomsReservation[roomIndex]
        const roomId = roomReservation ? roomReservation.id : null

        isDraggingRef.current = true
        setTemporaryReservation({
          title: t("desktop.manage.room_booking.calendar.no_title"),
          roomId: roomId,
          start: startTime,
          end: endTime,
          position: {
            top: normalizedY - calendarLayoutContentEl.scrollTop,
            left:
              normalizedX +
              TIMESLOT_SIDEBAR_WIDTH -
              calendarLayoutContentEl.scrollLeft,
            width: reservationWidth,
            height: PIXELS_PER_HOUR / 2,
            zIndex: 50,
            originalTop: normalizedY,
            originalLeft: normalizedX + TIMESLOT_SIDEBAR_WIDTH,
          },
        })
      }, 75)
    }

    const handleMouseMove = (event: MouseEvent) => {
      if (!isDraggingRef.current || temporaryReservation === null) return

      const boundingRect = calendarLayoutContentEl.getBoundingClientRect()
      let newHeight =
        event.clientY - boundingRect.top - temporaryReservation.position.top

      newHeight = normalizeToIncrement(newHeight, SHORTEST_RESERVATION_HEIGHT)

      if (newHeight < SHORTEST_RESERVATION_HEIGHT)
        newHeight = SHORTEST_RESERVATION_HEIGHT

      setTemporaryReservation((prev) => {
        if (!prev) return null

        return {
          ...prev,
          end: prev.start.add((newHeight / PIXELS_PER_HOUR) * 60, "minute"),
          position: {
            ...prev.position,
            height: newHeight,
          },
        }
      })
    }

    const handleMouseUp = (event: MouseEvent) => {
      const relatedTarget = event.relatedTarget as HTMLElement
      if (
        relatedTarget &&
        relatedTarget.classList.contains("CurrentTimeIndicator")
      ) {
        return
      }

      if (!temporaryReservation) {
        return
      }

      if (holdTimeoutRef.current) {
        clearTimeout(holdTimeoutRef.current)
        holdTimeoutRef.current = null
      }

      if (isDraggingRef.current) {
        const { start, end, roomId } = temporaryReservation

        const queryParams = new URLSearchParams({
          roomId: roomId ? roomId.toString() : "",
          startTime: toInternalTime(start),
          endTime: toInternalTime(end),
          date: currentDate.format("YYYY-MM-DD"),
        })

        push(`${ROOM_BOOKING_PATHS.add}?${queryParams.toString()}`)

        isDraggingRef.current = false
      }
    }

    const handleReservationScroll = () => {
      if (!temporaryReservation) {
        return
      }

      setTemporaryReservation((prev) => {
        if (!prev) return null

        return {
          ...prev,
          position: {
            ...prev.position,
            top: prev.position.originalTop - calendarLayoutContentEl.scrollTop,
            left:
              prev.position.originalLeft - calendarLayoutContentEl.scrollLeft,
          },
        }
      })
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setTemporaryReservation(null)
      }
    }

    calendarLayoutContentEl.addEventListener("mousedown", handleMouseDown)
    calendarLayoutContentEl.addEventListener("mousemove", handleMouseMove)
    calendarLayoutContentEl.addEventListener("mouseup", handleMouseUp)
    calendarLayoutContentEl.addEventListener("scroll", handleReservationScroll)
    document.addEventListener("keydown", handleKeyDown)

    return () => {
      calendarLayoutContentEl.removeEventListener("mousedown", handleMouseDown)
      calendarLayoutContentEl.removeEventListener("mousemove", handleMouseMove)
      calendarLayoutContentEl.removeEventListener("mouseup", handleMouseUp)
      calendarLayoutContentEl.removeEventListener(
        "scroll",
        handleReservationScroll,
      )
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [temporaryReservation])

  const tempReservationHeight = temporaryReservation?.position.height || 0

  const reservationClassNames = cn("reservation temporary my", {
    compact: tempReservationHeight < PIXELS_PER_HOUR,
    full: tempReservationHeight >= PIXELS_PER_HOUR,
  })

  const [currentScrollPosition, setCurrentScrollPosition] = useState(0)
  const isToday = start.isSame(dayjs(), "day")

  const { entry: user } = useAppSelector(selectUser)
  const isAdminOrManager = isOfficeManager(user) || isPortalAdmin(user)

  useEffect(() => {
    if (!isToday || !calendarLayoutContentRef.current) return

    const targetElement = calendarLayoutContentRef.current.querySelector(
      ".CurrentTimeIndicator",
    ) as HTMLElement | null

    if (targetElement) {
      targetElement.style.setProperty(
        "scroll-margin-top",
        `${PIXELS_PER_HOUR}px`,
      )
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }, [isToday])

  return (
    <div
      className={calendarLayoutClassName}
      style={{ maxHeight: `${HOURS_IN_A_DAY * PIXELS_PER_HOUR}px` }}
    >
      <div
        className="calendar-layout-header-wrapper"
        ref={calendarLayoutHeaderWrapperRef}
      >
        <div className="empty-space"></div>
        <div className="calendar-layout-header" ref={calendarLayoutHeaderRef}>
          {roomsReservation.map((room) => (
            <div key={room.id} className="cell cell-overview">
              <RoomOverviewCell
                name={room.name}
                capacity={room.capacity}
                amenities={room.amenities}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="calendar-layout-body">
        <div className="calendar-layout-sidebar" ref={calendarLayoutSidebarRef}>
          {timeSlots.map((slot, index) => (
            <div key={index} className="time">
              <span>{index > 0 && slot.label}</span>
            </div>
          ))}
        </div>

        <div
          className="calendar-layout-content-wrapper"
          ref={calendarLayoutContentRef}
          onScroll={handleScroll}
        >
          <div className="calendar-layout-content">
            {timeSlots.map((timeslot, timeSlotIdx) => (
              <div key={timeSlotIdx} className="row">
                {roomsReservation.map((room, roomIdx) => {
                  const cellTimeSlot = timeslot.value
                  const currentReservations =
                    room.schedule.find((schedule) =>
                      start.isSame(dayjs(schedule.day), "day"),
                    )?.reservations || []

                  const reservationsInSlot = currentReservations.filter(
                    (reservation) =>
                      isEventInSlot(reservation.start, timeSlotIdx),
                  )

                  const calculatedReservations =
                    calculateReservationPositions(reservationsInSlot)

                  const isReadOnlyCalendar =
                    room.calendar &&
                    room.calendar.type === CalendarType.ICALENDAR

                  return (
                    <DayViewCell
                      scrollTop={currentScrollPosition}
                      roomId={room.id}
                      timeSlot={cellTimeSlot}
                      key={room.id}
                      canDrop={!isReadOnlyCalendar}
                    >
                      {isEventInSlot(dayjs().toISOString(), timeSlotIdx) &&
                        isToday && (
                          <CurrentTimeIndicator
                            offset={offset}
                            isLast={roomIdx === roomsReservation.length - 1}
                          />
                        )}
                      {calculatedReservations.map(
                        ({ reservation, position }) => {
                          const {
                            id,
                            title,
                            start,
                            end,
                            my,
                            visibility,
                            organizer,
                            creator,
                          } = reservation

                          const isCreatorOrOrganizer = [
                            organizer,
                            creator,
                          ].some(
                            (person) =>
                              person?.user && person.user.email === user.email,
                          )

                          const isReadOnly =
                            isReadOnlyCalendar ||
                            (!isAdminOrManager && !isCreatorOrOrganizer)

                          const isPrivateTitle =
                            visibility === ReservationVisibility.PRIVATE &&
                            !isAdminOrManager &&
                            !isCreatorOrOrganizer

                          return (
                            <ReservationCard
                              reservationId={id}
                              scrollTop={currentScrollPosition}
                              key={id}
                              title={title}
                              startTime={start}
                              endTime={end}
                              my={my}
                              visibility={visibility}
                              position={position}
                              isReadOnly={isReadOnly}
                              isPrivateTitle={isPrivateTitle}
                              onClick={(e) => {
                                if (isDraggingRef.current) {
                                  return
                                }
                                e.stopPropagation()
                                handleReservationClick(
                                  id,
                                  visibility,
                                  isReadOnly,
                                )
                              }}
                            />
                          )
                        },
                      )}
                    </DayViewCell>
                  )
                })}
              </div>
            ))}
            {temporaryReservation && (
              <div
                style={{
                  top: temporaryReservation.position.top,
                  left: temporaryReservation.position.left,
                  width: temporaryReservation.position.width,
                  height: temporaryReservation.position.height,
                  zIndex: temporaryReservation.position.zIndex,
                }}
                className={reservationClassNames}
              >
                <div className="name">{temporaryReservation.title}</div>
                <div className="time">
                  {dayjs(temporaryReservation.start).format(userTimeFormat())} -{" "}
                  {dayjs(temporaryReservation.end).format(userTimeFormat())}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DayView
