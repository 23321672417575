import React, {
  CSSProperties,
  forwardRef,
  MouseEvent,
  PropsWithChildren,
  useState,
} from "react"

import classNames from "classnames"
import { t } from "i18next"

import { ImageResizeOptions } from "../types/sharedTypes"
import Skeleton from "./advanced/Skeleton"

import { selectIsPortraitOrientation } from "../redux/app/selectors"
import { getImageUrl } from "../redux/files/utils"
import { useAppSelector } from "../redux/reducers"

import InfoSVG from "../assets/images/icons/Info.svg"

import "./PlanImage.sass"

export type Dimension = {
  width: number
  height: number
}

type PlanImageProps = {
  src: string
  imageResizeOptions?: ImageResizeOptions
  onClick?: (e: MouseEvent<HTMLImageElement>) => void
  containerSize: Dimension
  isWider: boolean
  isTaller: boolean
  isSame: boolean
  isDragging?: boolean
  onIsLoaded?: () => void
  onIsError?: () => void
}

const PlanImage = forwardRef<
  HTMLImageElement,
  PropsWithChildren<PlanImageProps>
>(
  (
    {
      children,
      src,
      onClick,
      containerSize,
      isWider,
      isTaller,
      isSame,
      onIsLoaded,
      onIsError,
      imageResizeOptions,
      isDragging = false,
    },
    ref,
  ) => {
    const [imageState, setSetImageState] = useState({
      isLoading: true,
      isLoaded: false,
      hasError: false,
    })
    const { isLoading, isLoaded, hasError } = imageState
    const imageStyle: CSSProperties = {
      width: undefined,
      height: undefined,
    }
    const isPortrait = useAppSelector(selectIsPortraitOrientation)

    if (isTaller || isSame) {
      imageStyle.height = `${containerSize.height}px`
      imageStyle.width = "auto"
    }

    if (isWider) {
      imageStyle.width = `${containerSize.width}px`
      imageStyle.height = "auto"
    }

    const planImageClassName = classNames({
      PlanImage: true,
      hidden: isLoading || hasError,
      landscape: !isPortrait,
      hideCursor: isDragging,
    })

    return (
      <>
        {!isLoaded && (
          <Skeleton width={containerSize.width} height={containerSize.height} />
        )}
        {hasError && !isLoaded && !onIsError && (
          <div className="PlanImage">
            <div className="error">
              <InfoSVG />
              {t("desktop.manage.floor_plan.error")}
            </div>
          </div>
        )}
        <div className={planImageClassName}>
          <img
            src={getImageUrl(src, imageResizeOptions)}
            ref={ref}
            alt={"Floor plan"}
            onClick={(e) => onClick?.(e)}
            style={imageStyle}
            onLoad={() => {
              setSetImageState((state) => ({
                isLoading: false,
                isLoaded: true,
                hasError: false,
              }))
              onIsLoaded?.()
            }}
            onError={() => {
              setSetImageState((state) => ({
                ...state,
                isLoading: false,
                hasError: true,
              }))
              onIsError?.()
            }}
          />
          {children}
        </div>
      </>
    )
  },
)

export default PlanImage
