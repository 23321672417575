import dayjs from "dayjs"
import { useDrop } from "react-dnd"
import { useTranslation } from "react-i18next"

import { timeZone } from "../../../../../dayjs"
import { useNavigation } from "../../../../../hooks/useNavigation"
import { useToast } from "../../../../../hooks/useToast"
import { Children } from "../../../../../types/sharedTypes"
import { ROOM_BOOKING_PATHS } from "../../constants"
import {
  DRAGGABLE_IDENTIFIER,
  MINUTES_PER_PIXEL,
  normalizeToIncrement,
  roundTime,
} from "../constants"

import { usePatchRoomReservationMutation } from "../../../../../redux/api/roomReservations"
import { isApiResponseError, isRejected } from "../../../../../redux/api/types"
import { selectAppDates } from "../../../../../redux/app/selectors"
import { useAppSelector } from "../../../../../redux/reducers"

type DayViewCellProps = {
  children: Children
  roomId: number
  timeSlot: string
  scrollTop: number
  canDrop: boolean
}

type RoomReservationDraggable = {
  id: string
  startTime: string
  endTime: string
  initialScrollTop: number
}

const ROUND_TO = 15

const calculateTimeFromOffset = (baseTime: string, offsetMinutes: number) => {
  const [hours, minutes] = baseTime.split(":").map(Number)
  const totalMinutes = hours * 60 + minutes + offsetMinutes
  const newHours = Math.floor(totalMinutes / 60)
  const newMinutes = totalMinutes % 60

  // prevent from going tomorrow to avoid date change
  if (newHours === 24) {
    return "23:45"
  }

  return `${newHours.toString().padStart(2, "0")}:${newMinutes.toString().padStart(2, "0")}`
}

const DayViewCell = ({
  children,
  roomId,
  timeSlot,
  scrollTop: currentScrollTop,
  canDrop,
}: DayViewCellProps) => {
  const [patchRoomReservation] = usePatchRoomReservationMutation()
  const { push } = useNavigation()
  const { errorToast } = useToast()
  const { t } = useTranslation()
  const { currentDate } = useAppSelector(selectAppDates)

  const [_, dropRef] = useDrop({
    accept: DRAGGABLE_IDENTIFIER,
    drop: async (item: RoomReservationDraggable, monitor) => {
      const { id, startTime, endTime, initialScrollTop } = item
      const delta = monitor.getDifferenceFromInitialOffset()
      if (!delta) {
        return
      }

      const scrollDifference = currentScrollTop - initialScrollTop
      const minutesOffsetFromScroll = scrollDifference * MINUTES_PER_PIXEL

      const minutesOffset =
        MINUTES_PER_PIXEL * delta.y + minutesOffsetFromScroll

      let newStartTime = roundTime(
        dayjs(startTime).add(minutesOffset, "minute"),
        ROUND_TO,
      )

      if (newStartTime.isBefore(dayjs(startTime).startOf("day"))) {
        newStartTime = dayjs(startTime).hour(0).minute(0)
      }

      if (newStartTime.isAfter(dayjs(startTime).endOf("day"))) {
        newStartTime = dayjs(startTime).hour(23).minute(45)
      }

      const duration = dayjs(endTime).diff(dayjs(startTime), "minute")

      let newEndTime = newStartTime.add(duration, "minute")

      if (newEndTime.isAfter(dayjs(startTime).endOf("day"))) {
        newEndTime = dayjs(startTime).add(1, "day").hour(0).minute(0)
      }

      const response = await patchRoomReservation({
        id,
        room_id: roomId,
        start: newStartTime.toISOString(),
        end: newEndTime.toISOString(),
        tz: timeZone,
      })

      if (isRejected(response)) {
        const { error } = response

        if (isApiResponseError(error)) {
          errorToast(error.message)
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    canDrop: () => canDrop,
  })

  const handleCellClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const { top } = e.currentTarget.getBoundingClientRect()

    // prevents wrong clicks on resizing
    const isResizing = e.currentTarget.querySelector(".is-resizing")
    if (isResizing) {
      return
    }

    const clickY = e.clientY - top
    const minutesOffset = normalizeToIncrement(
      clickY * MINUTES_PER_PIXEL,
      ROUND_TO,
    )

    const startTime = calculateTimeFromOffset(timeSlot, minutesOffset)

    const queryParams = new URLSearchParams({
      roomId: roomId.toString(),
      startTime,
      date: currentDate.format("YYYY-MM-DD"),
    })

    push(`${ROOM_BOOKING_PATHS.add}?${queryParams.toString()}`)
  }

  return (
    <div ref={dropRef} className="cell" onClick={handleCellClick}>
      {children}
    </div>
  )
}

export default DayViewCell
