import classNames from "classnames"

import DeskReservationItem from "./DeskReservationItem"

import { DeskScheduleReservation } from "../../../../redux/desk_schedule/types"

type Props = {
  reservations: DeskScheduleReservation[]
  className?: string
}

export const DeskReservationList = ({ reservations, className }: Props) => {
  const classes = classNames("reservation-list", className)

  return (
    <div className={classes}>
      {reservations.map((r) => (
        <DeskReservationItem key={r.id} reservation={r} />
      ))}
    </div>
  )
}
