import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react"

import dayjs, { Dayjs } from "dayjs"
import queryString from "query-string"
import { useHistory } from "react-router-dom"

import { toInternalTime } from "../../../utils"
import { ROOM_BOOKING_PATHS } from "../Rooms/constants"
import { DeskWithReservations, RoomWithReservations } from "./types"

import { store } from "../../../redux/reducers"

const { stringify } = queryString

export const FLOOR_PLAN_SCHEDULE_PATHNAME = "/manage/floor-plan"

type FloorPlanState = {
  hoveredSpotId: string
  openedSpotId: string
  currentDate: Dayjs
  scrollToView: boolean
}

const initialState: FloorPlanState = {
  hoveredSpotId: "",
  openedSpotId: "",
  currentDate: dayjs(),
  scrollToView: false,
}

/**
 * Provider hook
 */
type FloorPlanContextState = {
  floorPlanState: FloorPlanState
  setFloorPlanState: Dispatch<SetStateAction<FloorPlanState>>
}

const useFloorPlanProvider = (): FloorPlanContextState => {
  const [floorPlanState, setFloorPlanState] =
    useState<FloorPlanState>(initialState)

  return {
    floorPlanState,
    setFloorPlanState,
  }
}

/**
 * Context
 */
const FloorPlanContext = createContext<FloorPlanContextState | null>(null)

/**
 * Provider
 */
type FloorPlanProviderProps = {
  children: ReactNode
}
const FloorPlanProvider: FC<FloorPlanProviderProps> = ({ children }) => {
  const providerValue: FloorPlanContextState = useFloorPlanProvider()

  return (
    <FloorPlanContext.Provider value={providerValue}>
      {children}
    </FloorPlanContext.Provider>
  )
}

/**
 * context hook
 */
type UseFloorPlanContext = {
  hoveredSpot: string
  openedSpot: string
  scrollToView: boolean
  setHoveredSpot: (id: string) => void
  clearHoveredSpot: () => void
  setOpenedSpot: (id: string, scrollToView?: boolean) => void
  clearOpenedSpot: () => void
  openDeskReservation: (id: string) => void
  openRoomReservation: (id: string) => void
  createNewDeskReservation: (spot: DeskWithReservations) => void
  createNewRoomReservation: (spot: RoomWithReservations) => void
}

export const useFloorPlanContext = (): UseFloorPlanContext => {
  const state: FloorPlanContextState | null = useContext(FloorPlanContext)
  const history = useHistory()

  if (!state) {
    throw new Error("useFloorPlanContext must be used within FloorPlanProvider")
  }

  const setHoveredSpot = (id: string) => {
    if (id !== state.floorPlanState.hoveredSpotId)
      state.setFloorPlanState((s) => ({ ...s, hoveredSpotId: id }))
  }

  const clearHoveredSpot = () => {
    state.setFloorPlanState((s) => ({ ...s, hoveredSpotId: "" }))
  }

  const setOpenedSpot = (id: string, scrollToView: boolean = false) => {
    state.setFloorPlanState((s) => ({ ...s, openedSpotId: id, scrollToView }))
  }

  const clearOpenedSpot = () => {
    state.setFloorPlanState((s) => ({
      ...s,
      openedSpotId: "",
      scrollToView: false,
    }))
  }

  const openDeskReservation = (id: string) => {
    history.push(`${FLOOR_PLAN_SCHEDULE_PATHNAME}/${id}`)
  }

  const createNewDeskReservation = ({ name, id }: DeskWithReservations) => {
    const state = store.getState()

    const { email, first_name, last_name } = state.user.entry
    const reservation = {
      date: dayjs(state.app.currentDate).toISOString(),
      desk: id,
      deskName: name,
      user: email,
      userFirstName: first_name,
      userLastName: last_name,
    }

    history.push({
      pathname: `${FLOOR_PLAN_SCHEDULE_PATHNAME}/add`,
      search: stringify(reservation),
    })
  }

  const createNewRoomReservation = ({ id }: RoomWithReservations) => {
    const state = store.getState()

    const now = dayjs()
    const currentDate = dayjs(state.app.currentDate)

    const currentHour = now.hour()
    const currentMinute = now.minute()

    const startDateTime = currentDate.hour(currentHour).minute(currentMinute)
    const endDateTime = startDateTime.add(30, "minute")

    const { email } = state.user.entry
    const reservation = {
      roomId: id ? id.toString() : "",
      date: currentDate.format("YYYY-MM-DD"),
      startTime: toInternalTime(startDateTime),
      endTime: toInternalTime(endDateTime),
      user: email,
      returnTo: FLOOR_PLAN_SCHEDULE_PATHNAME,
    }

    history.push({
      pathname: ROOM_BOOKING_PATHS.add,
      search: stringify(reservation),
    })
  }

  const openRoomReservation = (id: string) => {
    history.push({
      pathname: ROOM_BOOKING_PATHS.edit.replace(":id", id),
      search: stringify({ returnTo: FLOOR_PLAN_SCHEDULE_PATHNAME }),
    })
  }

  return {
    hoveredSpot: state.floorPlanState.hoveredSpotId,
    openedSpot: state.floorPlanState.openedSpotId,
    scrollToView: state.floorPlanState.scrollToView,
    setHoveredSpot,
    clearHoveredSpot,
    setOpenedSpot,
    clearOpenedSpot,
    openDeskReservation,
    openRoomReservation,
    createNewDeskReservation,
    createNewRoomReservation,
  }
}

export default FloorPlanProvider
