import React, { useLayoutEffect, useRef, useState } from "react"

import { useTranslation } from "react-i18next"

import ReservationItem from "./ReservationItem"

import {
  Reservation,
  ReservationVisibility,
} from "../../../../../redux/api/roomReservations/types"
import { useAppSelector } from "../../../../../redux/reducers"
import { selectUser } from "../../../../../redux/user/selectors"
import { isOfficeManager, isPortalAdmin } from "../../../../../redux/user/utils"

import Button from "../../../../../components/basic/Button"

import ChevronUpSVG from "../../../../../assets/images/icons/Chevron.svg"
import ChevronDownSVG from "../../../../../assets/images/icons/ChevronDown.svg"

import "./ReservationList.sass"

type Props = {
  reservations: Reservation[]
  isReadOnlyCalendar: boolean
  roomsCount: number
}

const RESERVATION_HEIGHT_ITEM_PER_PIXEL = 28
const RESERVATION_HEIGHT_ITEM_MARGIN_PER_PIXEL = 12
const SHOW_MORE_BUTTON_PER_PIXEL = 20

const DEFAULT_MAX_VISIBLE_RESERVATIONS = 3

const ReservationList = ({
  reservations,
  isReadOnlyCalendar,
  roomsCount,
}: Props) => {
  const { t } = useTranslation()

  const { entry: user } = useAppSelector(selectUser)
  const isAdminOrManager = isOfficeManager(user) || isPortalAdmin(user)

  const reservationListRef = useRef<HTMLDivElement | null>(null)

  const [maxVisibleReservations, setMaxVisibleReservations] = useState(
    DEFAULT_MAX_VISIBLE_RESERVATIONS,
  )
  const [isExpanded, setExpanded] = useState(false)

  const visibleReservations = isExpanded
    ? reservations
    : reservations.slice(0, maxVisibleReservations)

  const showAllButtonLabel = isExpanded
    ? t("general.show_less")
    : t("general.show_more")

  const showAllButtonIcon = isExpanded ? <ChevronDownSVG /> : <ChevronUpSVG />

  const updateMaxVisibleItems = () => {
    if (reservationListRef.current) {
      let availableHeight = reservationListRef.current.clientHeight

      const isShowMoreVisible = reservations.length > maxVisibleReservations

      if (isShowMoreVisible) {
        availableHeight -= SHOW_MORE_BUTTON_PER_PIXEL
      }

      const visibleItems = Math.floor(
        availableHeight /
          (RESERVATION_HEIGHT_ITEM_PER_PIXEL +
            RESERVATION_HEIGHT_ITEM_MARGIN_PER_PIXEL),
      )

      setMaxVisibleReservations(visibleItems)
    }
  }

  useLayoutEffect(() => {
    updateMaxVisibleItems()
  }, [roomsCount, reservations.length])

  return (
    <div ref={reservationListRef} className="reservation-list">
      {visibleReservations.map((reservation) => {
        const { id, title, start, end, visibility, organizer, creator } =
          reservation

        const isCreatorOrOrganizer = [organizer, creator].some(
          (person) => person?.user && person.user.email === user.email,
        )

        const isReadOnly =
          isReadOnlyCalendar || (!isAdminOrManager && !isCreatorOrOrganizer)

        const isPrivate =
          visibility === ReservationVisibility.PRIVATE &&
          !isAdminOrManager &&
          !isCreatorOrOrganizer

        return (
          <ReservationItem
            key={id}
            id={id}
            title={title}
            startTime={start}
            endTime={end}
            isReadOnly={isReadOnly}
            isPrivate={isPrivate}
          />
        )
      })}

      {reservations.length > maxVisibleReservations && (
        <Button
          className="ShowAll"
          variant="link"
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation()
            setExpanded((prev) => !prev)
          }}
          icon={showAllButtonIcon}
        >
          {showAllButtonLabel}
        </Button>
      )}
    </div>
  )
}

export default ReservationList
