import classNames from "classnames"

import "./CurrentTimeIndicator.sass"

type CurrentTimeIndicatorProps = {
  offset: number
  isLast?: boolean
}

const CurrentTimeIndicator = ({
  offset,
  isLast,
}: CurrentTimeIndicatorProps) => {
  const cn = classNames("CurrentTimeIndicator", {
    "CurrentTimeIndicator--last": isLast,
  })

  return (
    <div
      className={cn}
      style={{
        top: `${offset}px`,
      }}
    />
  )
}

export default CurrentTimeIndicator
