import React, { PropsWithChildren } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { getUserInfo } from "../screens/Manage/FloorPlan/utils"
import { Children, IdAndName } from "../types/sharedTypes"
import { shortUserTimeFormat } from "../utils"
import { Tooltip } from "./basic/Tooltip"

import {
  Reservation,
  ReservationVisibility,
} from "../redux/api/roomReservations/types"
import { useAppSelector } from "../redux/reducers"
import { selectUser } from "../redux/user/selectors"
import { formatUser, isOfficeManager, isPortalAdmin } from "../redux/user/utils"

import PeopleSVG from "../assets/images/icons/People.svg"

import "./PlaceTooltip.sass"

type RoomSpotTooltipProps = {
  title: string
  amenities?: IdAndName[]
  reservations?: Reservation[]
  capacity?: number | null
  children: Children
  uniqueId: string
  isTooltipClickable: boolean
}

const RoomSpotTooltip = ({
  title,
  amenities,
  reservations,
  capacity,
  children,
  uniqueId,
  isTooltipClickable,
}: PropsWithChildren<RoomSpotTooltipProps>) => {
  return (
    <div className="PlaceTooltip">
      <Tooltip
        className="place-tooltip"
        content={
          <RoomSpotTooltipContent
            title={title}
            reservations={reservations}
            amenities={amenities}
            capacity={capacity}
          />
        }
        uniqueId={uniqueId}
        place="bottom"
        offset={20}
        clickable={isTooltipClickable}
      >
        {children}
      </Tooltip>
    </div>
  )
}

type RoomSpotTooltipContentProps = {
  title: string
  amenities?: IdAndName[]
  reservations?: Reservation[]
  capacity?: number | null
}

const RoomSpotTooltipContent = ({
  title,
  amenities,
  reservations,
  capacity,
}: RoomSpotTooltipContentProps) => {
  const { t } = useTranslation()

  const { entry: myUser } = useAppSelector(selectUser)
  const isAdminOrManager = isOfficeManager(myUser) || isPortalAdmin(myUser)

  const hasAmenities = amenities && amenities.length > 0
  const hasReservations = reservations && reservations.length > 0

  return (
    <div className="TooltipPlaceContent" style={{ pointerEvents: "auto" }}>
      <div className="MetaPlaceContent">
        <div className="PlaceTitle">{title || "N/A"}</div>

        <div className="PlaceCapacity">
          {capacity != null && (
            <>
              <PeopleSVG />
              <span>
                {t("desktop.manage.room_booking.calendar.layout.people", {
                  count: capacity,
                })}
              </span>
            </>
          )}
        </div>

        {hasAmenities && (
          <div className="PlaceAmenities">
            {amenities.map((a: IdAndName) => (
              <span key={a.id}>{a.name}</span>
            ))}
          </div>
        )}
      </div>

      {hasReservations && (
        <div className="PlaceReservations PlaceReservations__rooms">
          {reservations.map((r) => {
            const user = getUserInfo(r)

            const isCreatorOrOrganizer = [r.organizer, r.creator].some(
              (person) => person?.user?.email === myUser.email,
            )

            const isPrivateTitle =
              !isAdminOrManager &&
              r.visibility === ReservationVisibility.PRIVATE &&
              !isCreatorOrOrganizer

            const formattedTitle = isPrivateTitle
              ? t(
                  "desktop.manage.room_booking.calendar.reservation_info.private",
                )
              : title || t("desktop.manage.room_booking.calendar.no_title")

            return (
              <div className="PlaceReservationsRow" key={r.id}>
                <div>
                  <div className="reservation-head">
                    <div className="title">{formattedTitle}</div>
                    <div className="time-range">
                      {dayjs(r.start).format(shortUserTimeFormat())}
                      {" - "}
                      {dayjs(r.end).format(shortUserTimeFormat())}
                    </div>
                  </div>
                  <div className="reservation-body">{formatUser(user)}</div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default RoomSpotTooltip
