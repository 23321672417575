import React from "react"

import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { DEVICES_WITH_RESTRICTED_CANCEL_OPTIONS } from "../../constants"
import {
  getDeviceCancelationEventOptions,
  getDeviceCheckinPeriodOptions,
  getDeviceMeetNowDurationOptions,
  getDeviceOnSpotDurationOptions,
  getRestrictedDeviceCancelationEventOptions,
  SETTINGS_GROUPS,
  SettingsGroups,
} from "../constants"
import FieldWithCheckbox from "../FieldWithCheckbox"
import { EffectiveSettingsLocation } from "../types"
import SettingsGroup, { LabelWithToolTip } from "./index"

import { DEVICE_TYPES } from "../../../../../redux/api/devices/constants"

import { Select } from "../../../../../components/basic/Select"
import Field from "../../../../../components/Field"

type Props = {
  checked: boolean
  disabled: boolean
  cancelMeetingsDisabled: boolean
  checkIntoMeetingsDisabled: boolean
  onToggleSettingsGroup: (
    settingsGroup: SettingsGroups,
    checked: boolean,
  ) => void
  effectiveSettingsLocation?: EffectiveSettingsLocation
  onLocationClick?: (location: EffectiveSettingsLocation) => void
  isSwitchHidden?: boolean
  featureExclusions?: string[]
  deviceType?: string | null
}

const GeneralGroup = ({
  checked,
  disabled,
  cancelMeetingsDisabled,
  checkIntoMeetingsDisabled,
  onToggleSettingsGroup,
  effectiveSettingsLocation,
  onLocationClick,
  isSwitchHidden,
  featureExclusions,
  deviceType,
}: Props) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const deviceMeetNowDurationOptions = getDeviceMeetNowDurationOptions()
  const deviceCancelationEventOptions =
    deviceType && DEVICES_WITH_RESTRICTED_CANCEL_OPTIONS.includes(deviceType)
      ? getRestrictedDeviceCancelationEventOptions()
      : getDeviceCancelationEventOptions()
  const deviceCheckinPeriodOptions = getDeviceCheckinPeriodOptions()
  const deviceOnSpotDurationOptions = getDeviceOnSpotDurationOptions()
  const isJoan13Pro = deviceType === DEVICE_TYPES.joan_13_pro

  return (
    <SettingsGroup
      label={t(
        "desktop.settings.rooms.device_settings.form.device_settings.title",
      )}
      checked={checked}
      onChange={(checked) =>
        onToggleSettingsGroup(SETTINGS_GROUPS.GENERAL_GROUP, checked)
      }
      disabled={disabled}
      effectiveSettingsLocation={effectiveSettingsLocation}
      onLocationClick={onLocationClick}
      isSwitchHidden={isSwitchHidden}
      id={SETTINGS_GROUPS.GENERAL_GROUP}
    >
      <div className="FieldWrapper Subtext">
        {t("desktop.settings.rooms.device_settings.form.device_settings.intro")}
      </div>

      {isJoan13Pro && (
        <>
          <Field
            control={control}
            name="device_on_spot_duration"
            label={
              <LabelWithToolTip
                group="device_settings"
                name="on_spot_duration"
              />
            }
            className="horizontal-field field-width-50"
          >
            {(props) => (
              <Select
                {...props}
                options={deviceOnSpotDurationOptions}
                getOptionValue={(t) => `${t.value}`}
                disabled={disabled}
                nothingFoundMessage={t("general.not_found.no_options_found")}
              />
            )}
          </Field>
        </>
      )}

      {!isJoan13Pro && (
        <>
          <Field
            control={control}
            name="device_meet_now_duration"
            label={<LabelWithToolTip group="device_settings" name="meet_now" />}
            className="horizontal-field field-width-50"
          >
            {(props) => (
              <Select
                {...props}
                options={deviceMeetNowDurationOptions}
                getOptionValue={(t) => `${t.value}`}
                disabled={disabled}
                nothingFoundMessage={t("general.not_found.no_options_found")}
              />
            )}
          </Field>
        </>
      )}

      <Field
        control={control}
        name="device_cancel_meetings"
        label={
          <LabelWithToolTip group="device_settings" name="cancel_meetings" />
        }
        className="horizontal-field field-width-50"
      >
        {(props) => (
          <Select
            {...props}
            options={deviceCancelationEventOptions}
            getOptionValue={(t) => `${t.value}`}
            disabled={cancelMeetingsDisabled}
            nothingFoundMessage={t("general.not_found.no_options_found")}
          />
        )}
      </Field>

      {!isJoan13Pro && (
        <FieldWithCheckbox
          name="device_meet_later"
          className="field-width-50"
          label={<LabelWithToolTip group="device_settings" name="meet_later" />}
          disabled={disabled}
          isSecondary
        />
      )}

      <Field
        control={control}
        name="device_check_into_timeout"
        label={
          <LabelWithToolTip
            group="device_settings"
            name="check_into_meetings"
          />
        }
        className="horizontal-field field-width-50"
      >
        {(props) => (
          <Select
            {...props}
            options={deviceCheckinPeriodOptions}
            getOptionValue={(t) => `${t.value}`}
            disabled={checkIntoMeetingsDisabled}
            nothingFoundMessage={t("general.not_found.no_options_found")}
          />
        )}
      </Field>

      <hr />

      <FieldWithCheckbox
        name="device_show_meeting_subject"
        className="field-width-50"
        label={
          <LabelWithToolTip
            group="device_settings"
            name="show_meeting_subject"
          />
        }
        disabled={disabled}
        isSecondary
      />

      <FieldWithCheckbox
        name="device_show_meeting_description"
        className="field-width-50"
        label={
          <LabelWithToolTip
            group="device_settings"
            name="show_meeting_description"
          />
        }
        disabled={disabled}
        isSecondary
      />

      <FieldWithCheckbox
        name="device_show_tentative_meetings"
        className="field-width-50"
        label={
          <LabelWithToolTip
            group="device_settings"
            name="show_tentative_meetings"
          />
        }
        disabled={disabled}
        isSecondary
      />

      <FieldWithCheckbox
        name="device_show_meeting_organizer"
        className="field-width-50"
        label={
          <LabelWithToolTip
            group="device_settings"
            name="show_meeting_organizer"
          />
        }
        disabled={disabled}
        isSecondary
      />

      <FieldWithCheckbox
        name="device_cleaning_status"
        className="field-width-50"
        label={<LabelWithToolTip group="device_settings" name="cleaned" />}
        disabled={disabled}
        isSecondary
      />

      <hr />

      <FieldWithCheckbox
        name="device_touchscreen"
        className="field-width-50"
        label={<LabelWithToolTip group="device_settings" name="enable_touch" />}
        disabled={disabled}
        isSecondary
        exclusions={featureExclusions}
      />

      <FieldWithCheckbox
        name="device_display_charging"
        className="field-width-50"
        label={
          <LabelWithToolTip
            group="device_settings"
            name="show_charging_screen"
          />
        }
        disabled={disabled}
        isSecondary
        exclusions={featureExclusions}
      />

      <FieldWithCheckbox
        name="device_sound"
        className="field-width-50"
        label={
          <LabelWithToolTip
            group="device_settings"
            name="play_sound_on_touch"
          />
        }
        disabled={disabled}
        isSecondary
      />

      <FieldWithCheckbox
        name="device_no_wifi"
        className="field-width-50"
        label={
          <LabelWithToolTip
            group="device_settings"
            name="show_no_wifi_screen"
          />
        }
        disabled={disabled}
        isSecondary
        exclusions={featureExclusions}
      />
    </SettingsGroup>
  )
}

export default GeneralGroup
