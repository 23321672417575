import classNames from "classnames"

import RoomReservationItem from "./RoomReservationItem"

import { Reservation } from "../../../../redux/api/roomReservations/types"

type Props = {
  reservations: Reservation[]
  isReadOnlyCalendar: boolean
  className?: string
}

const RoomReservationList = ({
  reservations,
  isReadOnlyCalendar,
  className,
}: Props) => {
  const classes = classNames("reservation-list", className)

  return (
    <div className={classes}>
      {reservations.map((r) => (
        <RoomReservationItem
          key={r.id}
          reservation={r}
          isReadOnlyCalendar={isReadOnlyCalendar}
        />
      ))}
    </div>
  )
}

export default RoomReservationList
