import React from "react"

import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

import { useFetchAssetsQuery } from "../../redux/api/assets"
import { useFetchSolutionsQuery } from "../../redux/api/solutions"
import { RootState, useAppSelector } from "../../redux/reducers"
import { selectSettingsEffective } from "../../redux/settings/selectors"

import AssetSVG from "../../assets/images/icons/Asset.svg"
import ChairSVG from "../../assets/images/icons/Chair.svg"
import RoomSVG from "../../assets/images/icons/Room.svg"

import "./LaunchBar.sass"

type LaunchButtonProps = {
  onClick?: React.EventHandler<any>
  icon: JSX.Element
}

const LaunchButton: React.FC<React.PropsWithChildren<LaunchButtonProps>> = ({
  onClick,
  icon,
  children,
}) => {
  return (
    <div className="LaunchButton" onClick={onClick}>
      {icon}
      {children}
    </div>
  )
}

export const LaunchBar: React.FC = () => {
  const { t } = useTranslation()

  const { data: solutions } = useFetchSolutionsQuery()

  const { entries: rooms } = useAppSelector((state: RootState) => state.rooms)

  const { entry: companySettings } = useAppSelector(selectSettingsEffective)
  const isInAppRoomBookingEnabled = companySettings?.room_in_app_booking_enabled

  const { data: { results: assets = [] } = {} } = useFetchAssetsQuery({
    active: true,
  })

  const showRoomsLaunchButton =
    ((solutions?.room?.active ?? false) &&
      isInAppRoomBookingEnabled &&
      rooms.length > 0) ??
    false
  const showDesksLaunchButton = solutions?.desk?.active ?? false
  const showAssetLaunchButton: boolean =
    (solutions?.desk?.active ?? false) && assets.length > 0
  const showPrompt = showDesksLaunchButton || showRoomsLaunchButton

  if (!showDesksLaunchButton && !showRoomsLaunchButton) {
    return null
  }

  const availableBookTypeCount = [
    showDesksLaunchButton,
    showRoomsLaunchButton,
    showAssetLaunchButton,
  ].reduce((acc, curr) => (curr ? acc + 1 : acc), 0)

  return (
    <div className="LaunchBar">
      {showPrompt && <div className="lead">{t("mobile.home.welcome")}</div>}

      <div className="actions">
        {showDesksLaunchButton && (
          <NavLink to="/book/desk">
            <LaunchButton icon={<ChairSVG />}>
              {t("mobile.home.book_desk")}
            </LaunchButton>
          </NavLink>
        )}
        {showRoomsLaunchButton && (
          <NavLink to="/book/room">
            <LaunchButton icon={<RoomSVG />}>
              {t("mobile.home.book_room")}
            </LaunchButton>
          </NavLink>
        )}
        {availableBookTypeCount === 3 && <div className="flex-break" />}
        {showAssetLaunchButton && (
          <NavLink to="/book/asset/time">
            <LaunchButton icon={<AssetSVG />}>
              {t("mobile.home.book_asset")}
            </LaunchButton>
          </NavLink>
        )}
      </div>
    </div>
  )
}
