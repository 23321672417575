import { api } from ".."
import { usersURL, userURL } from "../../../api"
import { store } from "../../reducers"
import { UserGroup } from "../../user/types"
import { getLegacyOfficeManagerEnabled } from "../../user/utils"
import {
  CSVEntry,
  FetchOptions,
  PatchUserRequest,
  UserRequest,
  UserRequestWithEmail,
  UserResponse,
  UsersResponse,
} from "./types"

export const ENTRIES_PER_PAGE = 10

const FETCH_DEFAULTS = {
  limit: ENTRIES_PER_PAGE,
  offset: 0,
  search: "",
}

const transformUserGroups = (user: UserResponse): UserResponse => ({
  ...user,
  groups:
    user.groups?.map((group) =>
      group === UserGroup.LEGACY_OFFICE_MANAGER
        ? UserGroup.OFFICE_MANAGER
        : group,
    ) || [],
})
const transformUserGroupsToLegacy = (
  user: Partial<UserRequest>,
): Partial<UserRequest> => ({
  ...user,
  groups:
    user.groups?.map((group) =>
      group === UserGroup.OFFICE_MANAGER
        ? UserGroup.LEGACY_OFFICE_MANAGER
        : group,
    ) || [],
})

export const users = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchUsers: builder.query<UsersResponse, FetchOptions | void>({
      query: (options) => {
        options = { ...FETCH_DEFAULTS, ...options }
        /*
					If we send an empty string we get back users that have no departments assigned to them,
					so we remove it from the options to avoid this.
				*/
        if (options.department_id === "") {
          delete options.department_id
        }
        // sending both groups will return users that have either group this can be removed when we remove legacy office manager flag
        if (options.role_id === UserGroup.OFFICE_MANAGER) {
          options.role_id = [
            UserGroup.OFFICE_MANAGER,
            UserGroup.LEGACY_OFFICE_MANAGER,
          ]
        }
        return {
          url: usersURL(options),
        }
      },
      transformResponse: (response: UsersResponse) => ({
        ...response,
        results: response.results.map(transformUserGroups),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ email }) => ({
                type: "Users" as const,
                id: email,
              })),
              { type: "Users", id: "LIST" },
            ]
          : [{ type: "Users", id: "LIST" }],
    }),

    fetchUser: builder.query<UserResponse, string>({
      query: (email) => ({
        url: userURL(email),
        method: "GET",
      }),
      transformResponse: transformUserGroups,
      providesTags: (_result, _error, email) => [{ type: "Users", id: email }],
    }),

    createUsers: builder.mutation<UserResponse, UserRequest[]>({
      query: (body = []) => {
        const transformedBody = getLegacyOfficeManagerEnabled()
          ? body.map(transformUserGroupsToLegacy)
          : body
        return {
          url: usersURL(),
          method: "POST",
          body: transformedBody,
        }
      },
      invalidatesTags: [{ type: "Users", id: "LIST" }],
    }),

    batchCreateUsers: builder.mutation<UserResponse[], CSVEntry[]>({
      query: (body = []) => ({
        url: usersURL(),
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Users", id: "LIST" }],
    }),

    updateUser: builder.mutation<UserResponse, UserRequestWithEmail>({
      query: ({ email, ...payload }) => {
        const transformedPayload = getLegacyOfficeManagerEnabled()
          ? transformUserGroupsToLegacy(payload)
          : payload

        return {
          url: userURL(email),
          method: "PUT",
          body: transformedPayload,
        }
      },
      invalidatesTags: (_result, _error, { email }) => [
        { type: "Users", id: "LIST" },
        { type: "Users", id: email },
        // only invalidate the Me if the user is me
        ...(isMe(email) ? (["Me"] as const) : []),
      ],
    }),
    patchUser: builder.mutation<UserResponse, PatchUserRequest>({
      query: ({ email, ...payload }) => {
        const transformedPayload = getLegacyOfficeManagerEnabled()
          ? transformUserGroupsToLegacy(payload)
          : payload
        return {
          url: userURL(email),
          method: "PATCH",
          body: transformedPayload,
        }
      },
      invalidatesTags: (_result, _error, { email }) => [
        { type: "Users", id: "LIST" },
        { type: "Users", id: email },
        // only invalidate the Me if the user is me
        ...(isMe(email) ? (["Me"] as const) : []),
      ],
    }),
    destroyUsers: builder.mutation<string, string[]>({
      query: (emails) => ({
        url: usersURL(),
        method: "DELETE",
        body: {
          emails,
        },
      }),
      invalidatesTags: (_result, _error, emails) => [
        { type: "Users", id: "LIST" },
        ...(emails &&
          emails.map((email) => ({ type: "Users", id: email }) as const)),
      ],
    }),
  }),
})

export const {
  useFetchUsersQuery,
  useFetchUserQuery,
  useCreateUsersMutation,
  useBatchCreateUsersMutation,
  usePatchUserMutation,
  useUpdateUserMutation,
  useDestroyUsersMutation,
} = users

const isMe = (email: string) => store.getState().user.entry.email === email

export const selectSingleUser = (email: string) =>
  users.endpoints.fetchUser.select(email)
