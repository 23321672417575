import React, { useEffect, useRef, useState } from "react"

import cn from "classnames"
import dayjs from "dayjs"

import { useHasScrollbar } from "../../../../../hooks/useHasScrollbar"
import RoomOverviewCell from "../RoomOverviewCell"
import ReservationList from "./ReservationList"
import { getDateRange } from "./utils"
import WeekViewCell from "./WeekViewCell"

import { CalendarType } from "../../../../../redux/api/calendars/types"
import { RoomReservation } from "../../../../../redux/api/roomReservations/types"
import { selectAppDates } from "../../../../../redux/app/selectors"
import { useAppSelector } from "../../../../../redux/reducers"

import "./WeekView.sass"

type Props = {
  roomsReservation: RoomReservation[]
}

const WeekView = ({ roomsReservation }: Props) => {
  const { fromDate, toDate, showWeekends } = useAppSelector(selectAppDates)

  const days = getDateRange(
    fromDate,
    showWeekends ? toDate : toDate.subtract(2, "day"),
  )

  const calendarLayoutContentRef = useRef<HTMLDivElement>(null)
  const [scrollbarPositionClassName, setScrollbarPositionClassName] =
    useState<string>("")

  const roomsCount = roomsReservation.length

  const hasScrollbar = useHasScrollbar(calendarLayoutContentRef, [
    roomsCount,
    showWeekends,
  ])

  const handleScrollPosition = () => {
    const calendarLayoutContentEl = calendarLayoutContentRef.current

    if (!calendarLayoutContentEl) return

    const { scrollTop, clientHeight, scrollHeight } = calendarLayoutContentEl

    setScrollbarPositionClassName(
      [
        scrollTop > 0 && "scroll-top-visible",
        scrollTop + clientHeight < scrollHeight && "scroll-bottom-visible",
      ]
        .filter(Boolean)
        .join(" "),
    )
  }

  useEffect(() => {
    const calendarLayoutContentEl = calendarLayoutContentRef.current

    if (!calendarLayoutContentEl) return

    calendarLayoutContentEl.addEventListener("scroll", handleScrollPosition)

    return () =>
      calendarLayoutContentEl.removeEventListener(
        "scroll",
        handleScrollPosition,
      )
  }, [])

  useEffect(() => {
    calendarLayoutContentRef.current?.classList.toggle(
      "hasScrollbar",
      hasScrollbar,
    )
  }, [hasScrollbar])

  useEffect(() => {
    handleScrollPosition()
  }, [roomsReservation])

  return (
    <div className={cn("WeekView", { showWeekends, hasScrollbar })}>
      <div className="calendar-layout">
        <div className="calendar-layout-header">
          <div className="empty-space"></div>
          {days.map((day, i) => (
            <div
              key={i}
              className={cn("day", { today: dayjs().isSame(day, "date") })}
            >
              <span>{day.format("ddd")}</span>
              <span>{day.format("MMM DD")}</span>
            </div>
          ))}
        </div>
        <div
          ref={calendarLayoutContentRef}
          className={cn("calendar-layout-body", scrollbarPositionClassName)}
        >
          {roomsReservation.map((room) => {
            const isReadOnlyCalendar =
              room.calendar && room.calendar.type === CalendarType.ICALENDAR

            return (
              <div key={room.id} className="calendar-row">
                <div className="cell cell-overview">
                  <RoomOverviewCell
                    name={room.name}
                    capacity={room.capacity}
                    amenities={room.amenities}
                  />
                </div>

                {days.map((day, i) => {
                  const reservations = room.schedule
                    .filter((schedule) =>
                      dayjs(schedule.day).isSame(day, "date"),
                    )
                    .flatMap((schedule) => schedule.reservations)

                  return (
                    <WeekViewCell
                      key={`${room.id}-${i}`}
                      roomId={room.id}
                      day={day.toISOString()}
                    >
                      {reservations.length > 0 && (
                        <ReservationList
                          reservations={reservations}
                          isReadOnlyCalendar={isReadOnlyCalendar}
                          roomsCount={roomsCount}
                        />
                      )}
                    </WeekViewCell>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default WeekView
