import React from "react"

import { Trans, useTranslation } from "react-i18next"

import { FEATURE_FLAGS } from "../../../../constants"
import { useCheckForFeatureFlag } from "../../../../hooks/useCheckForFeatureFlag"
import { useNavigation } from "../../../../hooks/useNavigation"
import { useScrollToLocation } from "../../../../hooks/useScrollToLocation"
import { getLabel } from "../../../../utils"
import BillingSection from "../BillingSection"
import { getPlanGroupPath } from "../constants"
import NoPlansInfo from "./NoPlansInfo"
import PricingPlans from "./PricingPlans"
import SubscriptionNotifications from "./SubscriptionNotifications"

import { LIST_PLAN_GROUPS } from "../../../../redux/api/billing/constants"
import { useFetchSubscriptionsQuery } from "../../../../redux/api/billing/subscriptions"
import { Subscription } from "../../../../redux/api/billing/subscriptions/types"
import { getPlanGroup } from "../../../../redux/api/billing/utils"

import SubscriptionCard from "../../../../components/advanced/SubscriptionCard"

import "./styles.sass"

const Plans = () => {
  const { t } = useTranslation()
  const { push } = useNavigation()

  const { data: { subsByPlanGroup, count = 0 } = {}, isSuccess } =
    useFetchSubscriptionsQuery()
  useScrollToLocation([isSuccess])

  const isNewBusinessModel = useCheckForFeatureFlag(
    FEATURE_FLAGS.NEW_BUSINESS_MODEL,
  )

  const handleModifyPlan = ({
    plan_type,
    subscription_id,
    plan_variation,
  }: Subscription) => {
    push(getPlanGroupPath(getPlanGroup(plan_type))?.root, {
      id: subscription_id,
      planType: plan_type,
      period: plan_variation.period_unit,
      currency: plan_variation.currency,
    })
  }

  const handleSelectPlan = ({
    plan_type,
    subscription_id,
    plan_variation,
  }: Subscription) => {
    push(getPlanGroupPath(getPlanGroup(plan_type))?.planSelection, {
      id: subscription_id,
      planType: plan_type,
      period: plan_variation.period_unit,
      currency: plan_variation.currency,
    })
  }

  return (
    <>
      {isNewBusinessModel && <PricingPlans />}

      {!isNewBusinessModel && (
        <BillingSection
          className="Plans"
          title={t("desktop.settings.billing.plans.title")}
          intro={
            <Trans i18nKey="desktop.settings.billing.plans.intro">
              <a
                href={`mailto:${getLabel("links.salesEmail")}`}
                target="_blank"
                rel="noreferrer"
              >
                link
              </a>
            </Trans>
          }
        >
          {isSuccess && count === 0 && <NoPlansInfo />}
          {isSuccess && count > 0 && (
            <div className="plans-list">
              {subsByPlanGroup &&
                LIST_PLAN_GROUPS.map((group) => {
                  const subscriptions = subsByPlanGroup[group]

                  if (!subscriptions || subscriptions.length === 0) {
                    return null
                  }
                  const notifications = subscriptions.flatMap((subscription) =>
                    subscription.notifications.map((notification) => ({
                      ...notification,
                      subscription,
                    })),
                  )

                  return (
                    <div key={group}>
                      <SubscriptionCard
                        id={group}
                        key={group}
                        subscriptions={subscriptions}
                        onModifyPlanClick={handleModifyPlan}
                        onSelectPlanClick={handleSelectPlan}
                      />
                      {notifications.length > 0 && (
                        <SubscriptionNotifications
                          notifications={notifications}
                          unit={subscriptions[0].unit[0].type}
                        />
                      )}
                    </div>
                  )
                })}
            </div>
          )}
        </BillingSection>
      )}
    </>
  )
}

export default Plans
