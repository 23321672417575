import React, { useState } from "react"

import cn from "classnames"

import { isValidURL } from "../../utils"

import Skeleton from "../../components/advanced/Skeleton"

import "./MetabaseEmbed.sass"

const MetabaseEmbedSkeleton = () => {
  return (
    <Skeleton
      skeletonGroup={{
        lines: [
          { width: "40%", space: 0.5 },
          { width: "84%" },
          { width: "84%" },
          { width: "84%", space: 0.5 },
          { width: "100%", height: "8rem", space: 0.5 },
          { width: "100%", height: "30rem" },
        ],
      }}
    />
  )
}

const MetabaseEmbed = ({
  isLoading: isLoadingProp = false,
  url,
  width = "100%",
  height = "75vh",
}: {
  isLoading?: boolean
  url?: string
  width?: string
  height?: string
}) => {
  const [isLoaded, setLoaded] = useState(false)
  const isLoading = isLoadingProp || !isLoaded
  let isUrlValid = isValidURL(url)

  const handleLoad = () => {
    setLoaded(true)
  }

  return (
    <div className={cn("MetabaseEmbed", { isLoading: isLoaded })}>
      {(isLoading || !isUrlValid) && <MetabaseEmbedSkeleton />}

      {isUrlValid && !isLoadingProp && (
        <iframe
          className="MetabaseEmbedIframe"
          src={url}
          style={{ width, height: isLoading ? 0 : height }}
          allow="fullscreen"
          onLoad={handleLoad}
        />
      )}

      {!isLoading && !isUrlValid && (
        <div>Viewing embedded analytics is not supported at the moment.</div>
      )}
    </div>
  )
}

export default MetabaseEmbed
