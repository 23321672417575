import { useLocation } from "react-router-dom"

export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const useQueryParam = (param: string) => {
  const location = useLocation()
  return new URLSearchParams(location.search).get(param)
}
