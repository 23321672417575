import { api } from ".."
import { calendarsURL, calendarURL } from "../../../api"
import { FETCH_WITH_NO_LIMIT } from "../../../constants"
import { PaginatedOptions } from "../../types"
import {
  AvailableCalendars,
  CalendarRequest,
  CalendarsResponse,
  CalendarType,
  ExchangeCalendar,
  ExchangeRequest,
  GoogleCalendar,
  GoogleOffice365Response,
  iCalendar,
  iCalendarRequest,
  Office365Calendar,
  UnpairCalendarRequest,
} from "./types"

const FETCH_DEFAULTS = {
  limit: FETCH_WITH_NO_LIMIT,
  offset: 0,
}

export const calendars = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchCalendars: builder.query<
      CalendarsResponse & { offset: number },
      PaginatedOptions | void
    >({
      query: (options) => calendarsURL({ ...FETCH_DEFAULTS, ...options }),
      providesTags: ["Calendars"],
      transformResponse: (response: CalendarsResponse, _meta, arg) => {
        return { ...response, offset: arg?.offset ?? 0 }
      },
    }),

    connectCalendar: builder.mutation<
      GoogleOffice365Response,
      (CalendarRequest | iCalendarRequest | ExchangeRequest) & {
        next?: string
      }
    >({
      query: ({ next, ...body }) => ({
        url: calendarsURL({
          ...(next && { next }),
        }),
        method: "POST",
        body,
      }),
      invalidatesTags: ["Calendars"],
    }),
    updateCalendar: builder.mutation<void, iCalendarRequest>({
      query: ({ calendar_id, ...body }) => ({
        url: calendarURL(calendar_id!),
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Calendars"],
    }),
    unpairCalendar: builder.mutation<void, UnpairCalendarRequest>({
      query: ({ id }) => ({
        url: calendarURL(id),
        method: "DELETE",
      }),
      invalidatesTags: ["Calendars"],
    }),
  }),
})

export const {
  useFetchCalendarsQuery,
  useConnectCalendarMutation,
  useUnpairCalendarMutation,
  useUpdateCalendarMutation,
} = calendars

export const isGoogleCalendar = (
  calendar: AvailableCalendars,
): calendar is GoogleCalendar => calendar.type === CalendarType.GOOGLE

export const isExchangeCalendar = (
  calendar: AvailableCalendars,
): calendar is ExchangeCalendar => calendar.type === CalendarType.EXCHANGE

export const isICalendar = (
  calendar: AvailableCalendars,
): calendar is iCalendar => calendar.type === CalendarType.ICALENDAR

export const isOffice365Calendar = (
  calendar: AvailableCalendars,
): calendar is Office365Calendar => calendar.type === CalendarType.OFFICE365
