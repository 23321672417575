import React from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import { useBookContext } from "../../../../contexts/Mobile/BookContext"
import { useAnalyticsScreenView } from "../../../../providers/Mobile/FirebaseAnalyticsProvider"
import NearbyDesks from "./NearbyDesks"

import { useFetchDeskReservationsQuery } from "../../../../redux/api/deskReservations"

import Loader from "../../../../components/basic/Loader"
import { IconMapper } from "../../../../components/CorrectIconMapper"
import SafeViewArea from "../../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../../components/Mobile/TopNav"

import "./OccupiedDesk.sass"

const OccupiedDesk = () => {
  useAnalyticsScreenView("Book/Desks/OccupiedDesk")

  const { t } = useTranslation()
  const { goToHome } = useBookContext()
  const handleClose = () => goToHome()

  const location = useLocation<{ desk_id: string }>()
  const deskId = location.state?.desk_id

  const date = dayjs()
  const startOfDay = date.startOf("day").toISOString()
  const endOfDay = date.endOf("day").toISOString()

  const { data: deskReservations, isLoading: isDeskReservationsLoading } =
    useFetchDeskReservationsQuery({
      start: startOfDay,
      end: endOfDay,
    })

  const deskOccupantFirstName = deskReservations?.results[0]?.user?.first_name
  const deskOccupantLastName = deskReservations?.results[0]?.user?.last_name

  return (
    <SafeViewArea className="OccupiedDesk">
      <div className="head">
        <TopNav onClose={handleClose} />
        <div className="icon">
          <div>
            <IconMapper iconType="desk" needsWrap={false} />
          </div>
        </div>
      </div>

      <div className="body">
        <div className="main-data">
          {isDeskReservationsLoading && <Loader variant="fullScreen" />}

          {deskReservations && !isDeskReservationsLoading && (
            <>
              <div className="title">
                <div>{t("mobile.general.occupied_desk")}</div>
                <div className="occupied-by">
                  {" "}
                  {t("general.by")} {deskOccupantFirstName}{" "}
                  {deskOccupantLastName}
                </div>
              </div>
              <div className="subtitle">
                {t("mobile.general.book_a_nearby_desk")}
              </div>

              <NearbyDesks deskId={deskId} />
            </>
          )}
        </div>
      </div>
    </SafeViewArea>
  )
}

export default OccupiedDesk
