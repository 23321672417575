import dayjs from "dayjs"

import { userTimeFormat } from "../../../../utils"
import { useFloorPlanContext } from "../FloorPlanProvider"
import { getUserInfo } from "../utils"

import { Reservation } from "../../../../redux/api/roomReservations/types"
import { useAppSelector } from "../../../../redux/reducers"
import { selectUser } from "../../../../redux/user/selectors"
import {
  formatUser,
  isOfficeManager,
  isPortalAdmin,
} from "../../../../redux/user/utils"

type Props = {
  reservation: Reservation
  isReadOnlyCalendar: boolean
}

const RoomReservationItem = ({ reservation, isReadOnlyCalendar }: Props) => {
  const { openRoomReservation } = useFloorPlanContext()

  const { entry: myUser } = useAppSelector(selectUser)
  const isAdminOrManager = isOfficeManager(myUser) || isPortalAdmin(myUser)

  const { start, end, organizer, creator } = reservation

  const startTime = dayjs(start).format(userTimeFormat())
  const endTime = dayjs(end).format(userTimeFormat())
  const isCreatorOrOrganizer = [organizer, creator].some(
    (person) => person?.user && person.user.email === myUser.email,
  )

  const user = getUserInfo(reservation)

  const isReadOnly =
    isReadOnlyCalendar || (!isAdminOrManager && !isCreatorOrOrganizer)

  const handleOpenRoomReservationClick = () => {
    if (isReadOnly) {
      return
    }

    openRoomReservation(reservation.id)
  }

  return (
    <button
      className="reservation-item-details"
      disabled={isReadOnly}
      type="button"
      onClick={handleOpenRoomReservationClick}
    >
      <div className="reservation-item-details-main">
        <div>
          <span className="reservation-item-details-main__name">
            {formatUser(user)}
          </span>
        </div>
        <span className="reservation-item-details-main__time">{`${startTime} - ${endTime}`}</span>
      </div>
    </button>
  )
}

export default RoomReservationItem
