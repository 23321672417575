import React, { useEffect, useRef } from "react"

import { useTranslation } from "react-i18next"

import { FETCH_WITH_NO_LIMIT, LabelPaths } from "../../constants"
import { getDeviceLabel } from "../../screens/Settings/Rooms/Devices/utils"
import { OptionType } from "../../types/sharedTypes"
import { getLabel } from "../../utils"
import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

import { useLazyFetchRoomsDevicesQuery } from "../../redux/api/devices"
import { RoomDeviceResponse } from "../../redux/api/devices/types"

import "./DeviceFilter.sass"

type Props = {
  value: string
  onChange: (v: string) => void
  showAll?: boolean
  showType?: boolean
  buildingId?: string
}

const DeviceFilter = ({
  value,
  onChange,
  showAll = false,
  showType = false,
  buildingId,
}: Props) => {
  const { t } = useTranslation()

  const [
    fetchRoomsDevices,
    { data: { results: entries = [] } = {}, isLoading },
  ] = useLazyFetchRoomsDevicesQuery()

  const valueRef = useRef<string>(value)
  const prevDeviceIdsRef = useRef<string[] | undefined>()

  useEffect(() => {
    if (
      buildingId !== FilterSpecialValues.EMPTY &&
      buildingId !== FilterSpecialValues.ALL
    ) {
      fetchRoomsDevices({
        limit: FETCH_WITH_NO_LIMIT,
        building: buildingId,
      })
    } else {
      fetchRoomsDevices({
        limit: FETCH_WITH_NO_LIMIT,
      })
    }
  }, [buildingId, fetchRoomsDevices])

  // Calculate which device is selected
  useEffect(() => {
    if (!isLoading && entries.length > 0) {
      if (showAll) {
        const hasDevice =
          entries.find((d: RoomDeviceResponse) => d.id === valueRef.current) !==
          undefined

        if (!hasDevice) {
          onChange(FilterSpecialValues.ALL)
        } else {
          onChange(valueRef.current)
        }
      } else {
        let devices: RoomDeviceResponse[] = entries

        const deviceIds: string[] = devices.map((f) => f.id)

        let hasNewOne = false

        // Find if there is a new device and select it
        if (prevDeviceIdsRef.current) {
          for (let dId of deviceIds) {
            if (!prevDeviceIdsRef.current.includes(dId)) {
              prevDeviceIdsRef.current = deviceIds
              valueRef.current = dId

              hasNewOne = true

              onChange(dId)
            }
          }
        }

        if (!hasNewOne) {
          // Leave previously selected or select first
          if (valueRef.current && deviceIds.includes(valueRef.current)) {
            prevDeviceIdsRef.current = deviceIds

            onChange(valueRef.current)
          } else {
            prevDeviceIdsRef.current = deviceIds
            valueRef.current = deviceIds[0]

            onChange(deviceIds[0])
          }
        }
      }
    } else if (!isLoading && entries.length === 0) {
      onChange(FilterSpecialValues.ALL)
    }
  }, [entries, buildingId, showAll])

  const deviceOptions: OptionType<string>[] = entries
    .filter((d) => !!d.type)
    .map((d: RoomDeviceResponse) => {
      const deviceType = d.type
        ? getLabel(`deviceTypes.${d.type.toLowerCase()}` as LabelPaths)
        : ""

      return {
        value: d.id,
        label: (
          <>
            {getDeviceLabel(d)}
            {showType && d.name && (
              <span className="type"> &middot; {deviceType}</span>
            )}
          </>
        ),
      }
    })

  const count = entries.length

  showAll &&
    deviceOptions.unshift({
      label: (
        <>
          {t("desktop.settings.rooms.device_settings.filters.all_devices")}{" "}
          <span className="count">&middot; {count}</span>
        </>
      ),
      value: FilterSpecialValues.ALL,
    })

  if (entries.length === 0) {
    return null
  }

  return (
    <Dropdown
      className="device-filter"
      options={deviceOptions}
      value={value}
      onChange={onChange}
    />
  )
}

export default DeviceFilter
