import React, { useCallback, useEffect, useMemo, useState } from "react"

import dayjs from "dayjs"
import CopyToClipBoardFunction from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"
import ReactModal from "react-modal"

import { useNavigation } from "../../../../hooks/useNavigation"
import { useToast } from "../../../../hooks/useToast"
import { contentStyle, overlayStyle } from "../../../../modals/modalStyles"
import { userTimeFormat } from "../../../../utils"
import { ROOM_BOOKING_PATHS } from "../constants"
import { useModals } from "@mattjennings/react-modal-stack"
import { isRejected } from "@reduxjs/toolkit"

import {
  useDeleteRoomReservationMutation,
  useFetchRoomReservationQuery,
} from "../../../../redux/api/roomReservations"
import {
  Person,
  ReservationVisibility,
} from "../../../../redux/api/roomReservations/types"
import { isApiResponseError } from "../../../../redux/api/types"
import { useAppSelector } from "../../../../redux/reducers"
import { selectUser } from "../../../../redux/user/selectors"
import {
  isNormalUser,
  isOfficeManager,
  isPortalAdmin,
} from "../../../../redux/user/utils"

import Button from "../../../../components/advanced/Button"
import { ConfirmationModal } from "../../../../components/advanced/ConfirmationModal"
import Loader from "../../../../components/basic/Loader"
import { RichText } from "../../../../components/basic/RichText"

import CheckSVG from "../../../../assets/images/icons/Check.svg"
import ContentCopySVG from "../../../../assets/images/icons/ContentCopy.svg"
import CrossSVG from "../../../../assets/images/icons/Cross.svg"

import "./ReservationInfoModal.sass"

const CopyToClipboard = ({ textToCopy }: { textToCopy: string }) => {
  const SUCCESS_ACTION_TIMER = 2000

  const [wasCopied, setWasCopied] = useState<boolean>(false)

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (wasCopied) {
      timer = setTimeout(() => {
        setWasCopied(false)
      }, SUCCESS_ACTION_TIMER)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [wasCopied])

  const handleOnClick = () => {
    setWasCopied(true)
  }

  return (
    <CopyToClipBoardFunction text={textToCopy}>
      {!wasCopied ? <ContentCopySVG onClick={handleOnClick} /> : <CheckSVG />}
    </CopyToClipBoardFunction>
  )
}

type Props = {
  open: boolean
  reservationId: string
  visibility: ReservationVisibility
  isReadOnly: boolean
  onCloseClick: () => void
}

const ReservationInfoModal = ({
  open,
  reservationId,
  visibility,
  isReadOnly,
  onCloseClick,
}: Props) => {
  const { openModal, closeModals } = useModals()
  const { errorToast, infoToast } = useToast()
  const { t } = useTranslation()
  const { push } = useNavigation()

  const { entry: currentUser } = useAppSelector(selectUser)
  const isUser = isNormalUser(currentUser)
  const isAdmin = isPortalAdmin(currentUser)
  const isManager = isOfficeManager(currentUser)

  const {
    data: reservation,
    isFetching: isRoomReservationLoading,
    isSuccess: isRoomReservationLoaded,
  } = useFetchRoomReservationQuery(reservationId, { skip: !reservationId })

  const [deleteRoomReservation] = useDeleteRoomReservationMutation()

  const {
    id,
    room,
    attendees,
    title,
    description,
    conference_link_id,
    creator,
    organizer,
    start,
    end,
    my,
  } = reservation ?? {}

  const getPersonEmail = useCallback(
    (person?: Person | null) =>
      person?.user?.email || person?.external?.email || "",
    [],
  )

  const isOwner = useMemo(
    () =>
      my ||
      [creator, organizer].some(
        (person) => currentUser.email === getPersonEmail(person),
      ),
    [my, currentUser.email, creator, organizer, getPersonEmail],
  )

  const isAttendee = useMemo(
    () =>
      attendees?.some(
        (attendee) => currentUser.email === getPersonEmail(attendee),
      ),
    [attendees, currentUser.email, getPersonEmail],
  )

  const isReservationPartiallyHidden = useMemo(() => {
    if (isAdmin || isManager) return false

    return (
      isUser &&
      visibility === ReservationVisibility.PRIVATE &&
      !isOwner &&
      !isAttendee
    )
  }, [isAdmin, isManager, isUser, visibility, isOwner, isAttendee])

  const formattedAttendees = useMemo(() => {
    if (!attendees?.length) {
      return []
    }

    return (
      attendees
        // Filter out the room from the attendees
        ?.filter((attendee) => getPersonEmail(attendee) !== room?.key)
        ?.map(getPersonEmail)
        .filter(Boolean) || []
    )
  }, [attendees, getPersonEmail])

  const formatValue = (value?: string | null) => value?.trim() || "N/A"

  const handleEditReservationClick = useCallback(() => {
    if (!id) return

    push(ROOM_BOOKING_PATHS.edit.replace(":id", id))

    onCloseClick()
  }, [id, push, onCloseClick])

  const handleDeleteReservationClick = useCallback(async () => {
    if (!id) return

    const response = await deleteRoomReservation(id)

    if (isRejected(response)) {
      const { error } = response

      if (isApiResponseError(error)) {
        errorToast(error.message)

        return
      }
    }

    infoToast(
      t(
        "desktop.manage.room_booking.calendar.reservation_info.toasts.delete_success",
      ),
    )

    closeModals()
  }, [id, deleteRoomReservation, errorToast, infoToast, closeModals, t])

  const handleDeleteConfirmationClick = useCallback(() => {
    if (!id) return

    openModal(ConfirmationModal, { onConfirm: handleDeleteReservationClick })
  }, [id, openModal, handleDeleteReservationClick])

  useEffect(() => {
    if (reservationId && isRoomReservationLoaded && !reservation) {
      errorToast(
        t(
          "desktop.manage.room_booking.calendar.reservation_info.toasts.not_found",
        ),
      )
    }
  }, [reservationId, reservation, isRoomReservationLoaded, errorToast, t])

  return (
    <ReactModal
      isOpen={open}
      style={{
        content: { ...contentStyle, width: "30rem" },
        overlay: overlayStyle,
      }}
      onRequestClose={onCloseClick}
      ariaHideApp={false}
    >
      {isRoomReservationLoading ? (
        <Loader />
      ) : (
        <div className="ModalForm ReservationInfoModal">
          <div className="title">
            <h1>
              {!isReservationPartiallyHidden
                ? formatValue(title)
                : t(
                    "desktop.manage.room_booking.calendar.reservation_info.private",
                  )}
            </h1>
          </div>
          <div className="close" onClick={onCloseClick}>
            <CrossSVG />
          </div>

          <div className="fields">
            <div className="field field-date-time">
              <div className="value">
                {formatValue(dayjs(start).format("ddd, D MMM, YYYY"))}
              </div>
              <div className="value">
                {start && end
                  ? `${dayjs(start).format(userTimeFormat())} - ${dayjs(end).format(userTimeFormat())}`
                  : "N/A"}
              </div>
            </div>

            <div className="field">
              <div className="label">
                {t(
                  "desktop.manage.room_booking.calendar.reservation_info.meeting_room",
                )}
              </div>
              <div className="value">{formatValue(room?.name)}</div>
            </div>

            {!isReservationPartiallyHidden && (
              <>
                <div className="field">
                  <div className="label">
                    {t(
                      "desktop.manage.room_booking.calendar.reservation_info.organizer_and_attendees",
                    )}
                  </div>
                  <div className="value">
                    {organizer && (
                      <div className="reservation-organizer">
                        {formatValue(getPersonEmail(organizer))}
                      </div>
                    )}

                    <div className="reservation-attendees">
                      {formatValue(formattedAttendees.join(", "))}
                    </div>
                  </div>
                </div>

                <div className="field field-conference-link">
                  <div className="label">
                    {t(
                      "desktop.manage.room_booking.calendar.reservation_info.video_conferencing",
                    )}
                  </div>
                  <div className="value">
                    {conference_link_id ? (
                      <>
                        <CopyToClipboard textToCopy={conference_link_id} />
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={conference_link_id}
                          className="field-conference-link-cta"
                        >
                          {conference_link_id}
                        </a>
                      </>
                    ) : (
                      formatValue(conference_link_id)
                    )}
                  </div>
                </div>

                <div className="field">
                  <div className="label">
                    {t(
                      "desktop.manage.room_booking.calendar.reservation_info.description",
                    )}
                  </div>
                  <div className="value">
                    {description ? (
                      <RichText value={description} disabled />
                    ) : (
                      formatValue(description)
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          {!isReadOnly && (
            <div className="actions">
              <Button
                className="delete"
                variant="danger-pop"
                onClick={handleDeleteConfirmationClick}
                noConfirm
              >
                {t(
                  "desktop.manage.room_booking.calendar.reservation_info.actions.delete_button",
                )}
              </Button>

              <Button onClick={handleEditReservationClick}>
                {t(
                  "desktop.manage.room_booking.calendar.reservation_info.actions.edit_button",
                )}
              </Button>
            </div>
          )}
        </div>
      )}
    </ReactModal>
  )
}

export default ReservationInfoModal
