import { ThunkApiConfig } from "RootType"

import {
  deleteJSON,
  experimentsCompanyURL,
  get,
  postJSON,
  putJSON,
} from "../../api"
import { ResponseError } from "../../api/apiUtils"
import {
  getErrorMessage,
  getErrorObject,
  paginationInitialState,
  setFetchErrorState,
  setFetchSuccessState,
  setSubmitErrorState,
  setSubmitSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import { PaginationState, SliceState } from "../types"
import {
  BusinessPlanSettingsRequest,
  ExperimentResponse,
  ExperimentSettings,
  RoomsSettingsRequest,
  VisitorSettingsRequest,
} from "./types"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

/**
 *  Thunks
 */

export const fetchExperiments = createAsyncThunk<
  ExperimentResponse,
  void,
  ThunkApiConfig
>("experiments/fetch", async (_, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(experimentsCompanyURL(), {}, access_token)

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

export const postExperiments = createAsyncThunk<
  ExperimentSettings,
  RoomsSettingsRequest | VisitorSettingsRequest | BusinessPlanSettingsRequest,
  ThunkApiConfig<ResponseError>
>("experiments/post", async (payload, { getState, rejectWithValue }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await postJSON(
    experimentsCompanyURL(),
    { body: { data: payload } },
    access_token,
  )

  if (response.ok) {
    return await response.json()
  }

  return rejectWithValue(await getErrorObject(response))
})

type UpdateExperimentsProps = (
  | RoomsSettingsRequest
  | VisitorSettingsRequest
  | BusinessPlanSettingsRequest
) & {
  id: string
}

export const updateExperiments = createAsyncThunk<
  ExperimentSettings,
  UpdateExperimentsProps,
  ThunkApiConfig<ResponseError>
>(
  "experiments/update",
  async ({ id, ...payload }, { getState, rejectWithValue }) => {
    const {
      auth: { access_token },
    } = getState()

    const response = await putJSON(
      experimentsCompanyURL(id),
      { body: { data: payload } },
      access_token,
    )

    if (response.ok) {
      return await response.json()
    }

    return rejectWithValue(await getErrorObject(response))
  },
)

export const destroyExperiment = createAsyncThunk<void, string, ThunkApiConfig>(
  "experiments/destroy",
  async (id, { getState }) => {
    const {
      auth: { access_token },
    } = getState()

    const response = await deleteJSON(
      experimentsCompanyURL(id),
      { body: {} },
      access_token,
    )

    if (response.ok) {
      return
    }

    throw new Error(await getErrorMessage(response))
  },
)

/**
 *  Slice
 */
export interface ExperimentsState extends SliceState, PaginationState {
  entries: ExperimentSettings[]
}

const initialState: ExperimentsState = {
  entries: [],
  ...sliceInitialState,
  ...paginationInitialState,
}

const experimentsSlice = createSlice({
  name: "experiments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExperiments.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchExperiments.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(fetchExperiments.fulfilled, (state, { payload }) => {
      const { results, ...paginationData } = payload
      state = {
        ...state,
        ...paginationData,
        entries: results,
      }
      setFetchSuccessState(state)
      return state
    })

    builder.addCase(postExperiments.pending, (state) => {
      state.isSubmitting = true
    })
    builder.addCase(postExperiments.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addCase(postExperiments.fulfilled, (state, { payload }) => {
      state.entries = [...state.entries, payload]
      setSubmitSuccessState(state)
    })

    builder.addCase(updateExperiments.pending, (state) => {
      state.isSubmitting = true
    })
    builder.addCase(updateExperiments.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addCase(updateExperiments.fulfilled, (state, { payload }) => {
      const remain = state.entries.filter((exp) => exp.id !== payload.id)

      state.entries = [...remain, payload]
      setSubmitSuccessState(state)
    })

    builder.addCase(destroyExperiment.pending, (state) => {
      state.isSubmitting = true
    })
    builder.addCase(destroyExperiment.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addCase(destroyExperiment.fulfilled, (state, action) => {
      state.entries = state.entries.filter((exp) => exp.id !== action.meta.arg)
      setSubmitSuccessState(state)
    })
  },
})

export const experimentsReducer = experimentsSlice.reducer
