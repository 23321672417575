import React, { useEffect, useRef, useState } from "react"

import classNames from "classnames"
import { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useBookContext } from "../../../contexts/Mobile/BookContext"

import { useAppSelector } from "../../../redux/reducers"
import { fetchSuggestions } from "../../../redux/suggestions/suggestionsSlice"
import { Suggestion } from "../../../redux/suggestions/types"
import { useActions } from "../../../redux/utils"

import Button from "../../../components/advanced/Button"
import Loader from "../../../components/basic/Loader"
import { CategoryRow } from "../../../components/Mobile/CategoryRow"
import { DatePicker } from "../../../components/Mobile/DatePicker"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { SuggestedDesk } from "../../../components/Mobile/SuggestedDesk"
import { TopNav } from "../../../components/Mobile/TopNav"

import "./Suggest.sass"

type SuggestProps = {
  isQrDeskBooking?: boolean
}

const Suggest = ({ isQrDeskBooking }: SuggestProps) => {
  const { date, onAnotherDeskPick, onChangeDate } = useBookContext()

  const wrapperRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  const [height, setHeight] = useState(0)

  const history = useHistory()

  const [selectedDate, setSelectedDate] = useState(date)

  const actions = useActions({
    fetchSuggestions: (date: Dayjs) =>
      fetchSuggestions({ now: date.toISOString() }),
  })

  const suggestions = useAppSelector((state) => state.suggestions.entries)
  const isSuggestionsLoading = useAppSelector(
    (state) => state.suggestions.isLoading,
  )

  const setWrapperHeight = () => {
    if (wrapperRef.current) {
      setHeight(wrapperRef.current.clientHeight)
    }
  }

  const handleClose = () => {
    history.push("/home/reservations")
  }

  const handleSelectDate = (date: Dayjs) => {
    actions.fetchSuggestions(date)
    setSelectedDate(date)
    onChangeDate(date)
  }

  useEffect(() => {
    setWrapperHeight()
  }, [])

  useEffect(() => {
    actions.fetchSuggestions(selectedDate)
  }, [actions, selectedDate])

  const bodyClasses = classNames("body", {
    "is-qr-desk-booking": isQrDeskBooking,
  })

  return (
    <SafeViewArea className="Suggest">
      {!isQrDeskBooking && (
        <TopNav
          titleCenter={true}
          backArrow={true}
          onGoBack={handleClose}
          onClose={handleClose}
        />
      )}

      <div className={bodyClasses}>
        {!isQrDeskBooking && (
          <>
            <div className="title">{t("mobile.general.choose_time")}</div>

            <CategoryRow name={t("mobile.general.date")}>
              <DatePicker
                selectedDate={selectedDate}
                onSelect={handleSelectDate}
              />
            </CategoryRow>

            <div className="label-custom">
              {`${t("mobile.book.suggested")} (${suggestions.length})`}
            </div>
          </>
        )}

        <div className="wrapper" ref={wrapperRef}>
          <div className="scrollable" style={{ height }}>
            {isSuggestionsLoading ? (
              <Loader variant="fullScreen" />
            ) : (
              suggestions.map((suggestion: Suggestion) => (
                <SuggestedDesk
                  date={selectedDate}
                  key={suggestion.desk.id}
                  suggestion={suggestion}
                  isQrDeskBooking={isQrDeskBooking}
                />
              ))
            )}
            {!isSuggestionsLoading && suggestions.length === 0 && (
              <div>{t("general.not_found.no_desks_found")}</div>
            )}
          </div>
        </div>

        <div className="padding-bottom">
          <p className="question">{t("mobile.book.not_okay")}</p>
          <div className="next-button">
            <Button
              variant="mobile-action"
              onClick={onAnotherDeskPick}
              isDisabled={isSuggestionsLoading}
            >
              {t("mobile.book.pick_another_desk")}
            </Button>
          </div>
        </div>
      </div>
    </SafeViewArea>
  )
}

export default Suggest
