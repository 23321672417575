import { Dayjs } from "dayjs"

export const PIXELS_PER_HOUR = 50
export const HOURS_IN_A_DAY = 24
export const MINUTES_IN_A_DAY = HOURS_IN_A_DAY * 60
export const MINUTES_PER_PIXEL = 60 / PIXELS_PER_HOUR
export const PIXELS_PER_MINUTE = 1 / MINUTES_PER_PIXEL
export const DRAGGABLE_IDENTIFIER = "RESERVATION"

export const normalizeToIncrement = (
  value: number,
  increment: number,
  type: "round" | "floor" = "round",
) =>
  type === "round"
    ? Math.round(value / increment) * increment
    : Math.floor(value / increment) * increment

export const roundTime = (time: Dayjs, roundTo: number) => {
  const minutes = time.minute()
  const roundedMinutes = normalizeToIncrement(minutes, roundTo)
  return time.minute(roundedMinutes).second(0)
}
