import { PropsWithChildren, useCallback, useEffect, useState } from "react"

import queryString from "query-string"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { analyticsEvent, SupportedEvents } from "../analytics"
import { FEATURE_FLAGS } from "../constants"
import { useCheckForFeatureFlag } from "../hooks/useCheckForFeatureFlag"
import { useIsOnDeskSpotCheckIn } from "../hooks/useIsOnSpotDeskCheckIn"
import { useToast } from "../hooks/useToast"
import CheckInsModal from "../modals/CheckInsModal"

import { useCheckinDeskReservationMutation } from "../redux/api/deskReservations"
import {
  isEmployeeScreeningNotApplicable,
  useLazyFetchEmployeeScreeningQuery,
} from "../redux/api/screenings"
import { isApiResponseError, isRejected } from "../redux/api/types"
import { selectNeedsOnboarding } from "../redux/app/selectors"
import { checkinAssetReservation } from "../redux/asset_reservation/assetReservationSlice"
import { fetchCheckIns } from "../redux/check_ins/checkInsSlice"
import { selectCheckIns } from "../redux/check_ins/selectors"
import {
  CHECK_INS_TYPES,
  CheckInResponse,
  FetchOptions,
} from "../redux/check_ins/types"
import { useAppSelector } from "../redux/reducers"
import { selectIsAuth } from "../redux/selectors"
import { selectSettingsEffective } from "../redux/settings/selectors"
import { useActions } from "../redux/utils"

/**
 * Will be @deprecated when the notification hub will be public (i.e. won't be under feature flag)
 * since check-in will be handled by the notification hub
 */

export const CheckInProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [opened, setOpened] = useState(false)

  const isAuth = useAppSelector(selectIsAuth)
  const { entry: settings } = useAppSelector(selectSettingsEffective)
  const { entries: checkIns, isLoaded } = useAppSelector(selectCheckIns)
  const isOnboarding = useAppSelector(selectNeedsOnboarding)

  const isNotificationHubEnabled = useCheckForFeatureFlag(
    FEATURE_FLAGS.NOTIFICATION_HUB,
  )

  const { errorToast } = useToast()
  const { t } = useTranslation()
  const [checkinDeskReservation] = useCheckinDeskReservationMutation()
  const [fetchScreening] = useLazyFetchEmployeeScreeningQuery()
  const history = useHistory()
  const isOnSpotCheckIn = useIsOnDeskSpotCheckIn()

  const actions = useActions({
    fetchCheckIns: (options?: FetchOptions) => fetchCheckIns(options),
    checkInAsset: (id: string) => checkinAssetReservation(id),
  })

  const filteredCheckIns = isOnSpotCheckIn
    ? checkIns.filter((checkIn) => checkIn.type !== CHECK_INS_TYPES.DESK)
    : checkIns

  const count = filteredCheckIns.length

  const handleCheckIn = useCallback(
    async (checkIn: CheckInResponse) => {
      const { type, id, building } = checkIn

      switch (type) {
        case CHECK_INS_TYPES.ASSET: {
          const response = await actions.checkInAsset(id)

          if (checkinAssetReservation.rejected.match(response)) {
            errorToast(response.error.message)
            return
          }
          break
        }
        default: {
          const screening = await fetchScreening(building.id)

          if (
            isEmployeeScreeningNotApplicable(screening) ||
            screening?.data?.passed === true
          ) {
            const response = await checkinDeskReservation(id)

            analyticsEvent(SupportedEvents.DESK_RESERVATION_CHECKIN, {
              id,
            })

            if (isRejected(response)) {
              const { error } = response
              if (isApiResponseError(error) && error.status === 404) {
                errorToast(t("general.reservation.not_found"))
                actions.fetchCheckIns()
                return
              }

              errorToast(error.message)
              return
            }
          }

          if (!isRejected(screening)) {
            history.push(
              `/screening/employee/${building.id}?${queryString.stringify({
                id,
              })}`,
            )
          }
          break
        }
      }

      await actions.fetchCheckIns()
    },
    [actions, checkinDeskReservation, errorToast, fetchScreening, history, t],
  )

  useEffect(() => {
    if (isAuth) {
      actions.fetchCheckIns()
    }
  }, [actions, isAuth])

  useEffect(() => {
    if (!settings?.desk_check_in_enabled || !isLoaded) return

    if (count < 1) {
      setOpened(false)
      return
    }

    setOpened(true)
  }, [settings?.desk_check_in_enabled, checkIns, count, isLoaded])

  if (history.location.pathname.startsWith("/screening/employee")) {
    return <>{children}</>
  }

  // If the notification hub is enabled, we don't need to show the check-in modal
  if (isNotificationHubEnabled) {
    return <>{children}</>
  }

  return (
    <>
      {!isOnboarding && (
        <CheckInsModal
          onClose={() => setOpened(false)}
          open={opened}
          handleCheckIn={handleCheckIn}
        />
      )}
      {children}
    </>
  )
}
