import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { userTimeFormat } from "../../../../utils"
import { useFloorPlanContext } from "../FloorPlanProvider"

import { DeskScheduleReservation } from "../../../../redux/desk_schedule/types"
import { formatUser } from "../../../../redux/user/utils"

import AccountCircleBoldSVG from "../../../../assets/images/icons/AccountCircleBold.svg"
import BadgeOutlineSVG from "../../../../assets/images/icons/BadgeOutline.svg"

type Props = {
  reservation: DeskScheduleReservation
}

const DeskReservationItem = ({ reservation }: Props) => {
  const { t } = useTranslation()

  const { user, visit_id, start, end } = reservation
  const { openDeskReservation } = useFloorPlanContext()

  const startTime = dayjs(start).format(userTimeFormat())
  const endTime = dayjs(end).format(userTimeFormat())

  const isBookedForVisitor = visit_id && visit_id !== null

  return (
    <button
      className="reservation-item-details"
      type="button"
      onClick={() => openDeskReservation(reservation.id)}
    >
      <div className="reservation-item-details-main">
        <div>
          <span className="reservation-item-details-main__badge">
            {isBookedForVisitor ? (
              <BadgeOutlineSVG />
            ) : (
              <AccountCircleBoldSVG />
            )}
          </span>
          <span className="reservation-item-details-main__name">
            {formatUser(user)}
          </span>
        </div>
        <span className="reservation-item-details-main__time">{`${startTime} - ${endTime}`}</span>
      </div>

      {isBookedForVisitor && (
        <div className="reservation-item-details-if-visitor">
          <span>
            ({t("desktop.manage.floor_plan.reservation_for_visitor")})
          </span>
        </div>
      )}
    </button>
  )
}

export default DeskReservationItem
