import React, {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import { DraggableEvent } from "react-draggable"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"

import { queryBuilder } from "../../../../api/queryBuilder"
import useCursorPosition from "../../../../hooks/useCursorPosition"
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside"
import { useToast } from "../../../../hooks/useToast"
import RoomModal from "../../../../modals/RoomModal"
import { getCoordsFormEvent, getMouseXY } from "../../Desks/Layout"
import { ROOMS_PATHS } from "../constants"
import { useModals } from "@mattjennings/react-modal-stack"

import { useFetchFloorsQuery } from "../../../../redux/api/floors"
import {
  useFetchRoomsRbQuery,
  useUpdateRoomRbMutation,
} from "../../../../redux/api/roomBookingRooms"
import {
  RbRoomResponse,
  RoomRequestWithId,
} from "../../../../redux/api/roomBookingRooms/types"
import { isRejected } from "../../../../redux/api/types"
import { FloorResponse } from "../../../../redux/floors/types"

import Button from "../../../../components/advanced/Button"
import Skeleton from "../../../../components/advanced/Skeleton"
import Card from "../../../../components/basic/Card"
import Divider from "../../../../components/basic/Divider"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import BuildingFilter from "../../../../components/Filter/BuildingFilter"
import FiltersBar from "../../../../components/Filter/FiltersBar"
import FloorFilter from "../../../../components/Filter/FloorFilter"
import { FilterSpecialValues } from "../../../../components/Filter/types"
import Intro from "../../../../components/Intro"
import Map from "../../../../components/Map"
import NoDataFound from "../../../../components/NoDataFound"
import Place from "../../../../components/Place"
import Space from "../../../../components/Space"
import { toast } from "../../../../components/Toast"
import View from "../../../../components/View"

import DeselectSVG from "../../../../assets/images/icons/Deselect.svg"
import MapSVG from "../../../../assets/images/icons/Map.svg"
import PencilSVG from "../../../../assets/images/icons/Pencil.svg"
import RoomSVG from "../../../../assets/images/icons/Room.svg"
import SelectSVG from "../../../../assets/images/icons/Select.svg"

import "./style.sass"

/**
 * Why we use Y_OFFSET_ROOM?
 *
 * Current positioning of rooms on the map in production does not
 * work correctly - when creating or repositioning a room - it is shifted
 * 4 pixels down the Y axis. To fix the incorrect display, a decision was made
 * use a little hack where we need to apply -4 pixels every time the room is moved
 * or set.
 *
 * Note: The source of the issue is the height of the container,
 * which is ~4 pixels different from the plan image. When trying to fix
 * this difference, all current rooms of existing users in the system will be
 * shifted 4 pixels up on the y-axis, which would be unacceptable.
 */

const Y_OFFSET_ROOM: number = 4

function RoomLayout() {
  const { t } = useTranslation()

  const { infoToast } = useToast()

  const { openModal } = useModals()

  const { search } = useLocation()

  const globalCursorPosition = useCursorPosition()

  const query = useMemo(() => new URLSearchParams(search), [search])

  const [selectedRooms, setSelectedRooms] = useState<number[]>([])

  const [isRepositionInitialized, setRepositionInitialized] = useState(false)

  const [isStartDragReposition, setStartDragReposition] = useState(false)

  const [dragRoom, setDragRoom] = useState<number>()
  const [dragRoomScale, setDragRoomScale] = useState<number>(1)

  const [hoveredRoom, setHoveredRoom] = useState<number>()

  const handleUpdateScale = (value: number) => setDragRoomScale(value)

  const [repositionRoom, setRepositionRoom] =
    useState<RoomRequestWithId | null>(null)

  const [buildingFilter, setBuildingFilter] = useState<string>(
    query.get("building") || FilterSpecialValues.EMPTY,
  )

  const [floorFilter, setFloorFilter] = useState<string>(
    query.get("floor") || FilterSpecialValues.EMPTY,
  )

  const [updateRoom] = useUpdateRoomRbMutation()

  const { data: { results: floors = [] } = {}, isFetching: areFloorsLoading } =
    useFetchFloorsQuery(
      {
        building: buildingFilter,
      },
      {
        skip: !buildingFilter,
      },
    )

  const {
    data: { results: unfilteredRooms = [] } = {},
    isSuccess: areRoomsLoaded,
  } = useFetchRoomsRbQuery({ floor: floorFilter })

  const rooms = useMemo(
    () =>
      unfilteredRooms.filter((room) => room.coords && room.coords.length > 0),
    [unfilteredRooms],
  )

  useEffect(() => {
    setSelectedRooms([])
  }, [buildingFilter])

  const mapBoxRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  const floor: FloorResponse | undefined = floors.find(
    (f: FloorResponse) => f.id === floorFilter,
  )
  const imgCursorPosition = (imageRef.current &&
    floor &&
    getMouseXY(globalCursorPosition, imageRef.current!, floor)) ?? {
    x: 0,
    y: 0,
  }

  const newRoom = useCallback(
    (room: RoomRequestWithId) => {
      if (floor) {
        openModal(RoomModal, {
          floor,
          room,
        })
      }
    },
    [floor, openModal],
  )

  const handleEditRooms = useCallback(() => {
    if (selectedRooms.length && floor) {
      openModal(RoomModal, {
        floor,
        roomsToEdit: selectedRooms,
        isEdit: true,
      })
    }
  }, [selectedRooms, rooms, floor, openModal])

  const selectRoom = useCallback(
    (room: RbRoomResponse) => {
      if (selectedRooms.find((id) => id === room.id) === undefined) {
        setSelectedRooms([...selectedRooms, room.id])
      } else {
        setSelectedRooms(selectedRooms.filter((id) => id !== room.id))
      }
    },
    [selectedRooms, setSelectedRooms],
  )

  const handleMouseOver = (id: number) => setHoveredRoom(id)

  const handleMouseOut = () => setHoveredRoom(undefined)

  const handleDeselect = useCallback(() => {
    setSelectedRooms([])
    setRepositionRoom(null)
  }, [])

  const handleSelectAll = useCallback(() => {
    if (floor) {
      setSelectedRooms(rooms.map((d: RbRoomResponse) => d.id))
    }
  }, [rooms, floor])

  useOnClickOutside([mapBoxRef], (e) => {
    if (!(e.target as HTMLElement).closest(`.Button.link`)) {
      if (selectedRooms.length) {
        handleDeselect()
      }
    }
  })

  const handleToggleReposition = () =>
    setRepositionInitialized((prevValue) => !prevValue)

  const handleStartReposition = useCallback(() => {
    const draggableRoom = rooms.find((r: RbRoomResponse) => r.id === dragRoom)

    if (draggableRoom && draggableRoom.id) {
      setStartDragReposition(true)

      infoToast(
        t("desktop.settings.rooms.layout.toasts.room_reposition_toast_drag"),
      )

      setRepositionRoom({
        ...draggableRoom,
        amenities: draggableRoom.amenities.map((a) => parseInt(a.id)),
        building_id: draggableRoom.building?.id,
        floor_id: draggableRoom.floor?.id,
      })
    }
  }, [rooms, dragRoom, infoToast, t])

  const handleRepositionRoom = useCallback(
    async (room: RoomRequestWithId) => {
      const response = await updateRoom(room)

      if (!isRejected(response)) {
        handleDeselect()
        toast.info(
          t("desktop.settings.rooms.layout.toasts.repositioned_room_toast"),
          { hideProgressBar: true },
        )
      } else {
        toast.error(response.error.message, { hideProgressBar: true })
      }
    },
    [updateRoom, handleDeselect, t],
  )

  const handleMapClick = useCallback(
    ({ nativeEvent }: ReactMouseEvent<HTMLImageElement>) => {
      if (floor) {
        const { x: imgX, y: imgY } = getMouseXY(
          nativeEvent,
          imageRef.current!,
          floor,
        )

        if (!repositionRoom) {
          // Creating a new room
          newRoom({
            coords: [
              { x: Math.floor(imgX), y: Math.floor(imgY) - Y_OFFSET_ROOM },
              { x: Math.floor(imgX), y: Math.floor(imgY) - Y_OFFSET_ROOM },
              { x: Math.floor(imgX), y: Math.floor(imgY) - Y_OFFSET_ROOM },
              { x: Math.floor(imgX), y: Math.floor(imgY) - Y_OFFSET_ROOM },
            ],
          } as RoomRequestWithId)
        } else {
          // or repositioning an existing room by clicking
          handleRepositionRoom({
            ...repositionRoom,
            coords: [
              { x: Math.floor(imgX), y: Math.floor(imgY) - Y_OFFSET_ROOM },
              { x: Math.floor(imgX), y: Math.floor(imgY) - Y_OFFSET_ROOM },
              { x: Math.floor(imgX), y: Math.floor(imgY) - Y_OFFSET_ROOM },
              { x: Math.floor(imgX), y: Math.floor(imgY) - Y_OFFSET_ROOM },
            ],
          })
        }
      }
    },
    [floor, repositionRoom, newRoom, handleRepositionRoom],
  )

  const handleDownDragPlace = useCallback(
    (id?: number | string) => id && setDragRoom(parseInt(id.toString())),
    [],
  )

  const handleStopDragPlace = useCallback(
    (event: DraggableEvent) => {
      if (floor) {
        const coords = getCoordsFormEvent(event)
        // Repositioning an existing room by dragging
        const { x: imgX, y: imgY } = getMouseXY(
          coords,
          imageRef.current!,
          floor,
        )

        const { width: sourceWidth = 0, height: sourceHeight = 0 } = floor

        const isRoomNotWithinBounds =
          Math.floor(imgX) > sourceWidth ||
          Math.floor(imgX) <= 0 ||
          Math.floor(imgY) > sourceHeight ||
          Math.floor(imgY) <= 0

        if (repositionRoom) {
          if (isRoomNotWithinBounds) {
            toast.error(
              t(
                "desktop.settings.rooms.layout.toasts.incorrect_room_position_toast",
              ),
              {
                hideProgressBar: true,
              },
            )
          } else {
            handleRepositionRoom({
              id: repositionRoom.id,
              coords: [
                { x: Math.floor(imgX), y: Math.floor(imgY) - Y_OFFSET_ROOM },
                { x: Math.floor(imgX), y: Math.floor(imgY) - Y_OFFSET_ROOM },
                { x: Math.floor(imgX), y: Math.floor(imgY) - Y_OFFSET_ROOM },
                { x: Math.floor(imgX), y: Math.floor(imgY) - Y_OFFSET_ROOM },
              ],
            }).then(() => {
              setStartDragReposition(false)
              setRepositionInitialized(false)
            })
          }
        }
      }
    },
    [t, floor, repositionRoom, handleRepositionRoom],
  )

  useEffect(() => {
    if (isRepositionInitialized) {
      handleStartReposition()
    }
  }, [isRepositionInitialized])

  const editingRoomId = repositionRoom && repositionRoom.id

  return (
    <View className="RoomLayout SettingsPage">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.rooms.title"),
          t("desktop.settings.rooms.layout.title"),
        ]}
      />

      <Intro isConstrained>
        {t("desktop.settings.rooms.layout.intro.title")}
      </Intro>
      <ul>
        <li>{t("desktop.settings.rooms.layout.intro.first_line")}</li>
        <li>{t("desktop.settings.rooms.layout.intro.second_line")}</li>
        <li>{t("desktop.settings.rooms.layout.intro.third_line")}</li>
      </ul>

      <Space size={0.75} />

      {!areRoomsLoaded && <Skeleton />}

      {areRoomsLoaded && (
        <div className="rooms">
          <FiltersBar>
            <BuildingFilter
              value={buildingFilter}
              onChange={setBuildingFilter}
            />
            <FloorFilter
              value={floorFilter}
              onChange={setFloorFilter}
              buildingId={buildingFilter}
            />
          </FiltersBar>

          {!areFloorsLoading && floor && floor.image && (
            <div className="floor-plan-visible">
              <div className="floor-plan-column">
                <Card isFloorPlan className="floor-plan">
                  <Map
                    map={floor}
                    isDisabled={!areRoomsLoaded || isStartDragReposition}
                    onClick={handleMapClick}
                    onUpdateScale={handleUpdateScale}
                    ref={imageRef}
                    mapBoxRef={mapBoxRef}
                    showPanControls
                    showZoomControls
                  >
                    {areRoomsLoaded &&
                      rooms.map((room: RbRoomResponse, i: number) => {
                        if (!room.coords || room.coords.length === 0) {
                          return
                        }

                        const roomX = room.coords[0].x
                        const roomY = room.coords[0].y

                        return (
                          <Place
                            type="room"
                            key={`room-${i}`}
                            id={room.id.toString()}
                            title={room.name}
                            x={
                              isStartDragReposition && room.id === dragRoom
                                ? imgCursorPosition.x
                                : roomX
                            }
                            y={
                              isStartDragReposition && room.id === dragRoom
                                ? imgCursorPosition.y - Y_OFFSET_ROOM
                                : roomY
                            }
                            mapWidth={floor.width ?? 1}
                            mapHeight={floor.height ?? 1}
                            onClick={() => selectRoom(room)}
                            onMouseOver={() => handleMouseOver(room.id)}
                            onMouseOut={handleMouseOut}
                            isBlinking={room.id === editingRoomId}
                            isHovered={room.id === hoveredRoom}
                            isSelected={
                              selectedRooms.find((id) => id === room.id) !==
                              undefined
                            }
                            scale={dragRoomScale}
                            onDownDragPlace={handleDownDragPlace}
                            onStopDragPlace={handleStopDragPlace}
                            onToggleReposition={handleToggleReposition}
                          />
                        )
                      })}
                  </Map>
                </Card>
                {unfilteredRooms.length === 0 && (
                  <NoDataFound warning className="floor-plan-cta">
                    <Trans
                      i18nKey={
                        "desktop.settings.rooms.layout.no_rooms_on_building_floor"
                      }
                      components={{
                        a: (
                          <Link
                            to={{
                              pathname: ROOMS_PATHS.rooms,
                              search: queryBuilder({
                                building: buildingFilter,
                                floor: floorFilter,
                              }),
                            }}
                          >
                            {t("desktop.settings.rooms.layout.add_rooms")}
                          </Link>
                        ),
                      }}
                    />
                  </NoDataFound>
                )}
              </div>

              <div className="floor-plan-toolbar-wrapper">
                <div className="floor-plan-toolbar">
                  <div className="selected-rooms">
                    <RoomSVG />
                    {t("desktop.settings.rooms.layout.selectedRoomCount", {
                      count: selectedRooms.length,
                    })}
                  </div>
                  <Button
                    onClick={handleSelectAll}
                    icon={<SelectSVG />}
                    variant="link"
                    isDisabled={selectedRooms.length === rooms.length}
                  >
                    {t("desktop.settings.rooms.layout.select_all_rooms")}
                  </Button>
                  <Button
                    onClick={handleDeselect}
                    icon={<DeselectSVG />}
                    variant="link"
                    isDisabled={!selectedRooms.length}
                  >
                    {selectedRooms.length === rooms.length
                      ? t("desktop.settings.rooms.layout.deselect_all_rooms")
                      : t("desktop.settings.rooms.layout.deselect_room")}
                  </Button>
                  <Divider color="gray-3" />
                  <Button
                    onClick={handleEditRooms}
                    icon={<PencilSVG />}
                    variant="link"
                    isDisabled={!selectedRooms.length}
                  >
                    {t("desktop.settings.rooms.layout.edit_room", {
                      count: selectedRooms.length,
                    })}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {!areFloorsLoading && (!floor || !floor.image) && (
            <>
              <Card className="floor-plan missing">
                <div className="floor-plan-content">
                  <MapSVG />
                  <p>{t("desktop.settings.rooms.layout.no_floor_plan")}</p>
                </div>
                <NoDataFound warning className="floor-plan-cta">
                  <p>
                    {t("desktop.settings.floor_plans.no_data.no_floor_plan")}{" "}
                    <Trans i18nKey="desktop.settings.floor_plans.no_data.add_link">
                      Add one under
                      <Link
                        to={{
                          pathname: "/settings/floor-plans",
                          search: queryBuilder({
                            building: buildingFilter,
                            floor: floorFilter,
                          }),
                        }}
                      >
                        floor plan settings
                      </Link>
                    </Trans>
                  </p>
                </NoDataFound>
              </Card>
            </>
          )}
        </div>
      )}
    </View>
  )
}

export default RoomLayout
