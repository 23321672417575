import { ImageResponse as ImgResponse } from "../../types/sharedTypes"

export type CustomButton = {
  action: number
  device: null | string
  friendly_key: string
  location: null | string
  payload: {
    authentication?: {
      basic?: {
        username?: string
        password?: string
      }
      type?: number
    }
    message: string
    url?: string
  }
  replaces: number
  replaces_pro: number
  state: number
  title: string
}

export type DeviceLogo = {
  id: string
  name: string
  path: string
  size: number
  url?: string
}

export type MeetingReminder = {
  contact_email: string
  contact_person: string
  contact_phone: string
  enabled: boolean
  excluded_domains: string
  instructions: string
  location: string
  location_full_address: string | null
  location_latitude: null | number
  location_longitude: null | number
}

export type OfficeHours<T = null | string> = {
  sleep_day_fri: boolean
  sleep_day_mon: boolean
  sleep_day_sat: boolean
  sleep_day_sun: boolean
  sleep_day_thu: boolean
  sleep_day_tue: boolean
  sleep_day_wed: boolean
  start_hour: T
  stop_hour: T
}
export type ImageResponse = ImgResponse

export enum DeviceDisplayMode {
  Rooms = "1",
  Meetings = "2",
}

export type Settings = {
  company_logo: ImageResponse | null
  company_color: string | null
  custom_button: CustomButton | null
  desk_capacity_limit: number | null
  desk_check_in_enabled: boolean | null
  desk_checkin_period: number | null
  desk_hide_reservations: boolean | null
  desk_hide_reservations_window_length: number | null
  desk_department_rules_enabled: boolean | null
  desk_force_geofence: boolean | null
  desk_force_timeslot_use: boolean | null
  desk_mandatory_check_in: boolean | null
  desk_one_reservation_per_user: boolean | null
  desk_reservation_window_length: number | null
  desk_send_notifications_at_change: boolean | null
  device_cancel_meetings: number | null
  device_check_into_timeout: number | null
  device_cleaning_status: boolean | null
  device_display_charging: boolean | null
  device_display_mode: DeviceDisplayMode
  device_dtim: number | null
  device_heartbeat: number | null
  device_logo: DeviceLogo | null
  device_meet_later: boolean | null
  device_meet_now_duration: number | null
  device_no_wifi: boolean | null
  device_personalized_device: boolean | null
  device_on_spot_duration: number | null
  device_roaming_hysteresis: number | null
  device_roaming_threshold: number | null
  device_roaming: boolean | null
  device_show_meeting_organizer: boolean | null
  device_show_meeting_subject: boolean | null
  device_show_meeting_description: boolean | null
  device_show_only_ongoing?: boolean
  device_show_tentative_meetings: boolean | null
  device_show_vacancies?: boolean
  device_sound: boolean | null
  device_touchscreen: boolean | null
  language: string | null
  maintenance_email: string | null
  meeting_reminder: MeetingReminder | null
  office_hours: OfficeHours | null
  technical_email: string | null
  timeformat: string | null
  timezone: string | null
  questionnaire_enabled?: boolean
  desk_check_in_location?: DeskCheckInLocation
  privacy_mode_enabled?: boolean
  room_in_app_booking_enabled?: boolean
}

export type SettingsResponse = {
  settings: Settings
  settings_effective: Settings
}

export type SettingsRequest = {
  settings: Partial<Settings>
}

export type SettingsConfigAction = {
  type: string
  payload: Settings | string
}

export type DeskCheckInLocation = "on_spot" | "any"
