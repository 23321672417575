import { useState } from "react"

import { ParseKeys, t } from "i18next"
import { useTranslation } from "react-i18next"

import { AMENITIES } from "../../screens/Settings/Rooms/Rooms/constants"
import { MultiOptionType } from "../../types/sharedTypes"
import DropdownMulti from "../advanced/DropdownMulti"
import { FilterSpecialValues } from "./types"

type MultiAmenitiesFilterProps = {
  preSelectedValues: string[]
  onChange: (values: string[]) => void
  optionsName?: string
  defaultLabel?: string
}

export default function MultiRoomAmenitiesFilter({
  preSelectedValues = [],
  onChange,
  optionsName = t("general.amenities").toLowerCase(),
  defaultLabel = t("mobile.general.all_amenities"),
}: MultiAmenitiesFilterProps) {
  /**
   * State
   */
  const [selected, setSelected] = useState<string[]>(preSelectedValues)

  const { t } = useTranslation()

  /**
   * Computed values
   */
  const amenityOptions: MultiOptionType<string>[] = (
    Object.keys(AMENITIES).filter((v) =>
      isNaN(Number(v)),
    ) as (keyof typeof AMENITIES)[]
  ).map((key) => ({
    value: AMENITIES[key].toString(),
    label: t(
      `desktop.settings.rooms.rooms.form.amenities.${key.toLowerCase()}` as ParseKeys,
    ),
    isSingleSelect: false,
  }))
  amenityOptions.unshift({
    label: <>{defaultLabel}</>,
    value: FilterSpecialValues.ALL,
    isSingleSelect: true,
  })

  /**
   * Handlers
   */
  const handleOnChange = (filterValues: string[]) => {
    if (filterValues.length === 0) {
      filterValues = [FilterSpecialValues.ALL]
    }
    setSelected(filterValues)
    onChange(filterValues)
  }

  /**
   * Render
   */
  return (
    <DropdownMulti
      className="multi-room-amenites-filter"
      options={amenityOptions}
      values={selected}
      onChange={handleOnChange}
      optionsName={optionsName}
      defaultLabel={defaultLabel}
    />
  )
}
