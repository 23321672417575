import { DeskResponse } from "../../../redux/api/desks/types"
import {
  Reservation,
  RoomReservation,
} from "../../../redux/api/roomReservations/types"
import { DeskScheduleReservation } from "../../../redux/desk_schedule/types"

export const SpotTypes = {
  Desk: "desk",
  Room: "room",
  Asset: "asset",
} as const

export type SpotType = (typeof SpotTypes)[keyof typeof SpotTypes]

export type DeskWithReservations = DeskResponse & {
  reservations: DeskScheduleReservation[]
  highlight?: boolean
  isDisabled?: boolean
  userEmail?: string
  type: typeof SpotTypes.Desk
}

export type RoomWithReservations = Omit<RoomReservation, "schedule"> & {
  reservations: Reservation[]
  highlight?: boolean
  isDisabled?: boolean
  userEmail?: string
  type: typeof SpotTypes.Room
}
